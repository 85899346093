import styled, { keyframes } from 'styled-components';
import pixel from '../../../utils/size';

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Conteiner = styled.div`
  position: absolute;
  z-index: 3;
  width: ${pixel(420)};
  height: ${pixel(420)};
  bottom: ${pixel(95)};
  right: ${pixel(30)};
  background-color: var(--white);
  border-radius: ${pixel(10)};
  animation-name: ${fade};
  animation-duration: 0.5s;
  box-shadow: 1px 1px 10px 0px #999999;

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 655px;
    right: 0;
    bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pixel(10)} ${pixel(20)};

  p {
    font-size: ${pixel(22)};
    font-weight: bold;
    color: var(--blue003);
    font-family: 'Nunito';

    @media only screen and (max-width: 600px) {
      font-size: 18pt;
    }
  }

  h3 {
    font-size: ${pixel(16)};
    font-weight: bold;
    font-family: 'Nunito';

    @media only screen and (max-width: 600px) {
      font-size: 14pt;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`;

export const StatusFirst = styled.div<{ option?: string }>`
  font-size: ${pixel(14)};
  font-family: 'Nunito';
  color: ${props => (props.option === 'progress' ? '#4793ce' : '#000000')};
  cursor: pointer;
  user-select: none;
`;

export const StatusSecond = styled.div<{ option?: string }>`
  font-size: ${pixel(14)};
  font-family: 'Nunito';
  color: ${props =>
    props.option?.includes('finished') ? '#4793ce' : '#000000'};
  margin-left: 5px;
  cursor: pointer;
  user-select: none;
`;

export const StatusTConcierge = styled.div<{ option?: string }>`
  font-size: ${pixel(14)};
  font-family: 'Nunito';
  color: ${props =>
    props.option?.includes('concierge') ? '#4793ce' : '#000000'};
  margin-left: 5px;
  cursor: pointer;
  user-select: none;
`;

export const ListChat = styled.div`
  height: 77%;
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: ${pixel(8)};
  }

  ::-webkit-scrollbar-thumb {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    width: 0;
  }

  hr {
    border: ${pixel(1)} solid #d4d4d483;
  }

  @media only screen and (max-width: 600px) {
    height: 95%;
  }
`;

export const Icon = styled.img`
  width: ${pixel(18)};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 600px) {
    width: 18px;
  }
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  padding: ${pixel(10)} ${pixel(15)} ${pixel(10)} 0;
  justify-content: space-between;

  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  @media only screen and (max-width: 600px) {
    gap: 20px;
  }
`;

export const SectionPhote = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${pixel(25)};

  @media only screen and (max-width: 600px) {
    margin-left: 20px;
  }
`;

export const Photo = styled.img`
  width: ${pixel(40)};
  height: ${pixel(40)};
  border-radius: 50%;

  @media only screen and (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;

export const InfoMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UserName = styled.div`
  font-weight: 500;
  font-size: 11pt;
  font-family: 'Nunito';
`;

export const TextMessage = styled.h6`
  font-weight: 100;
  font-size: ${pixel(14)};
  font-family: 'Nunito';
`;

export const Pointer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  right: ${pixel(20)};
  width: ${pixel(13)};
  height: ${pixel(13)};
  border-radius: ${pixel(20)};
  background-color: #0099ff;
  box-shadow: 0 0 1px 1px #0099ff;
  font-size: ${pixel(12)};
  padding-top: ${pixel(1.5)};
  color: #ffffff;
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: 100%;
  border: 0;
  width: 90%;
  font-size: 12pt;
  font-family: 'Nunito';
  padding: 0 10px;
  border-radius: 6px;

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }

  &:focus-visible {
    outline: none;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const RowChat = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixel(10)};
`;

export const InputContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  height: 40px;
  border: 1px solid #77a0c0;
  width: 90%;
  align-items: center;
  border-radius: 6px;

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;

export const SectionFilter = styled.div`
  display: flex;
  flex-direction: row;
`;
