import styled, { keyframes } from 'styled-components';

interface Props {
  option?: boolean;
}

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalMarina = styled.div<Props>`
  width: 100%;
  height: 100px;
  border-radius: 6px;
  background: ${props => (props.option ? '#fafafa' : 'white')};
  border: 1px solid #ededed;
  box-shadow: 1px 3px 1px #bcbcbc;

  animation-name: ${fade};
  animation-duration: 0.5s;
`;

export const CloseMarina = styled.div`
  text-align: right;
`;

export const ImageClosed = styled.img`
  width: 8px;
  margin: 10px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: 50px;
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 0 22px;
  border-radius: 6px;
  font-size: 12pt;
  font-family: 'Nunito';

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 10px;

  input[type='file'] {
    display: none;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid white;
  border-radius: 6px;
  font-size: 12pt;
  padding: 0 16px;
  color: black;
  font-family: 'Nunito';

  option {
    height: 50px;
  }
`;

export const Option = styled.option`
  padding: 22px;
  font-size: 12pt;
  border-radius: 6px;
  color: #1a1a1a;
  font-family: 'Nunito';
`;

export const ButtonMarina = styled.button`
  align-self: center;
  width: 150px;
  height: 35px;
  font-size: 12pt;
  font-family: 'Nunito';
  border-radius: 6px;
  border: 1px solid var(--blue003);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover {
    opacity: 0.8;
  }
`;

export const OptionBusiness = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0px 25px;
  overflow-x: auto;
  padding-bottom: 5px;
  gap: 5px;

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue003);
  }

  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }
`;

export const BusinessContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12pt;
  width: 150px;
  align-items: center;

  border-radius: 6px;
  background: white;
  border: 1px solid var(--blue003);

  div {
    width: 150px;
    text-align: right;
    height: 12px;
  }
`;

export const TextHours = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 8pt !important;
  width: 100%;
  justify-content: center;
`;

export const ImageBusiness = styled.img`
  padding: 5px;
  align-self: left;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

export const SectionOption = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  width: 100%;
  height: 150px;
  gap: 10px;

  p {
    font-size: 13pt;
    padding: 0 23px;
    color: #1a1a1a;
    font-family: 'Nunito';
  }
`;
