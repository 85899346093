import styled from 'styled-components';
import pixel from '../../../../utils/size';

import ImagemMap from '../../../../assets/img/map.png';
import MapCell from '../../../../assets/img/mapaCelular.png';
import Pointer from '../../../../assets/img/pointerBlue.png';

export const SectionMap = styled.div`
  height: 90vh;
  width: 100%;

  margin-top: ${pixel(18)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
    height: 235px;
  }
`;

export const ImageMap = styled.div`
  width: 100%;
  height: 90vh;
  background-image: url(${ImagemMap});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 600px) {
    height: 200px;

    background-image: url(${MapCell});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;

export const PointerMap = styled.div`
  width: ${pixel(185)};
  height: ${pixel(320)};

  background-image: url(${Pointer});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const TextMap = styled.div`
  font-size: ${pixel(28)};
  font-weight: bold;
  margin-bottom: ${pixel(5)};

  @media only screen and (max-width: 600px) {
    font-size: 16pt;
  }
`;

export const SubTextMap = styled.div`
  font-size: ${pixel(18)};
  width: ${pixel(350)};
  text-align: center;

  @media only screen and (max-width: 600px) {
    width: auto;
    font-size: 10pt;
    padding: 0 15px;
    width: 100vw;
  }
`;
