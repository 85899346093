import { Marker } from '@react-google-maps/api';
import { Dispatch, SetStateAction } from 'react';
import { IBoatList } from '../../pages/MapsPage/interface';
import BoatIcon from '../../assets/icons/boat.svg';

interface BoatProps {
  category: string;
  boatList: IBoatList[];
  setSelectedBoat: Dispatch<SetStateAction<IBoatList | null | undefined>>;
  boatModalRef: any;
}

export const renderBoats = ({
  category,
  boatList,
  boatModalRef,
  setSelectedBoat,
}: BoatProps) => {
  if (category.includes('Embarcações')) {
    return boatList?.map(item => (
      <Marker
        key={item.id}
        position={{
          lat: Number(item?.latitude),
          lng: Number(item?.longitude),
        }}
        icon={{
          url: BoatIcon,
          scaledSize: new google.maps.Size(35, 35),
        }}
        onClick={() => {
          setSelectedBoat(item);
          boatModalRef.current?.open();
        }}
      />
    ));
  }
};
