import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  animation-name: ${fade};
  animation-duration: 1s;
`;

interface Props {
  height?: string;
  width?: string;
}

export const Main = styled.div<Props>`
  height: ${props => (props.height ? props.height : '90%')};
  width: ${props => (props.width ? props.width : '700px')};
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 1px 2px 20px 0px var(--gray003);
`;
