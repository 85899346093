import styled from 'styled-components';

export const ModalMarker = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 280px;
  padding-right: 10px;
`;

export const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 5px;
  align-items: center;
`;

export const BannerName = styled.text`
  font-size: 14pt;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 88px;
  border-radius: 8px;
`;

export const BannerDescribe = styled.div`
  font-size: 12pt;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export const StyledText = styled.div`
  max-width: 88%;
  font-size: 10pt;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const EditImage = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 52px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);

  &:hover {
    opacity: 0.8;
  }
`;

export const TrashImage = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);

  &:hover {
    opacity: 0.8;
  }
`;

export const SectionClose = styled.button`
  height: 30px;
  width: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  border: 0;
  margin-left: 10px;

  &:hover {
    opacity: 0.8;
  }
`;
