import { createGlobalStyle, keyframes } from 'styled-components';

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export default createGlobalStyle`

  :root{
    --blue001: #4793CE;
    --blue002: #64CFFF;
    --blue003: #0006B8;
    --blue004: #1694D3;
    --white: #fafafa;
    --white002: #fafafaad;
    --black: #1a1a1a;
    --gray001: #e5e5e5;
    --gray002: #757575;
    --gray003: #999999;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    font-family: 'Nunito';
    background-color: white;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #1694D3;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #0006b8;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #1694D3;
    }

    .gm-style-iw-tc{
      display: none;
    }

    .gm-style-iw {
      border: 0px solid red;
      background-color: #FFFFFFD2 !important;
      margin-left: 116px;
      animation-name: ${fade};
      animation-duration: 0.5s;
      margin-top: -88px;
    }

    .gm-style-iw-d {
      overflow: hidden !important;
      padding: 5px;
    }

    .gm-ui-hover-effect {
      display: none !important;
    }

    .gm-style-iw-t::after{
      display: none;
    }

    .gm-style-iw-c {
      box-shadow: none !important;
    }

    .gm-style-cc {
      display: none;
    }

    .pac-container{
      display: none;
    }

    @media (max-width: 1080px) {
      ::-webkit-scrollbar {
      width: 4px;
    }
    }

    @media (max-width: 720px) {
      ::-webkit-scrollbar {
      width: 2px;
    }
    }
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

    /* animations ================================================= */
    @keyframes rendering{
    from{
      opacity:0
      }
    to{
        opacity:1
    }
  }
`;
