/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';

const notify = (message: string) =>
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
  });

const notifyError = (message: string) =>
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
  });

const notifyInfo = (message: string) =>
  toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
  });

function handleError(err: any, type: string) {
  if (type === 'error') {
    return notifyError(err?.response?.data?.message || err?.message);
  }
  if (type === 'success') {
    return notify(err);
  }
  if (type === 'info') {
    return notifyInfo(err);
  }
}

export default handleError;
