/* eslint-disable camelcase */
import axios from 'axios';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Address, ModalContainer, Response } from './_geocoding';

import Pin from '../../assets/icons/iconPinBlack.svg';

interface Props {
  value: string;
  setLat: Dispatch<SetStateAction<number>>;
  setLng: Dispatch<SetStateAction<number>>;
  showModal: boolean;
}

export interface Geocode {
  results: Result[];
}

export interface Result {
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
}

export const Geocoding = ({ value, setLat, setLng, showModal }: Props) => {
  const [response, setResponse] = useState<Result[]>();

  const fetchApiGeocoding = async (val: string) => {
    if (val !== '') {
      val.replace(' ', '+');

      const { data } = await axios.get<Geocode>(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${val}&key=AIzaSyAVZHZLREXZLj_HZnHS0hT_jTq4PpPqyo4`,
      );

      setResponse(data.results);
    }
  };

  useEffect(() => {
    fetchApiGeocoding(value);
  }, [value]);

  const handleSubmit = (lat: number, lng: number) => {
    setLat(lat);
    setLng(lng);
  };

  const renderAddress = () => {
    if (response) {
      return (
        <Response>
          {response.map(item => (
            <Address
              onClick={() =>
                handleSubmit(
                  item.geometry.location.lat,
                  item.geometry.location.lng,
                )
              }
            >
              <img src={Pin} alt="Icone Address" />
              {item.formatted_address}
            </Address>
          ))}
        </Response>
      );
    }

    return <div />;
  };

  return <ModalContainer>{showModal ? renderAddress() : null}</ModalContainer>;
};
