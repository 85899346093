import styled from 'styled-components';

export const Ticket = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 380px;

  border: 2px solid var(--blue003);
  border-radius: 12px;
  margin: 10px;
  padding: 10px 0 10px 0;
`;

export const Map = styled.div`
  margin-top: 12px;
  width: 92%;
  height: 200px;
  border-radius: 8px;
`;

export const Status = styled.div`
  width: 92%;
  padding: 15px 0;

  display: flex;
  justify-content: space-between;
`;

export const Serie = styled.div`
  font-weight: bold;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 92%;
  align-items: flex-end;
`;

export const ContainerOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.div`
  height: 40px;
  width: 150px;
  border-radius: 10px;
  background: white;
  box-shadow: -2px 2px 1px #e6e6e6;
  border: 1px solid #e6e6e64a;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
    color: var(--blue003);
  }
`;

export const Image = styled.img`
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;
