import { Dispatch, SetStateAction, useState } from 'react';
import { maskPhone, unformatPhone } from '../../../../utils/mask';
import { IContactForm } from '../../interface';
import {
  Button,
  ButtonsContainer,
  Container,
  Content,
  Field,
  Form,
  Input,
  Label,
  Title,
} from './_modalSupport';

export interface IModalSupportFormData {
  telephone: string;
  secondary_telephone: string;
  tertiary_telephone: string;
}

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: IModalSupportFormData) => void;
  initialData?: IContactForm;
}

export const ModalSupport: React.FC<Props> = ({
  initialData,
  onSubmit,
  setOpenModal,
}) => {
  const [telephone, setTelephone] = useState(initialData?.telephone || '');
  const [secondaryTelephone, setSecondaryTelephone] = useState(
    initialData?.secondary_telephone || '',
  );
  const [tertiaryTelephone, setTertiaryTelephone] = useState(
    initialData?.tertiary_telephone || '',
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({
      telephone: unformatPhone(telephone),
      secondary_telephone: unformatPhone(secondaryTelephone),
      tertiary_telephone: unformatPhone(tertiaryTelephone),
    });
  };

  return (
    <Container>
      <Content>
        <Title>Apoio</Title>
        <Form onSubmit={handleSubmit}>
          <Field>
            <Label>Telefone 1</Label>
            <Input
              value={telephone}
              onChange={e => setTelephone(maskPhone(e.target.value))}
              maxLength={15}
              required
            />
          </Field>
          <Field>
            <Label>Telefone 2</Label>
            <Input
              value={secondaryTelephone}
              onChange={e => setSecondaryTelephone(maskPhone(e.target.value))}
              maxLength={15}
            />
          </Field>
          <Field>
            <Label>Telefone 3</Label>
            <Input
              value={tertiaryTelephone}
              onChange={e => setTertiaryTelephone(maskPhone(e.target.value))}
              maxLength={15}
            />
          </Field>
          <ButtonsContainer>
            <Button type="button" onClick={() => setOpenModal(false)}>
              Cancelar
            </Button>
            <Button option>Salvar</Button>
          </ButtonsContainer>
        </Form>
      </Content>
    </Container>
  );
};
