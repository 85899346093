import styled from 'styled-components';

export const ContainerModal = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;

export const Section = styled.div`
  width: 100%;
  height: 80%;

  .overflow {
    overflow-y: scroll;
    width: 100%;

    height: 100%;

    ::-webkit-scrollbar {
      width: 1px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--blue003);
    }

    ::-webkit-scrollbar-track {
      background: #ffffff00;
    }
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 15px;
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  padding: 15px;
  font-size: 20pt;
  font-weight: bold;
  width: 100%;
`;

export const ButtonClose = styled.img`
  position: static;
  padding: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  font-size: 16pt;
  font-weight: 500;
  padding: 0 15px;
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 10px;

  input[type='file'] {
    display: none;
  }
`;

export const ComponentList = styled.div`
  width: 100%;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue003);
  }

  ::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue003);
  }

  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }
`;

export const List = styled.div`
  width: 90%;
  height: 50px;
  border-radius: 16px;
  background-color: white;
  padding: 0 10px;

  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TextId = styled.div`
  color: var(--blue003);
  font-weight: bold;
`;

export const TextType = styled.div`
  display: flex;
  gap: 10px;
  color: #707070;
`;

export const IconAlert = styled.img``;

export const IconPin = styled.img``;
