import { Dispatch, SetStateAction } from 'react';
import { MdDelete } from 'react-icons/md';
import api from '../../../../services/api';
import handleError from '../../../../utils/handleError';
import {
  Container,
  ImageInput,
  ImageLabel,
  Image,
  ImageRow,
  ImageColumn,
  RemoveButton,
} from './_newUploadImages';

interface ImageObject {
  file?: File;
  url?: string;
  id?: string;
}

export interface IEstablishmentImages {
  image1?: ImageObject;
  image2?: ImageObject;
  image3?: ImageObject;
  image4?: ImageObject;
  banner?: ImageObject;
}

interface Props {
  hasBanner: boolean;
  setEstablishmentImages: Dispatch<SetStateAction<IEstablishmentImages>>;
  establishmentImages: IEstablishmentImages;
}

export const NewUploadImages: React.FC<Props> = ({
  setEstablishmentImages,
  hasBanner,
  establishmentImages,
}) => {
  const { image1, image2, image3, image4, banner } = establishmentImages;

  const handleDeleteImage = async (id?: string) => {
    try {
      await api.delete(`/establishment/images/${id}`);

      handleError('A imagem foi deletada!', 'success');
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const handleAddImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof IEstablishmentImages,
  ) => {
    if (!event.target.files) return;

    const file = event.target.files[0];

    if (file) {
      setEstablishmentImages(prev => ({
        ...prev,
        [key]: {
          file,
        },
      }));
    }
  };

  return (
    <Container>
      {hasBanner && (
        <ImageRow>
          <ImageLabel>
            <ImageInput
              type="file"
              accept="image/*"
              onChange={e => handleAddImage(e, 'banner')}
              disabled={!!banner?.id}
            />
            {(banner?.file || banner?.url) && (
              <Image
                src={
                  banner?.file ? URL.createObjectURL(banner.file) : banner.url
                }
                style={{
                  aspectRatio: '400 / 160',
                }}
              />
            )}
            {banner?.id && (
              <RemoveButton
                type="button"
                onClick={() => {
                  handleDeleteImage(banner.id);
                  setEstablishmentImages(prev => ({
                    ...prev,
                    banner: undefined,
                  }));
                }}
              >
                <MdDelete />
              </RemoveButton>
            )}
          </ImageLabel>
        </ImageRow>
      )}
      <ImageRow>
        <ImageLabel>
          <ImageInput
            type="file"
            accept="image/*"
            onChange={e => handleAddImage(e, 'image1')}
            disabled={!!image1?.id}
          />
          {(image1?.file || image1?.url) && (
            <Image
              src={image1?.file ? URL.createObjectURL(image1.file) : image1.url}
            />
          )}
          {image1?.id && (
            <RemoveButton
              type="button"
              onClick={() => {
                handleDeleteImage(image1.id);
                setEstablishmentImages(prev => ({
                  ...prev,
                  image1: undefined,
                }));
              }}
            >
              <MdDelete />
            </RemoveButton>
          )}
        </ImageLabel>
        <ImageColumn>
          <ImageLabel style={{ height: '48%' }}>
            <ImageInput
              type="file"
              accept="image/*"
              onChange={e => handleAddImage(e, 'image2')}
              disabled={!!image2?.id}
            />
            {(image2?.file || image2?.url) && (
              <Image
                src={
                  image2?.file ? URL.createObjectURL(image2.file) : image2.url
                }
              />
            )}
            {image2?.id && (
              <RemoveButton
                type="button"
                onClick={() => {
                  handleDeleteImage(image2.id);
                  setEstablishmentImages(prev => ({
                    ...prev,
                    image2: undefined,
                  }));
                }}
              >
                <MdDelete />
              </RemoveButton>
            )}
          </ImageLabel>
          <ImageLabel style={{ height: '48%' }}>
            <ImageInput
              type="file"
              accept="image/*"
              onChange={e => handleAddImage(e, 'image3')}
              disabled={!!image3?.id}
            />
            {(image3?.file || image3?.url) && (
              <Image
                src={
                  image3?.file ? URL.createObjectURL(image3.file) : image3.url
                }
              />
            )}
            {image3?.id && (
              <RemoveButton
                type="button"
                onClick={() => {
                  handleDeleteImage(image3.id);
                  setEstablishmentImages(prev => ({
                    ...prev,
                    image3: undefined,
                  }));
                }}
              >
                <MdDelete />
              </RemoveButton>
            )}
          </ImageLabel>
        </ImageColumn>
        <ImageLabel>
          <ImageInput
            type="file"
            accept="image/*"
            onChange={e => handleAddImage(e, 'image4')}
            disabled={!!image4?.id}
          />
          {(image4?.file || image4?.url) && (
            <Image
              src={image4?.file ? URL.createObjectURL(image4.file) : image4.url}
            />
          )}
          {image4?.id && (
            <RemoveButton
              type="button"
              onClick={() => {
                handleDeleteImage(image4.id);
                setEstablishmentImages(prev => ({
                  ...prev,
                  image4: undefined,
                }));
              }}
            >
              <MdDelete />
            </RemoveButton>
          )}
        </ImageLabel>
      </ImageRow>
    </Container>
  );
};
