import styled from 'styled-components';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export const Loading = styled(AiOutlineLoading3Quarters)`
  animation: spin 1s linear infinite;
  font-size: 1.25rem;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
