import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconClose from '../../assets/icons/close.svg';
import User from '../../assets/icons/blueUser.svg';
import api from '../../services/api';
import handleError from '../../utils/handleError';
import { MapContainer } from '../MapContainer/MapContainer';

import {
  Section,
  ButtonClose,
  Title,
  Header,
  Container,
  Ticket,
  Map,
  Status,
  Serie,
  Info,
} from './_modalSos';
import { ProgressBar } from './Components/ProgressBar/ProgressBar';
import { UserInformation } from './Components/UserInformation/UserInformation';
import { ButtonOption } from './Components/ButtonOption/ButtonOption';
import { MainSection } from './Components/MainSection/MainSection';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIdChat: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
}

export interface ResponseSOS {
  results: Result[];
  total: number;
}

export interface Result {
  user: User;
  sos: Sos;
  messages_not_viewed: number;
}

export interface Sos {
  id: string;
  latitude: string;
  longitude: string;
  describe: string;
  status: string;
  is_read: boolean;
  user_id: string;
  user: User;
  insurance_id: string;
  insurance: Insurance;
  created_at: string;
  updated_at: string;
  type: string;
  title: string;
  visible: boolean;
  code: number;
}

export interface Insurance {
  id: string;
  name: string;
  logotype: string;
  describe: string;
  telephone: string;
  site: string;
  business_days: any[];
  created_at: string;
  updated_at: string;
  logotype_url: string;
  open_day: OpenDay;
}

export interface OpenDay {
  day: null;
  is_open: boolean;
}

export interface User {
  id: string;
  name: string;
  email: string;
  avatar: null;
  role: string;
  phone: string | null;
  user_valid: boolean;
  boat_id: string | null;
  created_at: string;
  updated_at: string;
  avatar_url: string;
}

export const ModalSos = ({ setOpenModal, setIdChat, setName }: Props) => {
  const [sos, setSos] = useState<Result[]>();
  const [id, setId] = useState<string>('');
  const history = useHistory();

  const fetchApiSOS = async () => {
    try {
      const { data } = await api.get<ResponseSOS>(`/sos/?created=ASC`);

      setSos(data.results.filter(item => item.sos.status !== 'finished'));
    } catch (error) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    fetchApiSOS();
  }, []);

  useEffect(() => {
    fetchApiSOS();
  }, [id]);

  const renderTicket = () => {
    if (sos) {
      return sos.map((item, index) => (
        <>
          {index > 0 && <hr />}
          <Ticket id={item.sos.id}>
            <Map>
              <MapContainer
                latitude={+item.sos.latitude}
                longitude={+item.sos.longitude}
              />
            </Map>
            <Status>
              <Serie>Resgate Nº{index + 1}</Serie>
              <ProgressBar status={item.sos.status} />
            </Status>
            <Info>
              <UserInformation
                latitude={item.sos.latitude}
                longitude={item.sos.longitude}
                user={item.sos.user.name}
                boat={item.sos.user.role}
              />
              <ButtonOption
                status={item.sos.status}
                id={item.sos.id}
                setOpenModal={setOpenModal}
                setIdChat={setIdChat}
                setIdentity={setId}
                name={item.sos.user.name}
                setName={setName}
              />
            </Info>
          </Ticket>
        </>
      ));
    }
  };

  return (
    <Container key={1}>
      <Header>
        <Title>Status de Rastreio dos SOS</Title>
        <ButtonClose
          src={IconClose}
          onClick={() => {
            setOpenModal(false);
            setIdChat('');
            history.push(`/maps`);
          }}
        />
      </Header>

      <Section>
        {sos && (
          <MainSection
            sos={sos}
            setId={setId}
            setIdChat={setIdChat}
            setName={setName}
            setOpenModal={setOpenModal}
          />
        )}

        {renderTicket()}
      </Section>
    </Container>
  );
};
