import styled from 'styled-components';

import LogoCel from '../../assets/img/LogoCel.png';
import LogoBoat from '../../assets/img/logoBoat.svg';

import pixel from '../../utils/size';

interface Props {
  active?: boolean;
}

export const Section = styled.section`
  display: flex;
  justify-content: space-between;
  padding: ${pixel(40)} ${pixel(55)} 0 ${pixel(70)};

  @media only screen and (max-width: 600px) {
    width: 100vw;
    align-items: center;
    padding: 10px 20px 0 10px;
  }
`;

export const Logo = styled.div`
  width: ${pixel(145)};
  height: ${pixel(60)};
  background-image: url(${LogoBoat});
  background-size: 100%;
  background-repeat: no-repeat;

  @media only screen and (max-width: 600px) {
    background-image: url(${LogoCel});
    background-repeat: no-repeat;
    background-size: 120px;
    margin-top: 15px;
    margin-left: 15px;

    width: 120px;
    height: 60px;
  }
`;

export const Option = styled.img<Props>`
  width: ${pixel(30)};
  height: ${pixel(30)};
  display: ${props => (props.active ? 'initial' : 'none')};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;
