import styled from 'styled-components';

export const Section = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--blue002);
`;

export const BannerImage = styled.img`
  position: absolute;
  top: 1px;
  left: 1px;

  width: 33px;
  height: 25px;

  border-radius: 10px;
`;
