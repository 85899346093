import styled from 'styled-components';

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  height: 2.5rem;
  width: ${({ isOpen }) => (isOpen ? 'fit-content' : '2.5rem')};
  min-width: 2rem;
  border-radius: 2rem;

  padding: 0 0.5rem;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);

  background: white;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const IconButton = styled.button`
  border: none;
  background: none;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;

  color: #24272d;

  border-radius: 50%;

  transition: filter 0.2s;

  filter: brightness(0.9);
`;

export const StyledIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
`;
