import styled from 'styled-components';

export const ModalMarker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 257px;
  height: 325px;
  padding-right: 10px;
`;

export const Label = styled.div`
  font-size: 12px;
`;
