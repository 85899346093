import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(3, 3, 3, 0.08);
`;

export const Content = styled.div`
  background-color: #edf0f4;
  border-radius: 16px;
  box-shadow: 1px 2px 20px 0px var(--gray003);
  width: 100%;
  min-height: min(34.6875rem, 90vh);
  max-height: 90vh;
  max-width: 28.75rem;
  padding: 1.25rem 3rem 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
`;

export const Close = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const Column = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 1.5rem;
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DetailLabel = styled.p`
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  padding-left: 0.25rem;
`;

export const Detail = styled.div`
  position: relative;
  background-color: #ffffff;
  height: 43px;
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 0 16px;
  border-radius: 6px;
  font-size: 16px;
  font-family: 'Nunito';
  display: flex;
  align-items: center;
  justify-content: space-between;

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }

  .icon {
    cursor: pointer;
    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const Input = styled.input`
  background: #ffffff;
  height: 43px;
  border: 0px solid var(--gray001);
  width: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-family: 'Nunito';

  ::-webkit-input-placeholder {
    color: #a8a8a8;
  }

  &:focus {
    border: 0;
    outline: none;
  }
`;

export const Button = styled.button`
  font-family: 'Nunito';
  margin: 0 auto;
  margin-top: auto;
  border: 2px solid var(--blue003);
  background-color: var(--blue003);
  color: var(--white);
  border-radius: 8px;
  width: 47%;
  height: 43px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;
