import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useRef } from 'react';
import MapsStyles from '../../assets/json/mapStyle.json';
import { Container } from './_mapContainer';
import IconSos from '../../assets/img/alerts/Sos.png';

interface Props {
  latitude: number;
  longitude: number;
}

export const MapContainer = ({ latitude, longitude }: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAVZHZLREXZLj_HZnHS0hT_jTq4PpPqyo4',
  });

  const mapRef = useRef<GoogleMap>();

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const handleCenter = () => {
    if (mapRef.current) {
      mapRef.current.panTo({
        lat: latitude,
        lng: longitude,
      });
    }
  };

  return (
    <Container>
      {isLoaded ? (
        <GoogleMap
          onLoad={handleCenter}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
            borderRadius: '8px',
          }}
          center={center}
          zoom={13}
          options={{
            styles: MapsStyles,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            zoomControl: false,
            keyboardShortcuts: false,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
          }}
        >
          <Marker
            position={{ lat: latitude, lng: longitude }}
            icon={{
              url: IconSos,
            }}
          />
        </GoogleMap>
      ) : null}
    </Container>
  );
};
