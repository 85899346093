/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-shadow */
import { createContext, useContext, useState } from 'react';
import { IUser } from '../interfaces/User';

interface IAuthContextProps {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
}

interface ChildrenProps {
  children: React.ReactNode;
}

const AuthContext = createContext({} as IAuthContextProps);

export const AuthProvider = ({ children }: ChildrenProps) => {
  const [user, setUser] = useState<IUser>(() => {
    const user = localStorage.getItem('@Booat: user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const useAuth = () => useContext(AuthContext);
