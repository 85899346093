import { Pagination, PaginationItem } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DeleteIcon from '../../../assets/icons/adminDelete.svg';
import SearchIcon from '../../../assets/icons/userSearch.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import { ModalConfirm } from '../../../shared/ModalConfirm/ModalConfirm';
import { ModalUserDetails } from '../ModalUserDetails/ModalUserDetails';
import api from '../../../services/api';
import { maskPhone } from '../../../utils/mask';
import handleError from '../../../utils/handleError';
import { Paginated } from '../../../interfaces/Paginated';
import { IUser } from '../../../interfaces/User';
import {
  ButtonsContainer,
  Close,
  CloseButton,
  Container,
  Content,
  Icon,
  IconButton,
  Input,
  ModalHeader,
  PaginationWrapper,
  RowContainer,
  Table,
  TableWrapper,
  Title,
  Button,
} from './styles';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

type UserPages = Record<number, Paginated<IUser>>;

const limit = 6;

const styles = {
  text: {
    WhiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
  },
  boat: {
    WhiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  },
};

export const ModalUsers: React.FC<Props> = ({ setOpenModal }) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [userPages, setUserPages] = useState<UserPages>();
  const [page, setPage] = useState(1);
  const [maximumPage, setMaximumPage] = useState(1);
  const [search, setSearch] = useState('');

  const getUsers = async () => {
    try {
      const response = await api.get<Paginated<IUser>>('/users/list', {
        params: {
          role: 'User',
          page,
          limit,
          search: search || undefined,
        },
      });

      setUserPages(prev => ({
        ...prev,
        [page]: response.data,
      }));
      setMaximumPage(Math.ceil(response.data.total / limit));
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  const handleDeleteUser = (id: string) => {
    setModalConfirm(true);
    setUserIdToDelete(id);
  };

  const confirmDeleteUser = async () => {
    try {
      setModalConfirm(false);
      setUserIdToDelete('');
      await api.delete(`/users/${userIdToDelete}`);
      getUsers();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const currentPageUsers = userPages?.[page]?.results || [];

  const pageOffset = (page - 1) * limit + 1;

  return (
    <Container>
      <Content>
        <ModalHeader>
          <Title>Usuários</Title>
          <CloseButton onClick={() => setOpenModal(false)}>
            <Close src={CloseIcon} />
          </CloseButton>
        </ModalHeader>

        <RowContainer>
          <Input
            placeholder="Buscar por nome do barco ou proprietário"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <Button type="button" onClick={getUsers}>
            Buscar
            <Icon src={SearchIcon} />
          </Button>
        </RowContainer>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Nº</th>
                <th>Nome</th>
                <th>Celular</th>
                <th>E-mail</th>
                <th>Nome do barco</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {currentPageUsers.map((user, index) => (
                <tr key={user.id}>
                  <td>{pageOffset + index}</td>
                  <td style={styles.text}>{user.name}</td>
                  <td>{maskPhone(user.phone)}</td>
                  <td style={{ ...styles.text }}>{user.email}</td>
                  <td style={styles.boat}>{user?.boats?.[0]?.name || '-'}</td>
                  <td>
                    <ButtonsContainer>
                      <IconButton
                        type="button"
                        onClick={() => setSelectedUser(user)}
                      >
                        <Icon src={SearchIcon} />
                      </IconButton>
                      <IconButton
                        type="button"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        <Icon src={DeleteIcon} />
                      </IconButton>
                    </ButtonsContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            page={page}
            count={maximumPage}
            siblingCount={1}
            onChange={(e, _page) => setPage(_page)}
            hidePrevButton={page === 1}
            hideNextButton={page === maximumPage}
            renderItem={item => {
              return (
                <PaginationItem
                  {...item}
                  style={{
                    color: item.page === page ? '#4793CE' : '#e5e5e5',
                    background: 'transparent',
                    fontSize: '1rem',
                    fontWeight: 500,
                    margin: 0,
                    padding: '0.125rem',
                    minWidth: '1rem',
                  }}
                  classes={{
                    icon: 'icon',
                  }}
                />
              );
            }}
          />
        </PaginationWrapper>
      </Content>
      {modalConfirm && (
        <ModalConfirm
          onCancel={() => setModalConfirm(false)}
          onConfirm={confirmDeleteUser}
          title="Tem certeza que deseja excluir esse usuário"
        />
      )}
      {selectedUser && (
        <ModalUserDetails
          user={selectedUser}
          onClose={() => setSelectedUser(undefined)}
        />
      )}
    </Container>
  );
};
