import { useState } from 'react';
import { FaLock, FaEdit } from 'react-icons/fa';
import Boat1 from '../../../assets/icons/Boat1.svg';
import Boat2 from '../../../assets/icons/Boat2.svg';
import Boat3 from '../../../assets/icons/Boat3.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import { IUser } from '../../../interfaces/User';
import api from '../../../services/api';
import handleError from '../../../utils/handleError';
import { maskPhone } from '../../../utils/mask';
import {
  BoatType,
  BoatTypeLabel,
  BoatTypeRow,
  BoatTypesList,
  BoatTypeWrapper,
  Button,
  ButtonText,
  Close,
  CloseButton,
  Column,
  Container,
  Content,
  Detail,
  DetailLabel,
  Details,
  DetailWrapper,
  Input,
  ModalHeader,
  Title,
} from './styles';

interface Props {
  user: IUser;
  onClose: () => void;
}

export const ModalUserDetails: React.FC<Props> = ({ user, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phone, setPhone] = useState<string>(user?.boats?.[0]?.phone || '');

  const handleResetPassword = async () => {
    setIsSubmitting(true);

    try {
      await api.post('/password/send', {
        email: user.email,
      });
    } catch (error: any) {
      handleError(error, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdatePhone = async () => {
    try {
      await api.put('/users/set-is-sailor', {
        phone,
        user_id: user.id,
      });

      handleError('Telefone atualizado com sucesso!', 'success');
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  return (
    <Container>
      <Content>
        <ModalHeader>
          <Title>Informações adicionais</Title>
          <CloseButton onClick={onClose}>
            <Close src={CloseIcon} />
          </CloseButton>
        </ModalHeader>
        <Details>
          <Column>
            <DetailWrapper>
              <DetailLabel>Modelo do Barco</DetailLabel>
              <Detail>
                {user?.boats?.[0]?.model}
                <FaLock color="#dedede" />
              </Detail>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabel>Ano de construção</DetailLabel>
              <Detail>
                {user?.boats?.[0]?.construction_year}
                <FaLock color="#dedede" />
              </Detail>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabel>Nome do Usuário</DetailLabel>
              <Detail>
                {user?.name} <FaLock color="#dedede" />
              </Detail>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabel>Cidade/UF</DetailLabel>
              <Detail>
                {user?.boats?.[0]?.city &&
                  `${user?.boats?.[0]?.city}-${user?.boats?.[0]?.state}`}
                <FaLock color="#dedede" />
              </Detail>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabel>Telefone do Marinheiro</DetailLabel>
              <Detail>
                <Input
                  value={phone}
                  onChange={e => setPhone(maskPhone(e.target.value))}
                  maxLength={15}
                />
                <FaEdit
                  size={20}
                  className="icon"
                  color="#65dba2"
                  onClick={handleUpdatePhone}
                />
              </Detail>
            </DetailWrapper>
          </Column>
          <Column>
            <BoatTypeRow>
              <Title>Tipo de barco</Title>
              <BoatTypesList>
                <BoatTypeWrapper>
                  <BoatType
                    src={Boat2}
                    selected={user?.boats?.[0]?.type === 'lancha'}
                  />
                  <BoatTypeLabel>Lancha</BoatTypeLabel>
                </BoatTypeWrapper>
                <BoatTypeWrapper>
                  <BoatType
                    src={Boat3}
                    selected={user?.boats?.[0]?.type === 'veleiro'}
                  />
                  <BoatTypeLabel>Veleiro</BoatTypeLabel>
                </BoatTypeWrapper>
                <BoatTypeWrapper>
                  <BoatType
                    src={Boat1}
                    selected={user?.boats?.[0]?.type === 'outros'}
                  />
                  <BoatTypeLabel>Outros</BoatTypeLabel>
                </BoatTypeWrapper>
              </BoatTypesList>
            </BoatTypeRow>
            <DetailWrapper>
              <DetailLabel>Local de guarda</DetailLabel>
              <Detail>
                {user?.boats?.[0]?.location} <FaLock color="#dedede" />
              </Detail>
            </DetailWrapper>
            <Button
              type="button"
              onClick={handleResetPassword}
              disabled={isSubmitting}
            >
              <ButtonText>Reenviar senha</ButtonText>
            </Button>
          </Column>
        </Details>
      </Content>
    </Container>
  );
};
