import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  animation-name: ${fade};
  animation-duration: 1s;
`;

export const Content = styled.div`
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 1px 2px 20px 0px var(--gray003);

  min-height: min(70vh, 39.6875rem);
  max-height: 95vh;
  max-width: 88rem;
  width: 90%;

  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  padding: 2.25rem 1rem 3rem 3rem;
`;

export const ModalHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-weight: bold;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;
`;

export const Close = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const TableWrapper = styled.div`
  width: 100%;
  padding-right: 2rem;

  max-height: 70vh;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #1694d3;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #0006b8;
    border-radius: 10px;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
  font-family: 'Nunito', sans-serif;

  thead {
    text-align: left;
  }

  thead tr th {
    padding: 0.5rem 0.75rem;
  }

  tbody tr td {
    padding: 0.875rem;
    border-top: 1px solid #e9ecef;
    border: none;
    cursor: pointer;
  }

  tbody tr td:last-child {
    width: 4rem;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
`;

export const IconButton = styled.button`
  border: none;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;

  .icon {
    color: #4793ce;
    font-size: 1.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: 50px;
  border: 1px solid #77a0c0;
  width: 80%;
  padding: 0 22px;
  border-radius: 6px;
  font-size: 12pt;
  font-family: 'Nunito';

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;

export const Button = styled.button`
  width: 150px;
  border: 1px solid #77a0c0;
  background-color: var(--white);
  font-size: 12pt;
  color: #77a0c0;
  border-radius: 10px;
  height: 50px;
  margin-left: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

export const InativeButton = styled.button`
  width: 80px;
  border: 1px solid #77a0c0;
  background-color: var(--white);
  font-size: 11pt;
  color: #77a0c0;
  border-radius: 10px;
  height: 30px;
  margin-left: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  float: inline-end;

  &:hover {
    opacity: 0.8;
  }
`;

export const TdCode = styled.td`
  font-weight: 500;
  color: black;
`;

export const PaginationSection = styled.td`
  min-width: 250px;
  max-width: 250px;
  display: flex;
  justify-content: flex-end;
`;
