export const isAuthenticated = () => {
  const accessToken = localStorage.getItem('@Booat: accessToken');
  const refreshToken = localStorage.getItem('@Booat: refreshToken');
  const user = localStorage.getItem('@Booat: user');

  if (accessToken && user && refreshToken) {
    return true;
  }
  return false;
};
