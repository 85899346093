import styled from 'styled-components';
import pixel from '../../../../utils/size';

interface Props {
  rotate: number;
}

export const SectionInfo = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SectionTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: ${pixel(600)};

  @media only screen and (max-width: 600px) {
    width: 100vw;
    justify-content: center;
    margin-top: 15px;
  }
`;

export const TitleTextInfo = styled.div`
  font-size: ${pixel(28)};
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    width: auto;
    font-size: 16pt;
    text-align: center;
  }
`;

export const SubTitleTextInfo = styled.div`
  font-size: ${pixel(18)};

  @media only screen and (max-width: 600px) {
    font-size: 10pt;
    padding: 0 15px;
    text-align: center;
  }
`;

export const SectionTextInfo2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
  width: ${pixel(600)};
  gap: ${pixel(15)};

  @media only screen and (max-width: 600px) {
    width: 100vw;
    justify-content: center;
    text-align: center;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;

    #invisible {
      display: none;
    }
  }
`;

export const CellSocialMedia = styled.div`
  div {
    width: ${pixel(30)};
    height: ${pixel(30)};
    background-color: black;
    border-radius: 50%;
  }

  display: flex;
  gap: ${pixel(10)};

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 75px;

    div {
      width: 30px;
      height: 30px;
      background-color: black;
      border-radius: 50%;
    }
  }
`;

export const Footer = styled.div`
  font-size: ${pixel(16)};

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 8pt;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
`;

export const SectionFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: ${pixel(20)};

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const Phone = styled.img<Props>`
  width: ${pixel(370)};
  transform: rotate(${props => props.rotate}deg);

  @media only screen and (max-width: 600px) {
    transform: rotate(0);
    width: 280px;
  }
`;
