import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../../services/api';
import handleError from '../../utils/handleError';

import {
  ContainerModal,
  ContainerTitle,
  Title,
  Button,
  ContainerButton,
  Section,
  ContainerRow,
  Input,
  AreaText,
} from './_modalAnchor';
import { maskPhone, unformatPhone } from '../../utils/mask';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export interface IPointes {
  results: Result[];
  total: number;
}

export interface Result {
  id: string;
  title: string;
  describe: string;
  icon: null;
  created_at: string;
  updated_at: string;
  icon_url: string;
}

export const ModalAnchor = ({ setOpenModal }: Props) => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [describe, setDescribe] = useState('');
  const [title, setTitle] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [radius, setRadius] = useState<number | null>(null);

  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const handleClearStates = () => {
    setTitle('');
    setLatitude('');
    setLongitude('');
    setDescribe('');
  };

  const onSubmit = async () => {
    try {
      await api.post('/anchor/', {
        latitude,
        longitude,
        title,
        describe,
        radius,
        cellphone: unformatPhone(cellphone),
      });

      handleError('Cadastro realizado com sucesso!', 'success');
      handleClearStates();
      setOpenModal(false);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const handleErro = (message: string) => {
    return notifyError(`${message} é obrigatório!`);
  };

  const handleCadaster = () => {
    if (latitude === '') return handleErro('Latitude');
    if (longitude === '') return handleErro('Longitude');
    if (radius === null) return handleErro('Raio');
    if (title === '') return handleErro('Título');

    onSubmit();
  };

  return (
    <ContainerModal>
      <Section>
        <ContainerTitle>
          <Title>Cadastrar Poita</Title>
        </ContainerTitle>
        <ContainerRow>
          <Input
            value={title}
            placeholder="Nome"
            type="text"
            onChange={e => setTitle(e.target.value)}
          />
        </ContainerRow>
        <ContainerRow>
          <Input
            value={cellphone}
            placeholder="Telefone"
            type="text"
            maxLength={15}
            onChange={e => setCellphone(maskPhone(e.target.value))}
          />
        </ContainerRow>
        <ContainerRow>
          <Input
            value={latitude}
            placeholder="Latitude"
            type="text"
            onChange={e => setLatitude(e.target.value)}
          />
          <Input
            value={longitude}
            placeholder="Longitude"
            type="text"
            onChange={e => setLongitude(e.target.value)}
          />
        </ContainerRow>
        <ContainerRow>
          <AreaText
            value={describe}
            placeholder="Descreva, brevemente o alerta"
            onChange={e => setDescribe(e.target.value)}
          />
        </ContainerRow>
        <ContainerRow>
          <Input
            type="number"
            placeholder="Raio em metros (apenas números)"
            value={radius === null ? '' : radius}
            onChange={e => setRadius(+e.target.value)}
          />
        </ContainerRow>
      </Section>
      <ContainerButton>
        <Button option onClick={() => setOpenModal(false)}>
          Cancelar
        </Button>
        <Button onClick={() => handleCadaster()}>Cadastrar</Button>
      </ContainerButton>
    </ContainerModal>
  );
};
