/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import handleError from '../../utils/handleError';
import {
  ContainerModal,
  ContainerTitle,
  Title,
  Button,
  ContainerButton,
  Section,
  ContainerRow,
  Input,
  AreaText,
  Select,
  Option,
} from './_modalWarning';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export interface IPointes {
  results: Result[];
  total: number;
}

export interface Result {
  id: string;
  title: string;
  describe: string;
  icon: null;
  created_at: string;
  updated_at: string;
  icon_url: string;
}

export const ModalWarning = ({ setOpenModal }: Props) => {
  const [type, setType] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [describe, setDescribe] = useState<string>('');
  const [optionsType, setOptionsType] = useState<Result[]>();

  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const handleClearStates = () => {
    setType('');
    setLatitude('');
    setLongitude('');
    setDescribe('');
  };

  const fecthApiPointer = async () => {
    try {
      const { data } = await api.get<IPointes>('');

      setOptionsType(data.results);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    fecthApiPointer();
  }, []);

  const fetchPoint = async () => {
    try {
      await api.post('/points', {
        latitude,
        longitude,
        typeId: type,
        radius: 15,
      });

      handleError('Cadastro realizado com sucesso!', 'success');
      setOpenModal(false);
      handleClearStates();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const fetchApiInsurance = async () => {
    if (type !== 'Perigo') return fetchPoint();

    try {
      await api.post('/warning/', {
        latitude,
        longitude,
        title: type,
        describe,
        radius: 15,
      });

      // await api.put(`/warning/${data.id}?visible=true`);

      handleError('Cadastro realizado com sucesso!', 'success');
      setOpenModal(false);
      handleClearStates();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const handleErro = (message: string) => {
    return notifyError(`${message} é obrigatório!`);
  };

  const handleCadaster = () => {
    if (type === '') return handleErro('Tipo de Alerta');
    if (latitude === '') return handleErro('Latitude');
    if (longitude === '') return handleErro('Longitude');

    fetchApiInsurance();
  };

  return (
    <ContainerModal>
      <Section>
        <ContainerTitle>
          <Title>Cadastrar Alerta</Title>
        </ContainerTitle>
        <ContainerRow>
          <Select onChange={e => setType(e.target.value)} value={type}>
            <Option value="" disabled selected>
              Tipo de Alerta
            </Option>
            {optionsType?.map(item => (
              <Option value={item.id}>{item.title}</Option>
            ))}
            <Option value="Perigo">Perigo</Option>
          </Select>
        </ContainerRow>
        <ContainerRow>
          <Input
            value={latitude}
            placeholder="Latitude"
            type="text"
            onChange={e => setLatitude(e.target.value)}
          />
          <Input
            value={longitude}
            placeholder="Longitude"
            type="text"
            onChange={e => setLongitude(e.target.value)}
          />
        </ContainerRow>
        <ContainerRow>
          <AreaText
            value={describe}
            placeholder="Descreva, brevemente o alerta"
            onChange={e => setDescribe(e.target.value)}
          />
        </ContainerRow>
      </Section>
      <ContainerButton>
        <Button option onClick={() => setOpenModal(false)}>
          Cancelar
        </Button>
        <Button onClick={() => handleCadaster()}>Cadastrar</Button>
      </ContainerButton>
    </ContainerModal>
  );
};
