/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import handleError from '../../../../utils/handleError';
import { MapContainer } from '../../../MapContainer/MapContainer';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { UserInformation } from '../UserInformation/UserInformation';
import Chat from '../../../../assets/icons/message.svg';
import {
  Ticket,
  Map,
  Status,
  Serie,
  Info,
  ContainerOption,
  Button,
  Image,
} from './_mainSection';
import { Result } from '../../ModalSos';

interface IParams {
  id: string;
}

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIdChat: Dispatch<SetStateAction<string>>;
  setId: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  sos: Result[] | null;
}

export const MainSection = ({
  setOpenModal,
  setId,
  setIdChat,
  setName,
  sos,
}: Props) => {
  const { id } = useParams<IParams>();
  const [sosData, setSosData] = useState<Result | undefined>();

  const fetchApiSos = async () => {
    try {
      const { data } = await api.get(`/sos/${id}`);

      setSosData(data);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    fetchApiSos();
  }, [id, sos]);

  const handleUpdate = async (value: string) => {
    try {
      await api.put(`/sos/${value}`, {
        status: 'coming',
      });

      handleError('O Status foi Alterado!', 'success');
      setId(id);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const renderTicket = () => {
    if (sosData) {
      return (
        <Ticket id={sosData.sos.id}>
          <Serie>{`"${sosData.sos.describe}"`}</Serie>
          <Map>
            <MapContainer
              latitude={+sosData.sos.latitude}
              longitude={+sosData.sos.longitude}
            />
          </Map>
          <Status>
            <Serie>{`"${sosData.sos.describe}"`}</Serie>
            <ProgressBar status={sosData.sos.status} />
          </Status>
          <Info>
            <UserInformation
              latitude={sosData.sos.latitude}
              longitude={sosData.sos.longitude}
              user={sosData.sos.user.name}
              boat={sosData.sos.user.role}
            />
            <ContainerOption>
              <Button
                onClick={() => {
                  if (
                    sosData.sos.status === 'finished' ||
                    sosData.sos.status === 'coming'
                  ) {
                    handleError('Ajuda já foi enviada!', 'info');
                  } else {
                    handleUpdate(id);
                  }
                }}
              >
                Ajuda Enviada
              </Button>
              <Image
                src={Chat}
                width={50}
                onClick={() => {
                  setIdChat(id);
                  setOpenModal(false);
                  setName(sosData.sos.user.name);
                }}
              />
            </ContainerOption>
          </Info>
        </Ticket>
      );
    }

    return <div />;
  };

  return <> {renderTicket()} </>;
};
