import {
  SectionInfo,
  ContainerInfo,
  SectionFooter,
  SectionTextInfo,
  SectionTextInfo2,
  Phone,
  TitleTextInfo,
  SubTitleTextInfo,
  Footer,
  CellSocialMedia,
} from './_lastSection';

import Smartphone from '../../../../assets/img/phone.svg';

const LastSection = () => {
  return (
    <SectionInfo>
      <ContainerInfo>
        <SectionTextInfo>
          <TitleTextInfo>Solicite Ajuda</TitleTextInfo>
          <SubTitleTextInfo>
            Se algo acontecer em sua navegação você pode pedir ajuda para nosso
            suporte, que logo irá mandar socorro até sua localização.
          </SubTitleTextInfo>
        </SectionTextInfo>
        <Phone src={Smartphone} alt="Imagem do smartphone" rotate={0} />
      </ContainerInfo>
      <ContainerInfo style={{ justifyContent: 'right' }}>
        <SectionTextInfo2>
          <TitleTextInfo>Ajude outros navegantes</TitleTextInfo>
          <SubTitleTextInfo>
            Com o recurso de criar alerta, você pode indicar no mapa
            localizações que possam ter perigos, climas ruins ou obstáculos e
            criar uma navegação tranquilo e segura para todos os usuários.
          </SubTitleTextInfo>
        </SectionTextInfo2>
      </ContainerInfo>

      <ContainerInfo>
        <Phone src={Smartphone} alt="Imagem do smartphone" rotate={0} />
        <Phone
          id="invisible"
          src={Smartphone}
          alt="Imagem do smartphone"
          rotate={-20}
        />
      </ContainerInfo>

      <SectionFooter>
        <Footer>Copyright © 2021 Booat. Todos os direitos reservados.</Footer>

        <CellSocialMedia>
          <div />
          <div />
          <div />
        </CellSocialMedia>
      </SectionFooter>
    </SectionInfo>
  );
};

export default LastSection;
