import styled from 'styled-components';
import pixel from '../../../../utils/size';

export const ImageProfile = styled.img`
  position: absolute;
  z-index: 1;

  width: ${pixel(65)};
  right: ${pixel(80)};
  top: ${pixel(16)};

  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  @media only screen and (max-width: 600px) {
    top: 10px;
    left: 5px;
    width: 65px;
  }
`;
