/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import api, { baseURL } from '../../services/api';
import handleError from '../../utils/handleError';
import {
  ContainerModal,
  ContainerTitle,
  Title,
  Button,
  ContainerButton,
  Section,
  ContainerRow,
  Input,
  Label,
  AreaText,
  ListTelephone,
  Telephone,
} from './_insurance';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export interface ResponseInsurance {
  results: Result[];
  total: number;
}

export interface Result {
  id: string;
  name: string;
  describe: string;
  telephone: string;
  logotype_url: string;
}

export interface OpenDay {
  day: null;
  is_open: boolean;
}

export const ModalInsurance = ({ setOpenModal }: Props) => {
  const [name, setName] = useState<string>('');
  const [logotype, setLogotype] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [site, setSite] = useState<string>('');
  const [business, setBusiness] = useState<string>('');
  const [insurance, setInsurance] = useState<Result[]>();

  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const handleClearStates = () => {
    setName('');
    setBusiness('');
    setLogotype('');
    setTelephone('');
    setSite('');
  };

  const postApiInsurance = async () => {
    try {
      const param = new FormData();
      param.append('name', name);
      param.append('logotype', logotype);
      param.append('telephone', telephone);
      param.append('site', site);
      param.append('describe', business);

      await api.post('/insurance', param);

      handleError('Cadastro realizado com sucesso!', 'success');

      setOpenModal(false);

      handleClearStates();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const fetchApiInsurance = async () => {
    try {
      const { data } = await api.get<ResponseInsurance>('/insurance');

      setInsurance(data.results);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    fetchApiInsurance();
  }, []);

  const DeleteEmergency = async (value: string) => {
    try {
      await api.delete(`/insurance/${value}`);

      handleError('Seguradora deletado com sucesso!', 'success');

      fetchApiInsurance();
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const handleErro = (message: string) => {
    return notifyError(`${message} é obrigatório!`);
  };

  const handleCadaster = () => {
    if (name === '') return handleErro('Nome');
    if (telephone === '') return handleErro('Telefone');

    postApiInsurance();
  };

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];
    return file;
  };

  const maskPhone = (value: string) => {
    if (value.length > 10) {
      return value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2})(\d{5})(\d{4})$.*/, '($1) $2-$3');
    }

    return value
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{2})(\d{4})(\d{4})$.*/, '($1) $2-$3');
  };

  const renderInsurance = () => {
    if (insurance) {
      return insurance.map(item => (
        <Telephone>
          <tr style={{ width: '100%' }}>
            <td>
              <img src={item.logotype_url ?? ''} alt="" />
            </td>
            <td>
              {item.name.length > 20
                ? `${item.name.substring(0, 20)}...`
                : item.name}
            </td>
            <td>
              {item.telephone.length > 15
                ? `${item.telephone.substring(0, 15)}...`
                : item.telephone}
            </td>
            <td>
              {item.describe.length > 15
                ? `${item.describe.substring(0, 15)}...`
                : item.describe}
            </td>
            <td>
              <FaTrash
                className="button"
                onClick={() => DeleteEmergency(item.id)}
              />
            </td>
          </tr>
        </Telephone>
      ));
    }
  };

  return (
    <ContainerModal>
      <Section>
        <ContainerTitle>
          <Title>Cadastrar Seguradora</Title>
        </ContainerTitle>
        <ContainerRow>
          <Input
            value={name}
            placeholder="Nome do Seguro"
            type="text"
            onChange={e => setName(e.target.value)}
          />
          <Label htmlFor="arquivo">
            {logotype ? `Logomarca adicionada` : 'Adicione a logomarca'}
          </Label>
          <input
            type="file"
            name="arquivo"
            id="arquivo"
            accept="image/x-png,image/jpeg"
            onChange={e => setLogotype(handleFileInput(e))}
          />
        </ContainerRow>
        <ContainerRow>
          <Input
            value={telephone}
            placeholder="Telefone"
            type="text"
            onChange={e => setTelephone(maskPhone(e.target.value))}
            maxLength={15}
          />
        </ContainerRow>
        <ContainerRow>
          <Input
            value={site}
            placeholder="Site"
            type="text"
            onChange={e => setSite(e.target.value)}
          />
        </ContainerRow>
        <ContainerRow>
          <AreaText
            value={business}
            placeholder="Descrição da emergência..."
            onChange={e => setBusiness(e.target.value)}
          />
        </ContainerRow>

        <ListTelephone>{renderInsurance()}</ListTelephone>
      </Section>
      <ContainerButton>
        <Button option onClick={() => setOpenModal(false)}>
          Cancelar
        </Button>
        <Button onClick={() => handleCadaster()}>Confirmar</Button>
      </ContainerButton>
    </ContainerModal>
  );
};
