import {
  Button,
  ButtonsContainer,
  ButtonText,
  Container,
  Content,
  Title,
} from './_modalConfirm';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
}

export const ModalConfirm: React.FC<Props> = ({
  title,
  onConfirm,
  onCancel,
}) => {
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <ButtonsContainer>
          <Button option onClick={onCancel}>
            <ButtonText>Cancelar</ButtonText>
          </Button>
          <Button onClick={onConfirm}>
            <ButtonText>Confirmar</ButtonText>
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
