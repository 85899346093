/* eslint-disable no-console */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IAnchor } from '../../../interfaces/Anchor';
import api from '../../../services/api';
import handleError from '../../../utils/handleError';
import { Modal } from '../../Modal/Modal';
import {
  AreaText,
  Button,
  ContainerButton,
  ContainerModal,
  ContainerRow,
  ContainerTitle,
  Input,
  Section,
  Title,
} from './_modalEditAnchor';

interface Props {
  setIdAnchor: Dispatch<SetStateAction<string>>;
  id: string;
  onFinish: () => void;
}

const ModalEditAlert = ({ id, onFinish, setIdAnchor }: Props) => {
  const [title, setTitle] = useState<string>('');
  const [describe, setDescribe] = useState<string>('');

  const handleUpdate = async () => {
    try {
      await api.put(`/anchor/${id}`, {
        title,
        describe,
      });

      onFinish();
      handleError('Alterado com sucesso!', 'success');
      setIdAnchor('');
      setDescribe('');
      setTitle('');
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchAnchor = async () => {
    if (!id) {
      return;
    }

    try {
      const { data } = await api.get<IAnchor>(`/anchor/${id}`);

      setDescribe(data.describe);
      setTitle(data.title);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAnchor();
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <Modal>
      <ContainerModal>
        <Section>
          <ContainerTitle>
            <Title>Editar Poita</Title>
          </ContainerTitle>
          <ContainerRow>
            <Input
              value={title}
              placeholder="Nome"
              type="text"
              onChange={e => setTitle(e.target.value)}
            />
          </ContainerRow>
          <ContainerRow>
            <AreaText
              value={describe}
              placeholder="Descreva brevemente a poita"
              onChange={e => setDescribe(e.target.value)}
            />
          </ContainerRow>
        </Section>
        <ContainerButton>
          <Button option onClick={() => setIdAnchor('')}>
            Cancelar
          </Button>
          <Button onClick={() => handleUpdate()}>Editar</Button>
        </ContainerButton>
      </ContainerModal>
    </Modal>
  );
};

export default ModalEditAlert;
