/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, PaginationItem } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FaPlus, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import DeleteIcon from '../../../assets/icons/adminDelete.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import api from '../../../services/api';
import handleError from '../../../utils/handleError';
import { ModalConfirm } from '../../../shared/ModalConfirm/ModalConfirm';
import { Paginated } from '../../../interfaces/Paginated';
import { ICodes } from '../../../interfaces/Codes';
import { ModalRegisterCode } from '../ModalRegisterCode/ModalRegisterCode';
import {
  Button,
  ButtonsContainer,
  Close,
  CloseButton,
  Container,
  Content,
  Icon,
  IconButton,
  InativeButton,
  Input,
  ModalHeader,
  PaginationSection,
  PaginationWrapper,
  RowContainer,
  Table,
  TableWrapper,
  TdCode,
  Title,
} from './styles';

const styles = {
  background: 'transparent',
  fontSize: '1rem',
  fontWeight: 500,
  margin: 0,
  padding: '0.125rem',
  minWidth: '1rem',
};

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const ModalCodes: React.FC<Props> = ({ setOpenModal }) => {
  const [deletingId, setDeletingId] = useState<string | undefined>();
  const [inactiveId, setInactiveId] = useState<string | undefined>();
  const [registerModal, setRegisterModal] = useState(false);

  const [codes, setCodes] = useState<ICodes[]>([]);
  const [relations, setRelations] = useState<ICodes[]>([]);
  const [defaults, setDefaults] = useState<ICodes[]>([]);
  const [selected, setSelected] = useState<ICodes>();

  const [page, setPage] = useState(1);
  const [pageR, setPageRelations] = useState(1);
  const [maximumPage, setMaximumPage] = useState(1);
  const [relationsMax, setRelationsMax] = useState(1);
  const [search, setSearch] = useState('');

  const getRoutes = async () => {
    try {
      const params = {
        page,
        limit: 6,
        'filters[is_main]': true,
      };

      const { data } = await api.get<Paginated<ICodes>>(`/gift-code`, {
        params,
      });

      setCodes(data.results);
      setDefaults(data.results);
      setMaximumPage(Math.ceil(data.total / 6));
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    getRoutes();
  }, [page]);

  const getRelations = async () => {
    if (!selected?.id) return;

    try {
      const params = {
        'relations[child_codes][page]': pageR,
        'relations[child_codes][limit]': 4,
      };

      const { data } = await api.get<ICodes>(`/gift-code/${selected?.id}`, {
        params,
      });

      const relationsData = data.child_codes_paginated.results || [];
      const max = Math.ceil(data.child_codes_paginated.total / 4);

      setRelations(relationsData);
      setRelationsMax(max || 1);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    getRelations();
  }, [selected, pageR]);

  const handleSearch = async () => {
    if (!search) return setCodes(defaults);

    try {
      const params = {
        'filters[is_main]': true,
        'filters[code]': search,
        page: 1,
        limit: 6,
      };

      const { data } = await api.get<Paginated<ICodes>>(`/gift-code`, {
        params,
      });

      setCodes(data.results);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const onDelete = async () => {
    if (!deletingId) return;

    try {
      await api.delete(`/gift-code/${deletingId}`);

      setDeletingId(undefined);
      handleError('Código deletado com sucesso.', 'success');
      getRoutes();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const onInactive = async () => {
    if (!inactiveId) return;

    try {
      await api.patch(`/gift-code/cancel-code/${inactiveId}`);

      setInactiveId(undefined);
      handleError('Código inativado com sucesso.', 'success');
      getRoutes();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const onSelect = (code: ICodes) => {
    if (selected === code) setSelected(undefined);
    else setSelected(code);
  };

  return (
    <Container>
      <Content>
        <ModalHeader>
          <Title>Listagem de códigos</Title>
          <CloseButton onClick={() => setOpenModal(false)}>
            <Close src={CloseIcon} />
          </CloseButton>
        </ModalHeader>

        <RowContainer>
          <Input
            placeholder="Buscar por código"
            value={search}
            onChange={e => setSearch(e.target.value)}
            onBlur={() => handleSearch()}
            onKeyDown={e => e.key === 'Enter' && handleSearch()}
          />

          <Button type="button" onClick={() => setRegisterModal(true)}>
            <FaPlus size={18} />
            Adicionar
          </Button>
        </RowContainer>

        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Código</th>
                <th>Inicío</th>
                <th>Vencimento</th>
                <th>Usado em</th>
                <th>Usado por</th>
                <th>Status</th>
                <th style={{ textAlign: 'right' }}>Ações</th>
              </tr>
            </thead>

            <tbody>
              {codes.map(code => (
                <>
                  <tr key={code.id}>
                    <TdCode
                      style={{ width: '180px' }}
                      onClick={() => onSelect(code)}
                    >
                      {code.name}
                    </TdCode>

                    <TdCode onClick={() => onSelect(code)}>{code.code}</TdCode>

                    <TdCode
                      style={{ width: '120px' }}
                      onClick={() => onSelect(code)}
                    >
                      {code.start_date &&
                        format(parseISO(code.start_date), 'dd/MM/yyyy')}
                    </TdCode>

                    <TdCode
                      style={{ width: '120px' }}
                      onClick={() => onSelect(code)}
                    >
                      {code.end_date &&
                        format(parseISO(code.end_date), 'dd/MM/yyyy')}
                    </TdCode>

                    <TdCode
                      style={{ width: '120px' }}
                      onClick={() => onSelect(code)}
                    />

                    <TdCode
                      style={{ width: '120px' }}
                      onClick={() => onSelect(code)}
                    />

                    <TdCode
                      style={{ width: '120px' }}
                      onClick={() => onSelect(code)}
                    />

                    <TdCode
                      style={{
                        maxWidth: '350px',
                        minWidth: '350px',
                        textAlign: 'right',
                      }}
                    >
                      <ButtonsContainer>
                        <IconButton
                          type="button"
                          onClick={() => setDeletingId(code.id)}
                        >
                          <Icon src={DeleteIcon} />
                        </IconButton>

                        {selected === code ? (
                          <FaChevronUp
                            size={22}
                            color="#4793ce"
                            onClick={() => onSelect(code)}
                          />
                        ) : (
                          <FaChevronDown
                            size={22}
                            color="#4793ce"
                            onClick={() => onSelect(code)}
                          />
                        )}
                      </ButtonsContainer>
                    </TdCode>
                  </tr>

                  {selected === code &&
                    relations?.map(item => (
                      <tr key={item.id}>
                        <td style={{ color: '#77a0c0' }}>{item.name}</td>

                        <td style={{ color: '#77a0c0' }}>{item.code}</td>

                        <td style={{ color: '#77a0c0' }}>
                          {code.start_date &&
                            format(parseISO(code.start_date), 'dd/MM/yyyy')}
                        </td>

                        <td style={{ color: '#77a0c0' }}>
                          {code.end_date &&
                            format(parseISO(code.end_date), 'dd/MM/yyyy')}
                        </td>

                        <td style={{ color: '#77a0c0' }}>
                          {item.used_at &&
                            format(parseISO(item.used_at), 'dd/MM/yyyy')}
                        </td>

                        <td style={{ color: '#77a0c0' }}>
                          {item.user?.name || ''}
                        </td>

                        <td style={{ color: '#77a0c0' }}>
                          {item.is_active ? 'Ativo' : 'Inativo'}
                        </td>

                        <td style={{ minWidth: '350px' }}>
                          {item.is_active && (
                            <InativeButton
                              onClick={() => setInactiveId(item.id)}
                            >
                              Inativar
                            </InativeButton>
                          )}
                        </td>
                      </tr>
                    ))}

                  {selected === code && relationsMax > 1 && (
                    <tr key={code.id}>
                      <td colSpan={7} />
                      <PaginationSection>
                        <Pagination
                          page={pageR}
                          count={relationsMax}
                          siblingCount={1}
                          onChange={(e, _page) => setPageRelations(_page)}
                          hidePrevButton={pageR === 1}
                          hideNextButton={pageR === relationsMax}
                          renderItem={i => {
                            return (
                              <PaginationItem
                                {...i}
                                style={{
                                  ...styles,
                                  color:
                                    i.page === pageR ? '#4793CE' : '#888888',
                                }}
                                classes={{ icon: 'icon' }}
                              />
                            );
                          }}
                        />
                      </PaginationSection>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        {maximumPage > 1 && (
          <PaginationWrapper>
            <Pagination
              page={page}
              count={maximumPage}
              siblingCount={1}
              onChange={(e, _page) => setPage(_page)}
              hidePrevButton={page === 1}
              hideNextButton={page === maximumPage}
              renderItem={item => {
                return (
                  <PaginationItem
                    {...item}
                    style={{
                      ...styles,
                      color: item.page === page ? '#4793CE' : '#888888',
                    }}
                    classes={{ icon: 'icon' }}
                  />
                );
              }}
            />
          </PaginationWrapper>
        )}
      </Content>

      {deletingId && (
        <ModalConfirm
          onCancel={() => setDeletingId(undefined)}
          onConfirm={onDelete}
          title="Tem certeza que deseja excluir esse código?"
        />
      )}

      {inactiveId && (
        <ModalConfirm
          onCancel={() => setInactiveId(undefined)}
          onConfirm={onInactive}
          title="Tem certeza que deseja inativar esse código?"
        />
      )}

      {registerModal && (
        <ModalRegisterCode
          onClose={() => {
            setRegisterModal(false);
            getRoutes();
          }}
        />
      )}
    </Container>
  );
};
