/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { IoSend } from 'react-icons/io5';
import { io, Socket } from 'socket.io-client';
import IconClose from '../../assets/icons/closeChat.svg';

import api from '../../services/api';
import handleError from '../../utils/handleError';

import {
  Action,
  Chat,
  Header,
  Input,
  MessagesClient,
  Send,
  Start,
  Window,
  ListChat,
  Icon,
  MessagesHeader,
  MessageRow,
  HeaderRow,
  ImgHeader,
  Paragraph,
} from './styles';

import { MessageList, UserMessage, Users } from '../../interfaces/Chat';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  setShowChat: Dispatch<SetStateAction<boolean>>;
  setIdChat: Dispatch<SetStateAction<string>>;
  setUser: Dispatch<SetStateAction<UserMessage>>;
  user: UserMessage;
}

export const Conversation = ({
  setShowChat,
  setIdChat,
  setUser,
  user,
}: Props) => {
  const { user: _user } = useAuth();
  const [user_info, setUserInfo] = useState<Users>();
  const [newMessage, setNewMessage] = useState<string>('');
  const [messageChat, setMessageChat] = useState<MessageList[]>([]);
  const socketRef = useRef<Socket>();
  const baseURL = process.env.REACT_APP_API_URL || '';

  const setLocalStorage = () => {
    const dataUser = localStorage.getItem('@Booat: user');
    if (dataUser) setUserInfo(JSON.parse(dataUser));
  };

  const fecthApiMessage = async () => {
    if (user_info) {
      try {
        if (user.type === 'concierge') {
          const { data } = await api.get<MessageList[]>(
            `/concierge/chat/${user?.id}`,
          );

          setMessageChat(data);
        } else {
          const { data } = await api.get<MessageList[]>(`/chat/${user.id}`);

          setMessageChat(data);
        }
      } catch (error) {
        handleError(error, 'error');
      }
    }
  };

  useEffect(() => {
    setLocalStorage();
  }, []);

  const handleConnectSocketIo = () => {
    try {
      const socket = io(baseURL, {
        query: { id: _user?.id },
      });

      socket.on('connect', () => {
        fecthApiMessage();
      });

      socket.on(user?.id, msg => {
        if (!Array.isArray(msg)) {
          const message = {
            id: msg.id,
            message: msg.message,
            user_id: msg.user_id,
          };
          setMessageChat(messages => [...messages, message]);
        }
      });

      socketRef.current = socket;
      return socket;
    } catch (err) {
      handleError(err, 'error');
    }
  };

  useEffect(() => {
    if (user.id !== '') {
      socketRef.current?.close();

      const socket = handleConnectSocketIo();

      return () => {
        socket?.off('connect');
        socket?.off('receiveMessage');
      };
    }
  }, [user_info, user]);

  const sendMessage = async (value: string) => {
    if (value === '') return;

    if (user_info) {
      try {
        const messageNew = { text: value };

        const { data } = await api.post(
          user.type === 'concierge'
            ? `/concierge/chat/${user.id}`
            : `/chat/${user.id}`,
          messageNew,
        );

        setMessageChat([...messageChat, data]);
        setNewMessage('');
      } catch (error) {
        handleError(error, 'error');
      }
    }
  };

  const something = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      sendMessage(newMessage);
      setNewMessage('');
    }
  };

  const onClose = () => {
    setShowChat(false);
    setMessageChat([]);
    setUser({ id: '', name: '', avatar: '', type: '' });
    setIdChat('');
  };

  const onSend = () => {
    sendMessage(newMessage);
    setNewMessage('');
  };

  return (
    <>
      <Header>
        <HeaderRow>
          <ImgHeader src={user.avatar} alt="" />
          <Paragraph>{user.name || 'Cliente'}</Paragraph>
        </HeaderRow>
        <Icon src={IconClose} onClick={onClose} />
      </Header>
      <ListChat>
        <Chat>
          <MessagesClient ref={ref => ref && ref.scrollTo(0, 10000000000)}>
            <MessagesHeader>
              <Start>
                {user.type.includes('concierge')
                  ? 'Concierge'
                  : 'S.O.S Solicitado.'}
              </Start>
            </MessagesHeader>
            {messageChat &&
              messageChat.map(item => (
                <MessageRow>
                  <Window active={item.user_id === user_info?.id ?? false}>
                    {item.message}
                  </Window>
                </MessageRow>
              ))}
          </MessagesClient>
          <Action>
            <Input
              onChange={e => setNewMessage(e.target.value)}
              value={newMessage}
              onKeyDown={e => something(e)}
            />
            <Send onClick={onSend}>
              <IoSend color="#4793ce" size={20} />
            </Send>
          </Action>
        </Chat>
      </ListChat>
    </>
  );
};
