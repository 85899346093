/* eslint-disable prettier/prettier */
/* eslint-disable react/no-children-prop */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import {
  GoogleMap,
  InfoWindow,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Polyline,
} from '@react-google-maps/api';

import { io, Socket } from 'socket.io-client';
import { useHistory } from 'react-router-dom';
import MapsStyles from '../../assets/json/mapStyle.json';
import handleError from '../../utils/handleError';
import api from '../../services/api';

// icons

import Logo from '../../assets/img/booatLogo.png';
import BannerPoint from '../../assets/bannerIcon.svg';
import FlagIcon from '../../assets/flagIcon.svg';
import PinWhite from '../../assets/pinWhite.svg';
import bubble from '../../assets/img/establishment/teste.svg';
import Sound from '../../assets/sounds/apple.mp3';

import { ModalEstablishment } from '../../shared/ModalEstablichment/ModalEstablishment';
import { ModalAlertHistory } from '../../shared/ModalAlertHistory/ModalAlertHistory';
import { ModalInsurance } from '../../shared/ModalInsurance/ModalInsurance';
import { ModalEmergency } from '../../shared/ModalEmergency/ModalEmergency';
import { ModalWarning } from '../../shared/ModalWarning/ModalWarning';
import { WindowAlert } from '../../shared/WindowAlert/WindowAlert';
import { Geocoding } from '../../shared/GeocodingMap/Geocoding';
import { Alerts } from './Components/ModalAlerts/ModalAlerts';
import { User } from './Components/ModalUser/ModalUser';
import { Navbar } from '../../shared/Navbar/Navbar';
import { Modal } from '../../shared/Modal/Modal';
import { Section } from './styles';

import BarWrappers from './Components/BarWrappers/BarWrappers';
import MapStatus from './Components/MapStatus/MapStatus';
import ModalEditAlert from '../../shared/WindowAlert/Components/ModalEditAlert';
import { WindowPointer } from '../../shared/WindowPointer/WindowAlert';
import { ResponseSOS, Result } from '../../shared/ModalSos/ModalSos';
import { ModalAdmin } from '../../shared/ModalAdmin/ModalAdmin';
import { ModalUsers } from '../../components/Modals/ModalUsers/ModalUsers';
import { ModalDraw } from './Components/ModalDraw/ModalDraw';
import { renderIconsMaps } from '../../utils/renderIcons';
import { ModalBanner } from '../../components/Modals/ModalBanner/ModalBanner';

import {
  Establishment,
  IBannerList,
  IBoatList,
  MultiRoutes,
  Point,
  ResponseBoats,
  ResponseConcierge,
  ResponseEstablishment,
  ResponsePoint,
  ResponseWarning,
  Warning,
} from './interface';

import EstablishmentDetails from '../../components/EstablishmentDetails/EstablishmentDetails';
import BannerDetails from '../../components/BannerDetails/BannerDetails';
import { ModalDrawRegister } from '../../components/Modals/ModalDrawRegister/ModalDrawRegister';
import { ModalRoutes } from '../../components/Modals/ModalRoutes/ModalRoutes';
import { ChatMapIcon } from '../../components/ChatMapIcon/ChatMapIcon';
import { useAuth } from '../../hooks/useAuth';
import { ModalAnchor } from '../../shared/ModalAnchor/ModalAnchor';
import { IAnchor } from '../../interfaces/Anchor';
import { WindowAnchor } from '../../shared/WindowAnchor/WindowAnchor';
import ModalEditAnchor from '../../shared/WindowAnchor/Components/ModalEditAnchor';
import { ModalRadius } from '../../shared/ModalRadius/ModalRadius';
import { ModalCodes } from '../../components/Modals/ModalCodes/ModalCodes';
import { BoatModal, PropsBoatRef } from '../../components/Windows/Boat/Boat';
import { renderBoats } from '../../components/Markers/markers';

const center = {
  lat: -23.983668684753727,
  lng: -46.256899237644305,
};

export const MapsPage = () => {
  const { user } = useAuth();
  const boatModalRef = useRef<PropsBoatRef>(null);

  const [modalRoutes, setModalRoutes] = useState<boolean>(false);
  const [modalAlertHistory, setModalAlertHistory] = useState<boolean>(false);
  const [modalAlertEdit, setModalAlertEdit] = useState<boolean>(false);
  const [modalInsurance, setModalInsurance] = useState<boolean>(false);
  const [modalEmergency, setModalEmergency] = useState<boolean>(false);
  const [modalAdmin, setModalAdmin] = useState<boolean>(false);
  const [modalUsers, setModalUsers] = useState<boolean>(false);
  const [establishment, setEstablishment] = useState<boolean>(false);
  const [modalBanner, setModalBanner] = useState<boolean>(false);
  const [modalWarning, setModalWarning] = useState<boolean>(false);
  const [userOptions, setUserOptions] = useState<boolean>(false);
  const [modalAlerts, setModalAlerts] = useState<boolean>(false);
  const [modalSearch, setModalSearch] = useState<boolean>(false);
  const [modalSos, setModalSos] = useState<boolean>(false);
  const [navbar, setNavbar] = useState<boolean>(false);
  const [notification, setNotification] = useState<boolean>(false);
  const [notification2, setNotification2] = useState<boolean>(false);
  const [showAnchor, setShowAnchor] = useState<boolean>(false);
  const [radius, setRadius] = useState<boolean>(false);
  const [codes, setCodes] = useState<boolean>(false);

  const [numberMessage, setNumberMessage] = useState<number>(0);
  const [numberConcierge, setNumberConcierge] = useState<number>(0);
  const [type, setType] = useState<string>('');

  const [selectedParker, setSelectedParker] = useState<Establishment | null>();
  const [selectedNotify, setSelectedNotify] = useState<ResponsePoint | null>();
  const [selectedPoint, setSelectedPoint] = useState<ResponsePoint | null>();
  const [selectedBanner, setSelectedBanner] = useState<IBannerList | null>();
  const [selectedAnchor, setSelectedAnchor] = useState<IAnchor | null>();
  const [selectedBoat, setSelectedBoat] = useState<IBoatList | null>();

  const [warning, setWarning] = useState<Warning[]>();
  const [point, setPoint] = useState<ResponsePoint[]>();
  const [info, setInfo] = useState<Establishment[]>();
  const [bannerList, setBannerList] = useState<IBannerList[]>();
  const [boatList, setBoatList] = useState<IBoatList[]>([]);
  const [sos, setSos] = useState<Result[]>();
  const [anchors, setAnchors] = useState<IAnchor[]>([]);
  const [notificationConcierge, setNotificationConcierge] = useState<
    ResponseConcierge[]
  >([]);

  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [path, setPath] = useState<google.maps.LatLng[]>([]);
  const [pathObj, setPathObj] = useState<MultiRoutes[]>([]);

  const [optionInfo, setOptionInfo] = useState<string>('');
  const [longitude, setLongitude] = useState<number>(0);
  const [category, setCategory] = useState<string>('');
  const [latitude, setLatitude] = useState<number>(0);
  const [idAlert, setIdAlert] = useState<string>('');
  const [map, setMap] = useState<google.maps.Map>();
  const [option, setOption] = useState<string>('');
  const [idChat, setIdChat] = useState<string>('');
  const [nameChat, setName] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [idEst, setIdEst] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [idAnchor, setIdAnchor] = useState('');

  const [velocity, setVelocity] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [nameOrigin, setNameOrigin] = useState<string>('');
  const [nameDestiny, setNameDestiny] = useState<string>('');

  const baseURL = process.env.REACT_APP_API_URL || '';
  const socketRef = useRef<Socket>();
  const history = useHistory();

  const handleConnectSocketIo = () => {
    try {
      const userData = localStorage.getItem('@Booat: user') || '{}';
      const userForm = JSON.parse(userData);

      const socket = io(baseURL, {
        query: { id: userForm.id },
      });

      socket.on('connect', () => fetchApiSos());
      socket.on('new_sos', () => {
        fetchApiSos();
        handlePlaySound();
      });
      socket.on('new_message', () => {
        fetchApiMessage();
        handlePlaySound();
      });
      socket.on('create_warning', () => {
        fetchApiWarning();
        handlePlaySound();
      });
      socket.on('new_concierge', () => {
        fetchApiConcierge();
        handlePlaySound();
      });
      socket.on('new_message_concierge', () => {
        handleVerificationNotify('Nova mensagem de concierge');
        handlePlaySound();
      });

      socketRef.current = socket;
      return socket;
    } catch (err) {
      handleError(err, 'error');
    }
  };

  const connectSocket = () => {
    socketRef.current?.close();
    const socket = handleConnectSocketIo();

    return () => {
      socket?.close();
    };
  };

  const handlePlaySound = () => {
    new Audio(Sound).play();
  };

  useEffect(() => {
    if (search !== '') {
      setModalSearch(true);
    } else {
      setModalSearch(false);
    }
  }, [search]);

  const onPlacesChanged = () => {
    const location = {
      lat: latitude,
      lng: longitude,
    };

    map?.panTo(location);
    setSearch('');
  };

  useEffect(() => {
    onPlacesChanged();
  }, [latitude]);

  const fetchApiMarker = async () => {
    try {
      const { data } = await api.get<ResponseEstablishment>('/establishment');

      if (category) {
        const categoryFilter =
          category === 'Restaurantes'
            ? data.results.filter(item => item.category === 'Restaurante')
            : [];

        const categoryFilter2 =
          category === 'Ponto de Abastecimento' &&
          data.results.filter(item => item.category === 'Posto de Combustível');

        setInfo(categoryFilter.concat(categoryFilter2 || []));
      } else {
        setInfo(data.results);
      }
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiSos = async () => {
    try {
      const { data } = await api.get<ResponseSOS>('/sos');

      data.results.map(item => {
        item.sos.type = 'SOS';
      });

      const responseCategory = data.results.filter(
        item =>
          item.sos.type === category && !item.sos.status.includes('finished'),
      );

      const response = data.results.filter(
        item => !item.sos.status.includes('finished'),
      );

      if (category) {
        setSos(responseCategory);
      } else {
        setSos(response);
      }

      if (
        response.filter(
          item => !item.sos.is_read && !item.sos.status.includes('finished'),
        ).length > 0
      ) {
        handleVerificationNotify('Você recebeu uma nova solicitação de SOS');
      }

      setNotification(
        data.results.some(
          item => !item.sos.is_read && !item.sos.status.includes('finished'),
        ),
      );

      setNumberMessage(0);

      data.results.map(item =>
        setNumberMessage(value => value + item.messages_not_viewed),
      );
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiMessage = async () => {
    try {
      const { data } = await api.get<ResponseSOS>('/sos');

      setNumberMessage(0);

      const messages = data.results.filter(
        _sos => !_sos.sos.status.includes('finished'),
      ).length;

      data.results.map(item => {
        if (!item.sos.status.includes('finished')) {
          setNumberMessage(messages);
        }
      });
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiConcierge = async () => {
    try {
      const { data } = await api.get<ResponseConcierge[]>('/concierge/admin');

      setNumberConcierge(
        data.filter(item => !item.admin_viewed && item.is_active).length,
      );

      if (
        data.filter(item => !item.admin_viewed && item.is_active).length > 0
      ) {
        handleVerificationNotify('Existe conversar abertas com o concierge');
      }

      const result = data
        .filter(item => item.is_active)
        .sort((a, b) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });

      setNotificationConcierge(result.filter(item => item.is_active));
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiPoint = async () => {
    try {
      const { data } = await api.get<Point>('/points/');

      data.results.map(item => {
        item.type_text = 'Alertas & Perigos';
      });

      if (category) {
        setPoint(data.results.filter(item => item.type_text === category));
      } else {
        setPoint(data.results);
      }
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiBanner = async () => {
    try {
      const { data } = await api.get<IBannerList[]>('/banners/');

      setBannerList(data);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiBoats = async () => {
    try {
      const { data } = await api.get<ResponseBoats>('/users/list/location');
      setBoatList(data.results);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiWarning = async () => {
    try {
      const { data } = await api.get<ResponseWarning>('/warning');

      data.results.map(item => {
        item.type = 'Alertas & Perigos';
      });

      if (category) {
        setWarning(
          data.results.filter(item => item.type === category && item.visible),
        );
      } else {
        setWarning(data.results.filter(item => item.visible));
      }

      setNotification2(
        data.results.some(item => {
          if (item.visible === null)
            handleVerificationNotify('Um novo alerta para aprovação');

          return item.visible === null ? !item.is_read : false;
        }),
      );
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiAnchors = async () => {
    try {
      const { data } = await api.get<{
        results: IAnchor[];
      }>('/anchor');

      setAnchors(data.results);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const handleVerificationNotify = (message: string) => {
    if (window.Notification && Notification.permission !== 'denied') {
      Notification.requestPermission().then(() => {
        Notification.requestPermission(() => {
          const n = new Notification('Notificação', {
            body: message,
            icon: Logo,
          });

          n.onclick = () => {
            window.location.href = '/maps';
          };
        });
      });
    }
  };

  useEffect(() => {
    if (category === 'Embarcações') {
      fetchApiBoats();
      setInterval(fetchApiBoats, 300000);
    }
  }, [category])

  useEffect(() => {
    fetchApiMarker();
    fetchApiWarning();
    fetchApiSos();
    fetchApiPoint();
    fetchApiBanner();
    fetchApiConcierge();
    fetchApiAnchors();
    connectSocket();
  }, [history, socketRef]);

  useEffect(() => {
    fetchApiMarker();
    fetchApiWarning();
    fetchApiSos();
    fetchApiPoint();
    fetchApiBanner();
    setSelectedParker(null);
    connectSocket();
  }, [idEst, id, category, modalWarning, nameChat]);

  // marker of establishment

  const renderMarker = () => {
    if (info) {
      return info.map(item => (
        <Marker
          position={{ lat: +item.latitude, lng: +item.longitude }}
          icon={{
            url: renderIconsMaps(item.category),
            anchor:
              window.google && window.google.maps
                ? new google.maps.Point(20, 81)
                : undefined,
          }}
          onClick={() => {
            if (isDrawing) {
              setPath(prev => [
                ...prev,
                new google.maps.LatLng(+item.latitude, +item.longitude),
              ]);
              return;
            }

            if (
              selectedParker &&
              +item.latitude === +selectedParker.latitude &&
              +item.longitude === +selectedParker.longitude
            ) {
              setSelectedParker(null);
            } else {
              setSelectedParker(item);
            }

            setSelectedNotify(null);
            setSelectedBanner(null);
          }}
        />
      ));
    }
  };

  // marker of sos

  const handleVerifyMarkerSOS = (item: any) => {
    if (
      selectedNotify &&
      +item.sos.latitude === +selectedNotify.latitude &&
      +item.sos.longitude === +selectedNotify.longitude
    ) {
      setSelectedNotify(null);
    } else {
      setSelectedNotify({
        id: item.sos.id,
        latitude: item.sos.latitude,
        longitude: item.sos.longitude,
        describe: item.sos.describe,
        user_id: item.sos.user_id,
        status: null,
        user: null,
        created_at: item.sos.created_at,
        updated_at: item.sos.updated_at,
        is_read: item.sos.is_read,
        type: item.sos.type,
        title: item.sos.title,
        visible: item.sos.visible,
        type_id: item.sos.type_id,
        type_text: item.sos.type_text,
        boats: item.boats,
      });
    }
  };

  const renderMarkerSos = () => {
    if (sos) {
      return sos.map(item => (
        <Marker
          key={item.sos.id}
          position={{ lat: +item.sos.latitude, lng: +item.sos.longitude }}
          icon={{
            url: renderIconsMaps('SOS'),
          }}
          onClick={() => {
            handleVerifyMarkerSOS(item);
            setSelectedParker(null);
            setSelectedBanner(null);
            setOptionInfo('sos');
          }}
        />
      ));
    }
  };

  const handleVerifyMarkerPoint = (item: any) => {
    if (
      selectedPoint &&
      +item.latitude === +selectedPoint.latitude &&
      +item.longitude === +selectedPoint.longitude
    ) {
      setSelectedPoint(null);
    } else {
      setSelectedPoint(item);
    }
  };

  const renderMarkerPoint = () => {
    if (point) {
      return point.map(item => (
        <Marker
          key={item.id}
          position={{ lat: +item.latitude, lng: +item.longitude }}
          icon={{
            url: renderIconsMaps(item.type && item.type.title),
          }}
          onClick={() => {
            handleVerifyMarkerPoint(item);
            setSelectedParker(null);
            setSelectedBanner(null);
            setOptionInfo('warning');
          }}
        />
      ));
    }
  };

  const handleVerifyMarkerWarning = (item: any) => {
    if (
      selectedNotify &&
      +item.latitude === +selectedNotify.latitude &&
      +item.longitude === +selectedNotify.longitude
    ) {
      setSelectedNotify(null);
    } else {
      setSelectedNotify(item);
    }
  };

  const renderMarkerWarning = () => {
    if (warning) {
      return warning.map(item => (
        <Marker
          key={item.id}
          position={{ lat: +item.latitude, lng: +item.longitude }}
          icon={{
            url: renderIconsMaps(item.title ?? ''),
          }}
          onClick={() => {
            handleVerifyMarkerWarning(item);
            setSelectedParker(null);
            setSelectedBanner(null);
            setOptionInfo('warning');
          }}
        />
      ));
    }
  };

  const handleVerifyMarkerBanner = (item: IBannerList) => {
    if (
      selectedBanner &&
      +item.latitude === +selectedBanner.latitude &&
      +item.longitude === +selectedBanner.longitude
    ) {
      setSelectedBanner(null);
    } else {
      setSelectedBanner(item);
    }
  };

  const renderBanner = () => {
    if (category) return;

    if (bannerList) {
      return bannerList.map(item => (
        <Marker
          key={item.id}
          position={{
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          }}
          icon={{
            url: BannerPoint,
          }}
          onClick={() => {
            handleVerifyMarkerBanner(item);
            setSelectedNotify(null);
            setSelectedParker(null);
          }}
        />
      ));
    }
  };



  const renderPolyline = () => {
    return (
      <>
        <Marker
          position={path[0]}
          icon={{
            url: PinWhite,
            anchor:
              window.google && window.google.maps
                ? new google.maps.Point(20, 81)
                : undefined,
          }}
        />

        {path.map((item, index) => (
          <Marker
            key={+index}
            position={item}
            icon={{
              url: bubble,
              anchor:
                window.google && window.google.maps
                  ? new google.maps.Point(20, 81)
                  : undefined,
            }}
          />
        ))}

        <Polyline
          path={path}
          options={{
            strokeColor: '#0099ff',
            strokeOpacity: 1,
            strokeWeight: 8,
          }}
        />

        <Marker
          position={path[path.length - 1]}
          icon={{
            url: FlagIcon,
            anchor:
              window.google && window.google.maps
                ? new google.maps.Point(20, 81)
                : undefined,
          }}
        />
      </>
    );
  };

  const renderRoutesList = () => {
    return pathObj.map((item, index) => (
      <>
        <Marker
          position={item.position[0]}
          icon={{
            url: PinWhite,
          }}
        />

        {item.position.map((latlng, ind) => (
          <Marker
            key={+ind}
            position={latlng}
            icon={{
              url: bubble,
            }}
          />
        ))}

        <Polyline
          key={+index}
          path={item.position}
          options={{
            strokeColor: '#0099ff',
            strokeOpacity: 1,
            strokeWeight: 8,
          }}
        />

        <Marker
          position={item.position[item.position.length - 1]}
          icon={{
            url: FlagIcon,
          }}
        />
      </>
    ));
  };

  const renderAnchor = () => {
    if (category) return;

    return anchors.map(anchor => (
      <Marker
        key={anchor.id}
        position={{ lat: +anchor.latitude, lng: +anchor.longitude }}
        icon={{
          url: renderIconsMaps('Poita'),
        }}
        onClick={() => setSelectedAnchor(anchor)}
      />
    ));
  };

  const onMapLoad = (value: google.maps.Map) => {
    setMap(value);
  };

  return (
    <Section>
      <Navbar
        showModal={navbar}
        setShowAnchor={setShowAnchor}
        setRoutes={setModalRoutes}
        setBanners={setModalBanner}
        setModalAlertHistory={setModalAlertHistory}
        setEstablishment={setEstablishment}
        setEmergency={setModalEmergency}
        setAdmin={setModalAdmin}
        setUsers={setModalUsers}
        setInsurance={setModalInsurance}
        setWarning={setModalWarning}
        setCategory={setCategory}
        setOpenModal={setNavbar}
        setOption={setOption}
        setId={setId}
        setRadius={setRadius}
        setCodes={setCodes}
      />

      <Geocoding
        setLat={setLatitude}
        setLng={setLongitude}
        value={search}
        showModal={modalSearch}
      />

      <ChatMapIcon
        setIdChat={setIdChat}
        user={{
          type,
          id: idChat,
          name: nameChat,
          message: numberMessage + numberConcierge,
        }}
      />

      <User setUserOptions={setUserOptions} userOptions={userOptions} />

      <Alerts
        modalAlerts={modalAlerts}
        setNotification={setNotification}
        setNotification2={setNotification2}
        modalSos={modalSos}
        setModalAlerts={setModalAlerts}
        setModalSos={setModalSos}
        setIdChat={setIdChat}
        setName={setName}
        notification={notification}
        notification2={notification2}
        concierge={notificationConcierge}
        setType={setType}
      />

      <MapStatus setCategory={setCategory} category={category} />

      <LoadScript
        googleMapsApiKey="AIzaSyAOnF9j-cr0M5-3d-cxg2rABkse4jlBnLQ"
        libraries={['places']}
      >
        <GoogleMap
          onLoad={onMapLoad}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={center}
          zoom={12}
          onClick={e => {
            if (!isDrawing) return;

            if (e.latLng) {
              setPath([...path, e.latLng]);
            }
          }}
          options={{
            styles: MapsStyles,
            mapTypeControl: true,
            mapTypeControlOptions: {
              position:
                window.google && window.google.maps
                  ? google.maps.ControlPosition.BOTTOM_LEFT
                  : undefined,
              mapTypeIds: ['roadmap', 'satellite']
            },
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            zoomControlOptions: {
              position:
                window.google && window.google.maps
                  ? google.maps.ControlPosition.RIGHT_CENTER
                  : undefined,
            },
          }}
        >
          <StandaloneSearchBox onPlacesChanged={onPlacesChanged}>
            <BarWrappers
              setNavbar={setNavbar}
              navbar={navbar}
              setSearch={setSearch}
              search={search}
            />
          </StandaloneSearchBox>

          {renderMarker()}
          {renderMarkerSos()}
          {renderBanner()}
          {renderMarkerWarning()}
          {renderMarkerPoint()}
          {renderPolyline()}
          {renderRoutesList()}
          {renderAnchor()}

          {renderBoats({
            boatList,
            boatModalRef,
            setSelectedBoat,
            category,
          })}

          {selectedBanner && (
            <InfoWindow
              position={{
                lat: Number(selectedBanner.latitude),
                lng: Number(selectedBanner.longitude),
              }}
            >
              <BannerDetails
                info={selectedBanner}
                setInfo={setSelectedBanner}
                setIsOpen={setModalBanner}
                setId={setId}
              />
            </InfoWindow>
          )}

          {path.length > 0 && (
            <InfoWindow
              position={{
                lat: path[0].lat(),
                lng: path[0].lng(),
              }}
            >
              <ModalDrawRegister
                setVelocity={setVelocity}
                velocity={velocity}
                time={time}
                setTime={setTime}
                name_destiny={nameDestiny}
                setNameDestiny={setNameDestiny}
                name_origin={nameOrigin}
                setNameOrigin={setNameOrigin}
              />
            </InfoWindow>
          )}

          <BoatModal boat={selectedBoat} cat={category} ref={boatModalRef} />

          {selectedParker && (
            <InfoWindow
              position={{
                lat: +selectedParker.latitude,
                lng: +selectedParker.longitude,
              }}
            >
              <EstablishmentDetails
                info={selectedParker}
                setInfo={setSelectedParker}
                setEstablishment={setEstablishment}
                setId={setId}
              />
            </InfoWindow>
          )}

          {selectedNotify && (
            <InfoWindow
              position={{
                lat: +selectedNotify.latitude,
                lng: +selectedNotify.longitude,
              }}
            >
              <WindowAlert
                setModalAlertEdit={setModalAlertEdit}
                setModalSos={setModalSos}
                setIdAlert={setIdAlert}
                optionInfo={optionInfo}
                info={selectedNotify}
                setIdChat={setIdChat}
                setName={setName}
                setInfo={setSelectedNotify}
              />
            </InfoWindow>
          )}

          {selectedAnchor && (
            <InfoWindow
              position={{
                lat: +selectedAnchor.latitude,
                lng: +selectedAnchor.longitude,
              }}
            >
              <WindowAnchor
                info={selectedAnchor}
                setInfo={setSelectedAnchor}
                fetchAnchors={fetchApiAnchors}
                setIdAnchor={setIdAnchor}
              />
            </InfoWindow>
          )}

          {selectedPoint && (
            <InfoWindow
              position={{
                lat: +selectedPoint.latitude,
                lng: +selectedPoint.longitude,
              }}
            >
              <WindowPointer
                point={selectedPoint}
                optionInfo={optionInfo}
                setName={setName}
                setInfo={setSelectedPoint}
              />
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>

      <ModalEditAlert
        setShowModal={setModalAlertEdit}
        showModal={modalAlertEdit}
        id={idAlert}
      />

      <ModalEditAnchor
        id={idAnchor}
        onFinish={() => {
          fetchApiAnchors();
          setIdAnchor('');
        }}
        setIdAnchor={setIdAnchor}
      />

      {establishment && (
        <Modal>
          <ModalEstablishment
            setOpenModal={setEstablishment}
            id={id}
            setIdEst={setIdEst}
          />
        </Modal>
      )}

      {modalBanner && (
        <Modal>
          <ModalBanner
            setOpenModal={setModalBanner}
            id={id}
            setIdEst={setIdEst}
          />
        </Modal>
      )}

      {(user.permissions.some((item: number) => item === 6) ||
        user.role === 'Master') && (
          <ModalDraw
            setIsDrawing={setIsDrawing}
            isDrawing={isDrawing}
            setPath={setPath}
            paths={path}
            time={time}
            velocity={velocity}
            setVelocity={setVelocity}
            setTime={setTime}
            nameDestiny={nameDestiny}
            setNameDestiny={setNameDestiny}
            nameOrigin={nameOrigin}
            setNameOrigin={setNameOrigin}
          />
        )}

      {modalInsurance && (
        <Modal>
          <ModalInsurance setOpenModal={setModalInsurance} />
        </Modal>
      )}

      {modalEmergency && (
        <Modal>
          <ModalEmergency setOpenModal={setModalEmergency} />
        </Modal>
      )}

      {modalRoutes && (
        <ModalRoutes
          setOpenModal={setModalRoutes}
          setPathObj={setPathObj}
          pathObj={pathObj}
        />
      )}

      {modalWarning && (
        <Modal>
          <ModalWarning setOpenModal={setModalWarning} />
        </Modal>
      )}

      {showAnchor && (
        <Modal>
          <ModalAnchor setOpenModal={setShowAnchor} />
        </Modal>
      )}

      {radius && <ModalRadius onClose={() => setRadius(false)} />}
      {codes && <ModalCodes setOpenModal={setCodes} />}
      {modalAdmin && <ModalAdmin setOpenModal={setModalAdmin} />}
      {modalUsers && <ModalUsers setOpenModal={setModalUsers} />}

      {modalAlertHistory && (
        <Modal>
          <ModalAlertHistory
            setOpenModal={setModalAlertHistory}
            option={option}
          />
        </Modal>
      )}
    </Section>
  );
};
