import { Dispatch, SetStateAction } from 'react';

import {
  BarWrapper,
  ContainerIcon,
  Input,
  MenuButton,
  SearchButton,
} from './_barWrappers';

import Menu from '../../../../assets/icons/menu.svg';
import Search from '../../../../assets/icons/search.svg';

interface Props {
  setNavbar: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
  navbar: boolean;
  search: string;
}

const BarWrappers = ({ setNavbar, navbar, setSearch, search }: Props) => {
  return (
    <BarWrapper>
      <MenuButton
        src={Menu}
        alt="Icon Menu"
        onClick={() => setNavbar(!navbar)}
      />
      <Input
        className="addressField"
        placeholder="Pesquise no Booat"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <ContainerIcon>
        <SearchButton src={Search} alt="icon search" />
      </ContainerIcon>
    </BarWrapper>
  );
};

export default BarWrappers;
