export const permissionLabels: Record<string, string> = {
  createEstablishment: 'Cadastrar Estabelecimento',
  createWarning: 'Cadastrar Alertas',
  createEmergency: 'Cadastrar Emergência',
  createInsurance: 'Cadastrar Seguradora',
  viewHistory: 'Histórico de Solicitações',
  createAdmin: 'Cadastrar Usuários Admin',
  createRoute: 'Cadastro de Rotas',
  createBanner: 'Cadastro de Banner',
  viewUsers: 'Listagem de Usuário',
};
