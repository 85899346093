/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import CloseIcon from '../../assets/icons/close.svg';
import EditIcon from '../../assets/icons/adminEdit.svg';
import DeleteIcon from '../../assets/icons/adminDelete.svg';
import AddIcon from '../../assets/icons/adminAdd.svg';

import {
  AddButton,
  ButtonsContainer,
  Close,
  CloseButton,
  Container,
  Content,
  Icon,
  IconButton,
  ModalFooter,
  ModalHeader,
  PaginationWrapper,
  Table,
  TableWrapper,
  Title,
} from './_modalAdmin';
import { ModalPermissions } from '../ModalPermissions/ModalPermissions';
import { ModalAddAdmin } from '../ModalAddAdmin/ModalAddAdmin';
import { ModalConfirm } from '../ModalConfirm/ModalConfirm';
import { Paginated } from '../../interfaces/Paginated';
import { IUser } from '../../interfaces/User';
import api from '../../services/api';
import handleError from '../../utils/handleError';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

type UserPages = Record<number, Paginated<IUser>>;

const limit = 6;

export const ModalAdmin: React.FC<Props> = ({ setOpenModal }) => {
  const [modalAddAdmin, setModalAddAdmin] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [userPages, setUserPages] = useState<UserPages>();
  const [userIdToDelete, setUserIdToDelete] = useState('');
  const [selectedAdmin, setSelectedAdmin] = useState<IUser>();
  const [page, setPage] = useState(1);
  const [maximumPage, setMaximumPage] = useState(1);

  const getUsers = async () => {
    try {
      const response = await api.get<Paginated<IUser>>('/users/list', {
        params: {
          role: 'Administrator',
          page,
          limit,
        },
      });

      setUserPages(prev => ({
        ...prev,
        [page]: response.data,
      }));
      setMaximumPage(Math.ceil(response.data.total / limit));
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  const handleDeleteAdmin = (id: string) => {
    setModalConfirm(true);
    setUserIdToDelete(id);
  };

  const confirmDeleteAdmin = async () => {
    setModalConfirm(false);
    setUserIdToDelete('');
    try {
      await api.delete(`/users/${userIdToDelete}`);

      getUsers();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const currentPageUsers = userPages?.[page]?.results || [];

  const pageOffset = (page - 1) * limit + 1;

  return (
    <Container>
      <Content>
        <ModalHeader>
          <Title>Admins</Title>
          <CloseButton onClick={() => setOpenModal(false)}>
            <Close src={CloseIcon} />
          </CloseButton>
        </ModalHeader>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Nº</th>
                <th>Nome</th>
                <th>E-mail</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {currentPageUsers.map((user, index) => (
                <tr key={user.id}>
                  <td>{pageOffset + index}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <ButtonsContainer>
                      <IconButton
                        type="button"
                        onClick={() => setSelectedAdmin(user)}
                      >
                        <Icon src={EditIcon} />
                      </IconButton>
                      <IconButton
                        type="button"
                        onClick={() => handleDeleteAdmin(user.id)}
                      >
                        <Icon src={DeleteIcon} />
                      </IconButton>
                    </ButtonsContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        <ModalFooter>
          <AddButton type="button" onClick={() => setModalAddAdmin(true)}>
            Criar novo admin
            <Icon src={AddIcon} style={{ width: '1rem', height: '1rem' }} />
          </AddButton>
          <PaginationWrapper>
            <Pagination
              page={page}
              count={maximumPage}
              siblingCount={1}
              onChange={(e, _page) => setPage(_page)}
              hidePrevButton={page === 1}
              hideNextButton={page === maximumPage}
              renderItem={item => {
                return (
                  <PaginationItem
                    {...item}
                    style={{
                      color: item.page === page ? '#4793CE' : '#e5e5e5',
                      background: 'transparent',
                      fontSize: '1rem',
                      fontWeight: 500,
                      margin: 0,
                      padding: '0.125rem',
                      minWidth: '1rem',
                    }}
                    classes={{
                      icon: 'icon',
                    }}
                  />
                );
              }}
            />
          </PaginationWrapper>
        </ModalFooter>
      </Content>
      {selectedAdmin && (
        <ModalPermissions
          user={selectedAdmin}
          onClose={() => setSelectedAdmin(undefined)}
          getList={() => getUsers()}
        />
      )}
      {modalAddAdmin && (
        <ModalAddAdmin
          onClose={() => {
            setModalAddAdmin(false);
            getUsers();
          }}
        />
      )}
      {modalConfirm && (
        <ModalConfirm
          title="Tem certeza que deseja apagar esse administrador?"
          onCancel={() => setModalConfirm(false)}
          onConfirm={() => confirmDeleteAdmin()}
        />
      )}
    </Container>
  );
};
