import styled from 'styled-components';

interface Props {
  cor?: boolean;
}

export const ModalMarker = styled.div`
  display: flex;
  flex-direction: column;
  width: 257px;
  height: 295px;
  padding-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
`;

export const Title = styled.text`
  font-size: 14pt;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const SectionIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-left: 10px;
`;

export const Image = styled.img`
  cursor: pointer;
  border-radius: 8px;
`;

export const Status = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10pt;
  font-weight: 400;

  div {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${props => (props.cor ? '#079223' : '#CA3030')};
  }
`;

export const SsectionImage = styled.div`
  display: flex;
  width: 100%;
  height: 88px;
  gap: 2px;
  overflow-x: scroll;
  overflow-y: hidden;
  border-radius: 8px;

  ::-webkit-scrollbar {
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--white);
  }

  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }
`;

export const SecondImages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledText = styled.div`
  max-width: 88%;
  font-size: 10pt;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const Edit = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 52px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);

  &:hover {
    opacity: 0.8;
  }
`;

export const Trash = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);

  &:hover {
    opacity: 0.8;
  }
`;

export const SectionClose = styled.button`
  height: 30px;
  width: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  border: 0;

  &:hover {
    opacity: 0.8;
  }
`;
