import { Dispatch, SetStateAction, useState } from 'react';
import { maskPhone, unformatPhone } from '../../../../utils/mask';
import { IContactForm } from '../../interface';
import {
  Button,
  ButtonsContainer,
  Container,
  Content,
  Field,
  Form,
  Input,
  Label,
  Title,
} from './_modalOther';

export interface IModalOtherFormData {
  email: string;
  secondary_email: string;
  telephone: string;
  secondary_telephone: string;
}

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: IModalOtherFormData) => void;
  initialData?: IContactForm;
}

export const ModalOther: React.FC<Props> = ({
  initialData,
  onSubmit,
  setOpenModal,
}) => {
  const [email, setEmail] = useState(initialData?.email || '');
  const [secondaryEmail, setSecondaryEmail] = useState(
    initialData?.secondary_email || '',
  );
  const [telephone, setTelephone] = useState(
    maskPhone(initialData?.telephone || ''),
  );
  const [secondaryTelephone, setSecondaryTelephone] = useState(
    maskPhone(initialData?.secondary_telephone || ''),
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({
      email,
      secondary_email: secondaryEmail,
      telephone: unformatPhone(telephone),
      secondary_telephone: unformatPhone(secondaryTelephone),
    });
  };

  return (
    <Container>
      <Content>
        <Title>Outros assuntos</Title>
        <Form onSubmit={handleSubmit}>
          <Field>
            <Label>E-mail Marina 1</Label>
            <Input
              type="email"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Field>
          <Field>
            <Label>E-mail Marina 2</Label>
            <Input
              type="email"
              value={secondaryEmail}
              onChange={e => setSecondaryEmail(e.target.value)}
            />
          </Field>
          <Field>
            <Label>Telefone Marina 1 </Label>
            <Input
              required
              value={telephone}
              onChange={e => setTelephone(maskPhone(e.target.value))}
              maxLength={15}
            />
          </Field>
          <Field>
            <Label>Telefone Marina 2</Label>
            <Input
              value={secondaryTelephone}
              onChange={e => setSecondaryTelephone(maskPhone(e.target.value))}
              maxLength={15}
            />
          </Field>
          <Field style={{ height: '4.375rem' }} />
          <ButtonsContainer>
            <Button type="button" onClick={() => setOpenModal(false)}>
              Cancelar
            </Button>
            <Button option>Salvar</Button>
          </ButtonsContainer>
        </Form>
      </Content>
    </Container>
  );
};
