import styled, { keyframes } from 'styled-components';
import ImagemBackground from '../../assets/img/BG-blue.png';
import pixel from '../../utils/size';

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Main = styled.div`
  background-image: url(${ImagemBackground});
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  height: 100vh;
  width: 100%;
`;

export const SectionForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${pixel(530)};

  @media only screen and (max-width: 600px) {
    height: auto;
    margin-top: 20px;
    padding: 10px;
  }
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;

  width: ${pixel(900)};
  height: ${pixel(460)};
  margin-top: ${pixel(2)};
  border-radius: ${pixel(12)};
  background: #0000002e;
  padding: ${pixel(32)};

  animation-duration: 1s;
  animation-name: ${fade};

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #1694d3;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #0006b8;
    border-radius: 10px;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 360px;
    margin-top: 5%;
    padding: 10px;
    border-radius: 14px;
  }
`;

export const Title = styled.h1`
  font-size: ${pixel(30)};
  font-weight: bold;
  color: white;
  margin-bottom: ${pixel(20)};
`;

export const SubTitle = styled.h2`
  font-size: ${pixel(20)};
  font-weight: bold;
  color: white;
  margin-bottom: ${pixel(20)};
`;

export const Text = styled.p`
  font-size: ${pixel(16)};
  color: white;
  margin-bottom: ${pixel(20)};
`;
