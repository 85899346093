import { useEffect, useState } from 'react';
import api from '../../services/api';
import handleError from '../../utils/handleError';
import { permissionLabels } from '../../utils/permissions';
import { Loading } from '../Loading/Loading';
import {
  Button,
  ButtonsContainer,
  ButtonText,
  Container,
  Content,
  Form,
  FormRow,
  Input,
  LoadingWrapper,
  PermissionItem,
  PermissionList,
  PermissionName,
  Title,
  Toggle,
} from './_modalAddAdmin';

interface IPermission {
  id: number;
  name: string;
}

interface Props {
  onClose: () => void;
}

export const ModalAddAdmin: React.FC<Props> = ({ onClose }) => {
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getPermissions = async () => {
    setIsLoading(true);
    try {
      const response = await api.get<IPermission[]>('/permission');

      setPermissions(response.data);
    } catch (error: any) {
      handleError(error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSubmitting(true);

    const payload = {
      name,
      email,
      password,
      permissions: selectedPermissions,
      role: 'Administrator',
    };

    try {
      await api.post('/users', payload);

      handleError('Cadastrado com sucesso.', 'success');
      onClose();
    } catch (error: any) {
      handleError(error, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTogglePermission = (id: number) => {
    const alreadySelected = selectedPermissions.findIndex(
      permission => permission === id,
    );

    if (alreadySelected >= 0) {
      const filteredPermissions = selectedPermissions.filter(
        permission => permission !== id,
      );
      setSelectedPermissions(filteredPermissions);
    } else {
      setSelectedPermissions([...selectedPermissions, id]);
    }
  };

  return (
    <Container>
      <Content>
        <Title>Cadastro de Admin</Title>
        <Form onSubmit={handleSubmit} id="add-admin-form">
          <Input
            placeholder="Nome do admin"
            required
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Input
            placeholder="E-mail"
            type="email"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FormRow>
            <Input
              placeholder="Senha"
              required
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Input
              placeholder="Confirmar senha"
              required
              type="password"
              pattern={password}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              title="As senhas não conferem"
            />
          </FormRow>
        </Form>
        {isLoading ? (
          <LoadingWrapper>
            <Loading style={{ fontSize: '2rem' }} />
          </LoadingWrapper>
        ) : (
          <PermissionList>
            {permissions.map(permission => (
              <PermissionItem key={permission.id}>
                <PermissionName>
                  {permissionLabels[permission.name] || permission.name}
                </PermissionName>
                <Toggle
                  type="checkbox"
                  checked={selectedPermissions.includes(permission.id)}
                  onChange={() => handleTogglePermission(permission.id)}
                />
              </PermissionItem>
            ))}
          </PermissionList>
        )}

        <ButtonsContainer>
          <Button type="button" onClick={onClose}>
            <ButtonText>Cancelar</ButtonText>
          </Button>
          <Button
            option
            type="submit"
            form="add-admin-form"
            disabled={isSubmitting}
          >
            <ButtonText>
              {isSubmitting ? (
                <Loading
                  style={{
                    marginTop: '0.25rem',
                  }}
                />
              ) : (
                'Salvar'
              )}
            </ButtonText>
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
