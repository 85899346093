import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Container, ImageInput, ImageLabel, Image, ImageRow } from './styles';

interface Props {
  setFirstBanner: Dispatch<SetStateAction<string>>;
  setDefaultBanner: Dispatch<SetStateAction<string | undefined>>;
  first_banner: string;
  default_banner: string | undefined;
}

export const NewUploadImages: React.FC<Props> = ({
  setFirstBanner,
  first_banner,
  default_banner,
  setDefaultBanner,
}) => {
  const [banner, setBanner] = React.useState<File>();

  const onAddFirstImage = async (event: any) => {
    if (!event.target.files) return;

    const file = event.target.files[0];

    setBanner(file);
    setFirstBanner(file);
    setDefaultBanner(undefined);
  };

  return (
    <Container>
      <ImageRow>
        <ImageLabel>
          <ImageInput
            type="file"
            accept="image/*"
            onChange={e => onAddFirstImage(e)}
          />
          {default_banner ? (
            <Image
              src={default_banner}
              style={{
                aspectRatio: '400 / 160',
              }}
            />
          ) : (
            banner &&
            first_banner !== '' && (
              <Image
                src={
                  default_banner ||
                  (banner ? URL.createObjectURL(banner) : first_banner)
                }
                style={{
                  aspectRatio: '400 / 160',
                }}
              />
            )
          )}
        </ImageLabel>
      </ImageRow>
    </Container>
  );
};
