/* eslint-disable consistent-return */
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import {
  BusinessContent,
  ButtonMarina,
  CloseMarina,
  ContainerRow,
  ImageBusiness,
  ImageClosed,
  Input,
  ModalMarina,
  Option,
  OptionBusiness,
  SectionOption,
  Select,
  TextHours,
} from './_businessHours';

import Close from '../../../../assets/icons/closeMarina.png';
import Lapis from '../../../../assets/icons/lapis.png';
import api from '../../../../services/api';

interface PropsTime {
  idHours: number;
  weekDay: string;
  startTime: string;
  endTime: string;
}

interface Props {
  isVisibleHours: boolean;
  setIsVisibleHours: Dispatch<SetStateAction<boolean>>;
  weekDay: string;
  setWeekDay: Dispatch<SetStateAction<string>>;
  startTime: string;
  setStartTime: Dispatch<SetStateAction<string>>;
  endTime: string;
  setEndTime: Dispatch<SetStateAction<string>>;
  business: PropsTime[];
  setBusiness: Dispatch<SetStateAction<PropsTime[]>>;
}

export const BusinessHours = ({
  isVisibleHours,
  setIsVisibleHours,
  weekDay,
  setWeekDay,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  business,
  setBusiness,
}: Props) => {
  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const handleErro = (message: string) => {
    return notifyError(`${message} é obrigatório!`);
  };

  const handleSaveBusiness = () => {
    if (weekDay === '') return handleErro('Dia da Semana');
    if (startTime === '') return handleErro('Hora Inicial');
    if (+startTime.replace(':', '') >= +endTime.replace(':', ''))
      return notifyError('Hora Inicial não pode ser maior ou igual hora final');
    if (endTime === '') return handleErro('Hora Final');
    if (endTime.length < 4 || startTime.length < 4)
      return notifyError('A hora deve conter 4 digitos');
    const verification = startTime.split(':');
    if (+verification[0] >= 24)
      return notifyError('A hora não pode passar de 24');
    if (+verification[1] >= 60)
      return notifyError('Os minutos não pode passar de 60');
    const verification2 = endTime.split(':');
    if (+verification2[0] >= 24)
      return notifyError('A hora não pode passar de 24');
    if (+verification2[1] >= 60)
      return notifyError('Os minutos não pode passar de 60');

    if (business) {
      const idHours = business.length + 1;
      const listBusiness = [
        ...business,
        { idHours, weekDay, startTime, endTime },
      ];
      setBusiness(listBusiness);
    } else {
      const listBusiness = [{ idHours: 1, weekDay, startTime, endTime }];
      setBusiness(listBusiness);
    }

    setIsVisibleHours(false);
  };

  const removeBusiness = async (idVal: number) => {
    if (business) {
      const value = business.filter(item => item.idHours !== idVal);
      setBusiness(value);

      await api.delete(`/establishment/days/${idVal}`);
    }
  };

  const renderShowBusiness = () => {
    if (business) {
      return business.map((item, index) => (
        <BusinessContent key={+index}>
          <div>
            <ImageBusiness
              src={Close}
              alt="Delete Business"
              onClick={() => removeBusiness(item.idHours)}
            />
          </div>
          {item.weekDay}
          <TextHours>
            {item.startTime.slice(0, 5)}-{item.endTime.slice(0, 5)}
          </TextHours>
        </BusinessContent>
      ));
    }
  };

  const maskHours = (value: string) => {
    return value.replace(/\D/g, '').replace(/^(\d{2})(\d{2}).*/, '$1:$2');
  };

  const handleBusinessHours = () => {
    if (isVisibleHours) {
      return (
        <>
          <ModalMarina option>
            <CloseMarina>
              <ImageClosed
                src={Close}
                alt="Icon Close Marina"
                onClick={() => setIsVisibleHours(false)}
              />
            </CloseMarina>
            <ContainerRow style={{ padding: '0px 15px' }}>
              <Select
                onChange={e => setWeekDay(e.target.value)}
                value={weekDay}
              >
                <Option value="" disabled selected>
                  Dias da Semana
                </Option>
                <Option value="Segunda a Sexta">Segunda a Sexta</Option>
                <Option value="Sabado e Domingo">Sábado e Domingo</Option>
                <Option value="Segunda">Segunda</Option>
                <Option value="Terça">Terça</Option>
                <Option value="Quarta">Quarta</Option>
                <Option value="Quinta">Quinta</Option>
                <Option value="Sexta">Sexta</Option>
                <Option value="Sabado">Sábado</Option>
                <Option value="Domingo">Domingo</Option>
              </Select>
              <Input
                placeholder="Início (00:00)"
                onChange={e => setStartTime(maskHours(e.target.value))}
                value={startTime}
                style={{ marginBottom: '5px' }}
              />
              <Input
                placeholder="Fim (00:00)"
                onChange={e => setEndTime(maskHours(e.target.value))}
                value={endTime}
                style={{ marginBottom: '5px' }}
              />
            </ContainerRow>
          </ModalMarina>
          <ButtonMarina onClick={() => handleSaveBusiness()}>
            <img src={Lapis} alt="Icon Lapis" width={14} />
            Salvar
          </ButtonMarina>
        </>
      );
    }
    return (
      <>
        <p>Horário de funcionamento</p>
        <ButtonMarina onClick={() => setIsVisibleHours(true)}>
          Adicionar
        </ButtonMarina>
        <OptionBusiness>{renderShowBusiness()}</OptionBusiness>
      </>
    );
  };

  return <SectionOption>{handleBusinessHours()}</SectionOption>;
};
