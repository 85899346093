import styled from 'styled-components';
import pixel from '../../utils/size';

export const SectionFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${pixel(84)};
  position: absolute;
  bottom: ${pixel(15)};

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

export const Copy = styled.h6`
  font-weight: bold;
  width: ${pixel(500)};
  font-size: ${pixel(12)};

  @media only screen and (max-width: 600px) {
    width: 500px;
    font-size: 12px;
    text-align: center;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: ${pixel(15)};
  align-items: center;

  @media only screen and (max-width: 600px) {
    gap: 15px;
  }
`;

export const Circulo = styled.div`
  border-radius: 50%;
  width: ${pixel(20)};
  height: ${pixel(20)};
  background: black;

  @media only screen and (max-width: 600px) {
    width: 25px;
    height: 25px;
  }
`;

export const Instagram = styled.img`
  width: ${pixel(20)};
  height: ${pixel(20)};
  color: var(--blue003);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: 600px) {
    width: 25px;
    height: 25px;
  }
`;

export const Link = styled.a`
  color: var(--blue003);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
