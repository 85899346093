import { useState } from 'react';
import { parseISO } from 'date-fns';
import CloseIcon from '../../../assets/icons/close.svg';
import api from '../../../services/api';
import handleError from '../../../utils/handleError';
import {
  Button,
  Close,
  CloseButton,
  Column,
  Container,
  Content,
  Detail,
  DetailLabel,
  DetailWrapper,
  Input,
  ModalHeader,
  Title,
} from './styles';

interface Form {
  name: string;
  code: string;
  start_date: string;
  end_date: string;
  quantity: number;
}

interface Props {
  onClose: () => void;
}

export const ModalRegisterCode: React.FC<Props> = ({ onClose }) => {
  const [state, setState] = useState<Form>({} as Form);

  const onSubmit = async () => {
    try {
      await api.post('/gift-code', {
        ...state,
        start_date: parseISO(state.start_date),
        end_date: parseISO(state.end_date),
      });

      await api.post('/gift-code/process');

      handleError('Codígo cadastrado com sucesso!', 'success');
      onClose();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  return (
    <Container>
      <Content>
        <ModalHeader>
          <Title>Adicionar Gift Card</Title>
          <CloseButton onClick={onClose}>
            <Close src={CloseIcon} />
          </CloseButton>
        </ModalHeader>
        <Column>
          <DetailWrapper>
            <DetailLabel>Nome</DetailLabel>
            <Detail>
              <Input
                type="text"
                placeholder="Ex.: Promocional"
                value={state.name}
                onChange={e => {
                  const obj = {
                    ...state,
                    name: e.target.value,
                  };
                  setState(obj);
                }}
              />
            </Detail>
          </DetailWrapper>
          <DetailWrapper>
            <DetailLabel>Código</DetailLabel>
            <Detail>
              <Input
                type="text"
                placeholder="Ex.: ABC123"
                value={state.code}
                onChange={e => {
                  const obj = {
                    ...state,
                    code: e.target.value,
                  };
                  setState(obj);
                }}
              />
            </Detail>
          </DetailWrapper>
          <DetailWrapper>
            <DetailLabel>Data de inicío</DetailLabel>
            <Detail>
              <Input
                type="date"
                value={state.start_date}
                onChange={e => {
                  const obj = {
                    ...state,
                    start_date: e.target.value,
                  };
                  setState(obj);
                }}
              />
            </Detail>
          </DetailWrapper>
          <DetailWrapper>
            <DetailLabel>Data final</DetailLabel>
            <Detail>
              <Input
                type="date"
                value={state.end_date}
                onChange={e => {
                  const obj = {
                    ...state,
                    end_date: e.target.value,
                  };
                  setState(obj);
                }}
              />
            </Detail>
          </DetailWrapper>
          <DetailWrapper>
            <DetailLabel>Quantidade</DetailLabel>
            <Detail>
              <Input
                type="number"
                placeholder="Ex.: 10"
                value={state.quantity}
                onChange={e => {
                  const obj = {
                    ...state,
                    quantity: Number(e.target.value),
                  };
                  setState(obj);
                }}
              />
            </Detail>
          </DetailWrapper>

          <Button type="button" onClick={onSubmit}>
            Cadastrar
          </Button>
        </Column>
      </Content>
    </Container>
  );
};
