/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Footer } from '../../shared/Footer/Footer';
import { Header } from '../../shared/Header/Header';

import api from '../../services/api';
import pixel from '../../utils/size';
import handleError from '../../utils/handleError';

import {
  FormLogin,
  SectionForm,
  SubTitle,
  Title,
  Input,
  Button,
  Forgot,
  Main,
} from './_login';
import { useAuth } from '../../hooks/useAuth';

export const Login = () => {
  const { setUser } = useAuth();

  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [forget, setForget] = useState<boolean>(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const postApiLogin = async () => {
    try {
      const { data } = await api.post('/sessions', {
        email,
        password,
        role: 'admin',
      });

      setUser(data.user);

      localStorage.setItem('@Booat: user', JSON.stringify(data.user));
      localStorage.setItem('@Booat: accessToken', data.access_token);
      localStorage.setItem('@Booat: refreshToken', data.refresh_token);

      history.push('/maps');
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const postApiForgot = async () => {
    try {
      await api.post('/password/forgot', {
        email,
      });

      handleError('E-mail enviado!', 'success');
    } catch (error: any) {
      handleError(error, 'error');

      setPassword('');
      setEmail('');
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    postApiLogin();
  };

  const handleSubmitForgot = (event: React.FormEvent) => {
    event.preventDefault();
    postApiForgot();
  };

  const renderForgetPassword = () => {
    return (
      <FormLogin onSubmit={handleSubmitForgot}>
        <Title>Esqueceu a senha?</Title>
        <SubTitle>
          Informe o seu email para receber um link para trocar a senha.
        </SubTitle>
        <Input
          value={email}
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
        />

        <Forgot onClick={() => setForget(!forget)}>Lembrou sua senha?</Forgot>

        <Button type="submit">Enviar</Button>
      </FormLogin>
    );
  };

  return (
    <Main>
      <Header />

      <SectionForm>
        {!forget ? (
          <FormLogin onSubmit={handleSubmit}>
            <Title>Acesso Usuário Administrador</Title>
            <SubTitle>
              ÁREA DE LOGIN EXCLUSIVA DO USUARIO ADMINISTRADOR. O Booat não pode
              ser acessado via web. Por favor. baixe e acesse o aplicativo do
              Booat no seu smartphone e aproveite!
            </SubTitle>
            <Input
              value={email}
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
              type="email"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: pixel(22),
              }}
            >
              <Input
                value={password}
                placeholder="Senha"
                onChange={e => setPassword(e.target.value)}
                type="password"
              />
              <Forgot onClick={() => setForget(!forget)}>
                Esqueceu a senha?
              </Forgot>
            </div>

            <Button type="submit">Entrar</Button>
          </FormLogin>
        ) : (
          renderForgetPassword()
        )}
      </SectionForm>

      <Footer />
    </Main>
  );
};
