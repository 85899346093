/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction } from 'react';
import { IoClose, IoLocationSharp } from 'react-icons/io5';
import {
  Adress,
  BusinessHours,
  Container,
  ModalMarker,
  SectionClose,
  Title,
  Trash,
} from './_windowAlert';
import lixo from '../../assets/icons/trash.png';
import handleError from '../../utils/handleError';
import api from '../../services/api';
import { ResponsePoint } from '../../pages/MapsPage/interface';

interface Props {
  point: ResponsePoint;
  setInfo: Dispatch<SetStateAction<ResponsePoint | null | undefined>>;
  optionInfo: string;
  setName: Dispatch<SetStateAction<string>>;
}

export const WindowPointer = ({
  point,
  setInfo,
  optionInfo,
  setName,
}: Props) => {
  const renderWarning = () => {
    const DeleteApiEstablishment = async (value: string) => {
      try {
        await api.delete(`/points/${value}`);

        handleError('Alerta deletado com sucesso!', 'success');
        setName('teste');
      } catch (error) {
        handleError(error, 'error');
      }
    };

    return (
      <ModalMarker>
        <Container>
          <Title>{point.type.title}</Title>

          <SectionClose type="button" onClick={() => setInfo(null)}>
            <IoClose color="#0006b8" size={20} />
          </SectionClose>
        </Container>
        <Container>
          <Adress>
            <IoLocationSharp color="#0006b8" width={25} />
            <p>
              {point.latitude.slice(0, 10)}/{point.longitude.slice(0, 10)}
            </p>
          </Adress>
        </Container>
        <Container>
          <BusinessHours>
            <p>{point.type.describe.slice(0, 30)}</p>
          </BusinessHours>
        </Container>
        <Trash
          src={lixo}
          alt="Icon Chat"
          width={35}
          onClick={() => {
            DeleteApiEstablishment(point.id);
          }}
        />
      </ModalMarker>
    );
  };

  return (
    <ModalMarker>{optionInfo === 'sos' ? null : renderWarning()}</ModalMarker>
  );
};
