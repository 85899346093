import styled, { keyframes } from 'styled-components';

interface Props {
  active?: boolean;
}

const progress = keyframes`
  from{
    background-color: var(--gray001);
    width: 0%;
  }
  to {
    background-color: var(--blue003);
    width: 100%;
  }
`;

const size = keyframes`
  from{
    border-color: white;
  }
  to {
    border-color: #91D1F1;
  }
`;

export const Progress = styled.div`
  color: blue;
  width: 70%;
`;

export const List = styled.ul``;

export const ListItem = styled.li<Props>`
  list-style-type: none;
  float: left;
  width: 33.33%;
  position: relative;
  text-align: center;
  font-size: 8pt;
  color: black;
  font-weight: ${props => (props.active ? 'bold' : '100')};

  &:before {
    content: '';
    width: 14px;
    height: 14px;
    border: 5px solid;
    border-color: ${props => (props.active ? '#91D1F1' : 'white')};
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: ${props =>
      props.active ? 'var(--blue003)' : 'var(--gray001)'};

    animation-name: ${props => (props.active ? size : '')};
    animation-duration: 1s;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    top: 10px;
    left: -50%;
    background-color: ${props =>
      props.active ? 'var(--blue003)' : 'var(--gray001)'};
    border-radius: 50px;

    animation-name: ${props => (props.active ? progress : '')};
    animation-duration: 2s;
  }

  &:first-child::after {
    content: none;
  }
`;
