export const maskPhone = (value: string) => {
  if (!value) return '';

  const onlyDigits = value.startsWith('55')
    ? value.replace(/\D/g, '').substring(2)
    : value.replace(/\D/g, '');

  if (onlyDigits.length <= 10) {
    return onlyDigits
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }

  return onlyDigits
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2');
};

export const unformatPhone = (value: string) => {
  return value
    .replace(' ', '')
    .replace('(', '')
    .replace(')', '')
    .replace('-', '');
};
