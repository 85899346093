import styled from 'styled-components';

interface Props {
  option?: boolean;
}

export const ContainerModal = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  width: 100%;
  height: 800%;
  overflow: overlay;
  margin-top: 10px;
  padding: 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue003);
  }

  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
`;

export const Title = styled.h2`
  font-weight: bold;
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 10px;

  input[type='file'] {
    display: none;
  }
`;

export const Label = styled.label`
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12pt;
  border: 1px solid var(--blue003);
  border-radius: 6px;
  gap: 8px;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
    background-color: var(--white);
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid white;
  border-radius: 6px;
  font-size: 12pt;
  padding: 0 16px;
  color: black;
  font-family: 'Nunito';

  option {
    height: 50px;
  }
`;

export const Option = styled.option`
  padding: 22px;
  font-size: 12pt;
  border-radius: 6px;
  color: #1a1a1a;
  font-family: 'Nunito';
`;

export const AreaText = styled.textarea`
  background-color: #ffffff;
  height: 100px;
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 12px 22px;
  border-radius: 6px;
  font-size: 13pt;
  font-family: 'Nunito';
  color: #1a1a1a;

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: 50px;
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 0 22px;
  border-radius: 6px;
  font-size: 12pt;
  font-family: 'Nunito';

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 15px;
  gap: 16px;
  height: 100%;
  align-items: flex-end;
`;

export const Button = styled.button<Props>`
  width: 150px;
  border: 2px solid var(--blue003);
  background-color: ${props =>
    props.option ? 'var(--blue003)' : 'var(--white)'};
  font-size: 12pt;
  color: ${props => (props.option ? 'var(--white)' : 'var(--blue003)')};
  border-radius: 10px;

  height: 55px;

  &:hover {
    opacity: 0.8;
  }
`;
