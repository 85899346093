/* eslint-disable prettier/prettier */
import { Dispatch, SetStateAction, useState } from 'react';

import {
  ContainerCadaster,
  SectionCadaster,
  ContainerAlert,
  ContainerLogo,
  SectionButton,
  MainContainer,
  ModalNavbar,
  SectionIcon,
  TextOption,
  Divisor,
  Close,
  Logo,
  Icon,
} from './_navbar';

import Restaurante from '../../assets/icons/restaurant.svg';
import Logotipo from '../../assets/img/logotipo.svg';
import CloseIcon from '../../assets/icons/close.svg';
import Combustivel from '../../assets/icons/gas.svg';
import Alerta from '../../assets/icons/alert.svg';
import Barco from '../../assets/icons/boat.svg';
import Boia from '../../assets/icons/sos.svg';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  setModalAlertHistory: Dispatch<SetStateAction<boolean>>;
  setEstablishment: Dispatch<SetStateAction<boolean>>;
  setBanners: Dispatch<SetStateAction<boolean>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setInsurance: Dispatch<SetStateAction<boolean>>;
  setEmergency: Dispatch<SetStateAction<boolean>>;
  setRoutes: Dispatch<SetStateAction<boolean>>;
  setAdmin: Dispatch<SetStateAction<boolean>>;
  setUsers: Dispatch<SetStateAction<boolean>>;
  setCategory: Dispatch<SetStateAction<string>>;
  setWarning: Dispatch<SetStateAction<boolean>>;
  setOption: Dispatch<SetStateAction<string>>;
  setId: Dispatch<SetStateAction<string>>;
  setShowAnchor: Dispatch<SetStateAction<boolean>>;
  setRadius: Dispatch<SetStateAction<boolean>>;
  setCodes: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
}

export const Navbar = ({
  setModalAlertHistory,
  setEstablishment,
  setEmergency,
  setBanners,
  setAdmin,
  setUsers,
  setInsurance,
  setOpenModal,
  setShowAnchor,
  setCategory,
  setWarning,
  setOption,
  setRoutes,
  setRadius,
  setCodes,
  showModal,
  setId,
}: Props) => {
  const { user } = useAuth();

  const [options, setOptions] = useState<boolean>(false);

  const renderOptions = () => {
    return (
      <>
        <SectionCadaster
          onClick={() => {
            setOption('aceitas');
            setOpenModal(false);
            setModalAlertHistory(true);
          }}
        >
          <TextOption style={{ fontSize: '13pt', color: '#1a1a1a' }}>
            Aceitas
          </TextOption>
        </SectionCadaster>

        <SectionCadaster
          onClick={() => {
            setOption('rejeitadas');
            setOpenModal(false);
            setModalAlertHistory(true);
          }}
        >
          <TextOption style={{ fontSize: '13pt', color: '#1a1a1a' }}>
            Rejeitadas
          </TextOption>
        </SectionCadaster>
      </>
    );
  };

  const modal = () => (
    <ModalNavbar>
      <MainContainer>
        <ContainerLogo>
          <Logo src={Logotipo} alt="Logo Booat" />
          <Close
            src={CloseIcon}
            alt="Icon close"
            onClick={() => setOpenModal(false)}
          />
        </ContainerLogo>

        <ContainerAlert>
          <SectionButton
            onClick={() => {
              setCategory('Embarcações');
              setOpenModal(false);
            }}
          >
            <SectionIcon>
              <Icon src={Barco} alt="Icon Barco" width={10} />
            </SectionIcon>
            <TextOption>Embarcações</TextOption>
          </SectionButton>

          <SectionButton
            onClick={() => {
              setCategory('Restaurantes');
              setOpenModal(false);
            }}
          >
            <SectionIcon>
              <Icon src={Restaurante} alt="Icon Restaurante" width={18} />
            </SectionIcon>
            <TextOption>Restaurantes</TextOption>
          </SectionButton>

          <SectionButton
            onClick={() => {
              setCategory('Ponto de Abastecimento');
              setOpenModal(false);
            }}
          >
            <SectionIcon>
              <Icon src={Combustivel} alt="Icon Combustivel" width={16} />
            </SectionIcon>
            <TextOption>Ponto de Abastecimento</TextOption>
          </SectionButton>

          <SectionButton
            onClick={() => {
              setCategory('SOS');
              setOpenModal(false);
            }}
          >
            <SectionIcon>
              <Icon src={Boia} alt="Icon Boia" width={18} />
            </SectionIcon>
            <TextOption>SOS</TextOption>
          </SectionButton>

          <SectionButton
            onClick={() => {
              setCategory('Alertas & Perigos');
              setOpenModal(false);
            }}
          >
            <SectionIcon>
              <Icon src={Alerta} alt="Icon Alerta" width={20} />
            </SectionIcon>
            <TextOption>Alertas & Perigos</TextOption>
          </SectionButton>
        </ContainerAlert>
        <Divisor />
        <ContainerCadaster>
          {(user.permissions.some((item: number) => item === 0) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setEstablishment(true);
                  setOpenModal(false);
                  setId('');
                }}
              >
                <TextOption>Cadastrar Estabelecimento</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 7) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setBanners(true);
                  setOpenModal(false);
                  setId('');
                }}
              >
                <TextOption>Cadastrar Banner</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 1) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setWarning(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Cadastrar Alerta</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 2) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setEmergency(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Cadastrar Emergência</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 3) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setInsurance(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Cadastrar Seguradora</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 5) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setAdmin(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Cadastrar Admin</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 5) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setRadius(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Ativar/Desativar Raio de Icones</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 5) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setShowAnchor(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Cadastrar Poita</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 8) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setUsers(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Listagem de Usuários</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 6) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setRoutes(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Listagem de Rotas</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 6) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setCodes(true);
                  setOpenModal(false);
                }}
              >
                <TextOption>Listagem de Códigos</TextOption>
              </SectionCadaster>
            )}

          {(user.permissions.some((item: number) => item === 4) ||
            user.role === 'Master') && (
              <SectionCadaster
                onClick={() => {
                  setOptions(!options);
                }}
              >
                <TextOption>Solicitações</TextOption>
              </SectionCadaster>
            )}
          {options && renderOptions()}
        </ContainerCadaster>
      </MainContainer>
    </ModalNavbar>
  );

  return showModal ? modal() : null;
};
