import styled, { keyframes } from 'styled-components';
import ImagemBackground from '../../assets/img/BG-blue.png';
import pixel from '../../utils/size';

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Main = styled.div`
  background-image: url(${ImagemBackground});
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  height: 100vh;
  width: 100%;
`;

export const SectionForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${pixel(530)};

  @media only screen and (max-width: 600px) {
    height: auto;
    margin-top: 20px;
    padding: 10px;
  }
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;

  width: ${pixel(390)};
  height: ${pixel(430)};
  margin-top: ${pixel(2)};
  border-radius: ${pixel(12)};
  background: #0000002e;
  padding: ${pixel(32)};

  animation-duration: 1s;
  animation-name: ${fade};

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 360px;
    margin-top: 5%;
    padding: 10px;
    border-radius: 14px;
  }
`;

export const Title = styled.h2`
  color: #ffffff;
  margin-bottom: ${pixel(24)};
  font-size: ${pixel(22)};

  @media only screen and (max-width: 600px) {
    margin-bottom: 10px;
    font-size: 14pt;
  }
`;

export const SubTitle = styled.text`
  color: var(--gray001);
  margin-bottom: ${pixel(24)};
  font-size: ${pixel(14)};

  @media only screen and (max-width: 600px) {
    font-size: 10pt;
    margin-bottom: 10px;
  }
`;

export const Forgot = styled.h6`
  text-align: right;
  color: var(--gray001);
  cursor: pointer;
  margin-bottom: ${pixel(24)};
  margin-top: ${pixel(5)};
  font-size: ${pixel(12)};

  &:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: 600px) {
    font-size: 8pt;
  }
`;

export const Input = styled.input`
  height: ${pixel(45)};
  border-radius: ${pixel(8)};
  border: 1px solid;
  border-color: var(--gray002);
  font-size: ${pixel(16)};
  padding: 0 ${pixel(20)};

  @media only screen and (max-width: 600px) {
    font-size: 12pt;
    height: 42px;
    border-radius: 6px;
    margin-top: 15px;
    padding: 0 20px;
  }
`;

export const Button = styled.button`
  height: ${pixel(45)};
  background: var(--white);
  border: ${pixel(1)} solid;
  border-radius: ${pixel(8)};
  border-color: var(--white);

  font-size: ${pixel(16)};

  &:hover {
    background: var(--gray001);
  }

  @media only screen and (max-width: 600px) {
    font-size: 14pt;
    height: 40px;
    border-radius: 8px;
    margin-top: 10px;
  }
`;
