import {
  SectionMap,
  ImageMap,
  PointerMap,
  TextMap,
  SubTextMap,
} from './_thirdSection';

const ThirdSection = () => {
  return (
    <SectionMap>
      <ImageMap>
        <PointerMap />
        <TextMap>Veja o que tem ao seu redor</TextMap>
      </ImageMap>
      <SubTextMap>
        Está procurando algum estabelecimento enquanto navega? O Booat mostra
        tudo o que está a sua volta, posto de combustível, restaurantes, marina
        e muito mais.
      </SubTextMap>
    </SectionMap>
  );
};

export default ThirdSection;
