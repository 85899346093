import { Dispatch, SetStateAction } from 'react';
import Chat from '../../../../assets/icons/message.svg';
import api from '../../../../services/api';
import handleError from '../../../../utils/handleError';
import { Button, Image, ContainerOption } from './_buttonOption';

interface Props {
  status: string;
  id: string;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIdChat: Dispatch<SetStateAction<string>>;
  setIdentity: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  name: string;
}

export const ButtonOption = ({
  status,
  id,
  setOpenModal,
  setIdChat,
  setIdentity,
  setName,
  name,
}: Props) => {
  const handleUpdate = async (value: string) => {
    try {
      await api.put(`/sos/${value}`, {
        status: 'coming',
      });

      handleError('O Status foi Alterado!', 'success');
      setIdentity(id);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  return (
    <ContainerOption>
      <Button
        onClick={() => {
          if (status === 'finished' || status === 'coming') {
            handleError('Ajuda já foi enviada!', 'info');
          } else {
            handleUpdate(id);
          }
        }}
      >
        Ajuda Enviada
      </Button>
      <Image
        src={Chat}
        width={50}
        onClick={() => {
          setIdChat(id);
          setOpenModal(false);
          setName(name);
        }}
      />
    </ContainerOption>
  );
};
