import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const Toggle = styled.input`
  appearance: none;
  min-width: 2.375rem;
  height: 1.375rem;
  background: #e9ecef;
  border-radius: 2rem;
  border: 1px solid rgba(0, 6, 184, 0.22);

  cursor: pointer;

  margin-left: 2rem;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);

  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    margin: 0.125rem;

    display: inline-block;

    margin-left: 0.125rem;

    border-radius: 50%;

    background-color: #cfcfcf;
    border: none;

    transition: transform 0.2s ease;
  }

  &:checked {
    background-color: rgba(19, 133, 142, 0.2);

    &:after {
      width: 0.875rem;
      height: 0.875rem;
      background-color: #64cfff;
      transform: translateX(90%);
      border: 1px solid rgba(0, 6, 184, 0.22);
    }
  }
`;

interface CardsListProps {
  enabled: boolean;
}

export const CardsList = styled.div<CardsListProps>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  filter: ${({ enabled }) => (enabled ? 'none' : 'grayscale(100%)')};

  transition: filter 0.2s;
`;

interface CardProps {
  cardBackgroundColor: string;
}

export const Card = styled.button<CardProps>`
  border: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.375rem;

  background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};

  font-family: 'Nunito', sans-serif;
  color: #2c2c2c;
  font-size: 0.75rem;
  letter-spacing: 0.01em;

  padding: 0.75rem 1rem 0.625rem;
  min-height: 4.5rem;
  width: 8rem;
  border-radius: 0.625rem;

  &:disabled {
    opacity: 1;
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const CardIcon = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
`;

export const CardText = styled.p``;
