/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Logo, Option, Section } from './_header';

import Login from '../../assets/img/Login.svg';

export const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<string>();

  const getCurrentPage = () => {
    const [, currentNavigation] = pathname.split('/');
    setCurrentPage(currentNavigation);
  };

  useEffect(() => {
    getCurrentPage();
  }, []);

  return (
    <Section>
      <Logo onClick={() => history.push('/')} />
      <Option
        src={Login}
        alt="Icon Login"
        // active={currentPage !== 'login'}
        active={false}
        onClick={() => history.push('/login')}
      />
    </Section>
  );
};
