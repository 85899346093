import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(3, 3, 3, 0.08);
`;

export const Content = styled.div`
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 1px 2px 20px 0px var(--gray003);

  min-height: 15rem;
  width: 100%;
  max-width: 26.25rem;

  padding: 2.5rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

interface Props {
  option?: boolean;
}

export const Button = styled.button<Props>`
  width: 100%;

  font-family: 'Nunito';

  border: 2px solid var(--blue003);
  background-color: ${props =>
    props.option ? 'var(--blue003)' : 'transparent'};
  color: ${props => (props.option ? 'var(--white)' : 'var(--blue003)')};
  border-radius: 8px;
  padding: 0 1.25rem;

  height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonText = styled.p`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.01em;
`;
