import { useEffect } from 'react';
import { Header } from '../../shared/Header/Header';

import { Main } from './_home';

import FirstSection from './Components/FirstSection/FirstSection';
import ThirdSection from './Components/ThirdSection/ThirdSection';
import SecondSection from './Components/SecondSection/SecondSection';
import LastSection from './Components/LastSection/LastSection';

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Main>
      <Header />

      <FirstSection />

      <SecondSection />

      <ThirdSection />

      <LastSection />
    </Main>
  );
};
