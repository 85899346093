import { useImperativeHandle, useState, forwardRef } from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { IoLocationSharp, IoBoat, IoCall, IoPerson } from 'react-icons/io5';
import { IoCalendarClearSharp } from 'react-icons/io5';
import { format, parseISO } from 'date-fns';
import { IBoatList } from '../../../pages/MapsPage/interface';
import { InfoText, Container, ModalMarker } from './styles';
import { maskPhone } from '../../../utils/mask';

interface Props {
  boat: IBoatList | null | undefined;
  cat: string;
}

export interface PropsBoatRef {
  open: () => void;
  close: () => void;
}

export const BoatModal = forwardRef<PropsBoatRef, Props>(
  ({ boat, cat }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {
      setIsOpen(!isOpen);
    };

    const close = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open,
      close,
    }));

    if (!boat) {
      return null;
    }

    if (!cat.includes('Embarcações')) {
      return null;
    }

    const position = {
      lat: Number(boat.latitude),
      lng: Number(boat.longitude),
    };

    const date =
      boat.started_routes?.[0]?.started_at &&
      format(
        parseISO(boat.started_routes?.[0]?.started_at),
        'dd/MM/yyyy HH:mm',
      );

    if (!isOpen) {
      return null;
    }

    return (
      <InfoWindow position={position}>
        <ModalMarker>
          <Container>
            <IoLocationSharp color="#76a0c0" size={20} />
            <InfoText>
              {boat.latitude.slice(0, 10)}/ {boat.longitude.slice(0, 10)}
            </InfoText>
          </Container>

          <Container>
            <IoPerson color="#76a0c0" size={20} />
            <InfoText>{boat.name}</InfoText>
          </Container>

          <Container>
            <IoBoat color="#76a0c0" size={20} />
            <InfoText>{boat.boats?.[0]?.name}</InfoText>
          </Container>

          <Container>
            <IoCall color="#76a0c0" size={20} />
            <InfoText>{maskPhone(boat.phone.replace('+55', ''))}</InfoText>
          </Container>

          <Container>
            <IoCalendarClearSharp color="#76a0c0" size={20} />
            <InfoText>Início: {date}</InfoText>
          </Container>
        </ModalMarker>
      </InfoWindow>
    );
  },
);
