import styled from 'styled-components';

export const ModalMarker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 257px;
  height: 150px;
  padding-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.text`
  font-size: 18pt;
  margin-bottom: 5px;
  font-weight: 500;
`;

export const Image = styled.img`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Adress = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 12pt;
  font-weight: 400;
`;

export const BusinessHours = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 12pt;
  font-weight: 400;
`;

export const Telephone = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 11pt;
`;

export const Edit = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 18px;
  right: 62px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Trash = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 18px;
  right: 18px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  border: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const SectionClose = styled.button`
  height: 30px;
  width: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  border: 0;

  &:hover {
    opacity: 0.8;
  }
`;
