import styled from 'styled-components';
import pixel from '../../../../utils/size';

export const SectionStatus = styled.div`
  padding: 0 ${pixel(15)};
  height: ${pixel(45)};
  width: auto;
  background-color: #fafafa;
  position: absolute;
  right: ${pixel(160)};
  top: ${pixel(30)};
  z-index: 1;
  border-radius: ${pixel(70)};
  font-size: ${pixel(14)};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: ${pixel(15)};

  &:hover {
    background-color: #fafafaef;
  }

  @media only screen and (max-width: 600px) {
    top: 75px;
    left: 15px;
    right: auto;
  }

  .button-close-status {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ImageIcon = styled.img`
  width: ${pixel(15)};

  @media only screen and (max-width: 600px) {
    width: 15px;
  }
`;

export const CloseButton = styled.img`
  cursor: pointer;
  width: ${pixel(20)};

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 600px) {
    width: 25px;
  }
`;

export const TextStatus = styled.text`
  @media only screen and (max-width: 600px) {
    font-size: 12pt;
  }
`;
