import { Row, Image } from './_userInformation';
import Marker from '../../../../assets/icons/marker.svg';
import SimpleBoat from '../../../../assets/icons/blueSimpleBoat.svg';
import User from '../../../../assets/icons/blueUser.svg';

interface Props {
  latitude: string;
  longitude: string;
  user: string;
  boat: string;
}

export const UserInformation = ({ latitude, longitude, user, boat }: Props) => {
  return (
    <div>
      <Row>
        <Image src={Marker} alt="Icone do marcador" width={13} />
        <p>
          {latitude.slice(0, 10)}/ {longitude.slice(0, 10)}
        </p>
      </Row>
      <Row>
        <Image src={User} alt="Icone do Usuario" width={15} />
        <p>{user}</p>
      </Row>
      <Row>
        <Image src={SimpleBoat} alt="Icone do barco" width={15} />
        <p>{boat}</p>
      </Row>
    </div>
  );
};
