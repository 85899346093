import { Dispatch, SetStateAction, useState } from 'react';

import { ImageMessage, Pointer } from './styles';

import Message from '../../assets/icons/message.svg';

import { ModalChat } from '../Modals/ModalChat/ModalChat';

interface Props {
  setIdChat: Dispatch<SetStateAction<string>>;
  user: ChatUser;
}

interface ChatUser {
  message: number;
  name: string;
  id: string;
  type: string;
}

export const ChatMapIcon = ({ setIdChat, user }: Props) => {
  const [modalChat, setModalChat] = useState<boolean>(false);

  return (
    <>
      {user.message > 0 ? (
        <Pointer>{user.message > 99 ? 99 : user.message}</Pointer>
      ) : null}

      <ImageMessage
        src={Message}
        alt="Icon message chat"
        onClick={() => {
          setModalChat(!modalChat);
          setIdChat('');
        }}
      />

      {modalChat && (
        <ModalChat
          setOpenModal={setModalChat}
          setIdChat={setIdChat}
          idChat={user.id}
          name={user.name}
          type={user.type}
        />
      )}

      {user.id && (
        <ModalChat
          setOpenModal={setModalChat}
          setIdChat={setIdChat}
          idChat={user.id}
          name={user.name}
          type={user.type}
        />
      )}
    </>
  );
};
