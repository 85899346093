import { Dispatch, SetStateAction } from 'react';
import Bell from '../../../../assets/icons/bell.svg';
import { Modal } from '../../../../shared/Modal/Modal';
import { ModalAlerts } from '../../../../shared/ModalAlerts/ModalAlerts';
import { ModalSos } from '../../../../shared/ModalSos/ModalSos';
import { ResponseConcierge } from '../../interface';

import { ImageAlert, Pointer } from './_modalAlerts';

interface Props {
  modalAlerts: boolean;
  modalSos: boolean;
  notification: boolean;
  notification2: boolean;
  setNotification: Dispatch<SetStateAction<boolean>>;
  setNotification2: Dispatch<SetStateAction<boolean>>;
  setModalAlerts: Dispatch<SetStateAction<boolean>>;
  setModalSos: Dispatch<SetStateAction<boolean>>;
  setIdChat: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setType: Dispatch<SetStateAction<string>>;
  concierge: ResponseConcierge[];
}

export const Alerts = ({
  modalAlerts,
  modalSos,
  notification,
  notification2,
  setNotification,
  setNotification2,
  setModalAlerts,
  setModalSos,
  setIdChat,
  setName,
  setType,
  concierge,
}: Props) => {
  const renderSos = () => {
    return (
      <Modal>
        <ModalSos
          setOpenModal={setModalSos}
          setIdChat={setIdChat}
          setName={setName}
        />
      </Modal>
    );
  };

  const renderPointer = () => {
    if (
      notification ||
      notification2 ||
      concierge.filter(item => !item.admin_viewed).length > 0
    ) {
      return <Pointer />;
    }
  };

  return (
    <>
      <ImageAlert
        src={Bell}
        alt="Icon Alert"
        onClick={() => {
          setModalAlerts(!modalAlerts);
        }}
      />

      {renderPointer()}

      {modalAlerts && (
        <ModalAlerts
          setOpenModal={setModalAlerts}
          setModalSos={setModalSos}
          setNotification={setNotification}
          notification={notification}
          setNotification2={setNotification2}
          notification2={notification2}
          concierge={concierge}
          setIdChat={setIdChat}
          setName={setName}
          setType={setType}
        />
      )}

      {modalSos && renderSos()}
    </>
  );
};
