import styled, { keyframes } from 'styled-components';

interface Props {
  option?: boolean;
}

const fade = keyframes`
    from{
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

export const SectionOption = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  width: 100%;
  height: 150px;
  gap: 10px;

  p {
    font-size: 13pt;
    padding: 0 23px;
    color: #1a1a1a;
    font-family: 'Nunito';
  }
`;

export const ModalMarina = styled.div<Props>`
  width: 100%;
  height: 100px;
  border-radius: 6px;
  background: ${props => (props.option ? '#fafafa' : 'white')};
  border: 1px solid #ededed;
  box-shadow: 1px 3px 1px #bcbcbc;

  animation-name: ${fade};
  animation-duration: 0.5s;
`;

export const CloseMarina = styled.div`
  text-align: right;
`;

export const ImageClosed = styled.img`
  width: 8px;
  margin: 10px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const ButtonMarina = styled.button`
  align-self: center;
  width: 150px;
  height: 35px;
  font-size: 12pt;
  font-family: 'Nunito';
  border-radius: 6px;
  border: 1px solid var(--blue003);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover {
    opacity: 0.8;
  }
`;

export const OptionsImage = styled.img<Props>`
  border: 1px solid
    ${props => (props.option ? 'var(--blue003)' : 'var(--white0)')};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
