import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalNavbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 297px;
  height: 100vh;
  background-color: var(--white);
  border-radius: 0 18px 18px 0;
  animation-name: ${fade};
  animation-duration: 0.5s;

  box-shadow: 1px 1px 10px 0px #999999;

  @media only screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 30px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 44px 28px 28px 28px;

  @media only screen and (max-width: 600px) {
    padding: 20px 28px 28px 28px;
  }
`;

export const Logo = styled.img`
  width: 100px;
  margin-left: 0.3rem;
`;

export const Close = styled.img`
  cursor: pointer;
  color: #cccccc;

  &:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: 600px) {
    width: 50px;
  }
`;

export const ContainerAlert = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 26px;
  gap: 6px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray001);
  }

  @media only screen and (max-width: 600px) {
    height: 50px;
  }
`;

export const TextOption = styled.text`
  font-size: 12pt;

  @media only screen and (max-width: 600px) {
    font-size: 16pt;
  }
`;

export const SectionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
`;

export const Icon = styled.img``;

export const Divisor = styled.hr`
  width: 100%;
  border-color: var(--gray001);
  margin: 18px 0;
`;

export const ContainerCadaster = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SectionCadaster = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 35px;
  padding: 0 28px;

  cursor: pointer;

  &:hover {
    background-color: var(--gray001);
  }

  @media only screen and (max-width: 600px) {
    height: 50px;
  }
`;
