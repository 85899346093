import styled from 'styled-components';

export const ModalMarker = styled.div`
  min-width: 257px;
  min-height: 150px;
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
`;

export const InfoText = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
`;
