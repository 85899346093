import { Footer } from '../../shared/Footer/Footer';
import { Header } from '../../shared/Header/Header';

import { FormLogin, SectionForm, Main, Title, SubTitle, Text } from './styles';

export const Terms = () => {
  return (
    <Main>
      <Header />

      <SectionForm>
        <FormLogin>
          <Title>TERMOS E CONDIÇÕES</Title>
          <SubTitle>1. RELACIONAMENTO CONTRATUAL</SubTitle>
          <Text>
            Estes Termos de uso (“Termos”) regem seu acesso e uso, como pessoa
            física, dentro do Brasil, de aplicativos, sítios de Internet,
            conteúdos, bens e também serviços (os “Serviços”) disponibilizados
            pela Booat Tecnologia.
          </Text>
          <Text>
            Ao acessar e usar os Serviços você concorda com os presentes termos
            e condições, que estabelecem o relacionamento contratual entre você
            e o Booat. Se você não concorda com estes Termos, você não pode
            acessar nem usar os Serviços. O Booat poderá imediatamente encerrar
            estes Termos ou quaisquer Serviços em relação a você ou, de modo
            geral, deixar de oferecer ou negar acesso aos Serviços ou a qualquer
            parte deles, a qualquer momento e por qualquer motivo.
          </Text>
          <Text>
            Termos adicionais poderão se aplicar a determinados Serviços, tais
            como condições para um produto, serviço, atividade ou promoção em
            particular, e esses Termos adicionais serão divulgados em relação
            aos respectivos Serviços. Termos adicionais são complementares e
            considerados parte integrante destes Termos para os efeitos dos
            respectivos Serviços. Termos adicionais prevalecerão sobre estes
            Termos em caso de conflito com relação aos referidos Serviços.
          </Text>
          <Text>
            O Booat poderá alterar os Termos relativos aos Serviços a qualquer
            momento. Aditamentos entrarão em vigor quando o Booat fizer a
            postagem da versão atualizada dos Termos neste local ou das
            condições atualizadas ou Termos adicionais sobre o respectivo
            Serviço. O fato de você continuar a acessar ou usar os Serviços após
            essa postagem representa seu consentimento em vincular-se aos Termos
            alterados.
          </Text>
          <Text>
            Nossa obtenção e uso de informações pessoais associadas aos Serviços
            está disciplinada na Declaração de Privacidade de Usuários do Booat
            localizada em www.booat.com.br/privacidade. O Booat ou qualquer de
            suas afiliadas poderá fornecer a uma gerenciadora de reclamações ou
            seguradoras qualquer informação necessária (inclusive suas
            informações de contato e atendimento) se houver qualquer acidente,
            reclamação, litígio ou conflito, o que pode incluir acidente
            envolvendo sua embarcação ou a de Terceiros, e essas informações ou
            dados que forem necessários para solucionar a reclamação, acidente,
            litígio ou conflito.
          </Text>

          <SubTitle>2. OS SERVIÇOS</SubTitle>
          <Text>
            Os Serviços integram uma plataforma de tecnologia que permite aos
            Usuários de aplicativos móveis ou sítios de Internet do Booat,
            fornecidos como parte dos Serviços (cada qual um “Aplicativo”),
            providenciar e programar Serviços de navegação e/ou apoio e/ou
            compra de certos bens/serviços com terceiros provedores
            independentes desses Serviços, mediante contrato com o Booat ou
            determinadas Afiliadas (“Prestadores Terceiros”). A menos que
            diversamente acordado pelo Booat em contrato escrito em separado
            firmado, os Serviços são disponibilizados para seu uso pessoal e não
            comercial. VOCÊ RECONHECE QUE O BOOAT NÃO É FORNECEDORA DE BENS, NÃO
            PRESTA SERVIÇOS DE QUALQUER NATUREZA À EMBARCAÇÕES (EXCETO AQUELES
            CLARAMENTE DIVULGADOS AO PUBLICO E/OU ACORDADOS PREVIAMENTE EM
            CONTRATO COM OUTRAS EMPRESAS E/OU PRESTADORAS DE SERVIÇOS), NEM
            FUNCIONA COMO SEGURADORA, E QUE TODOS ESSES SERVIÇOS SÃO PRESTADOS
            POR PRESTADORES TERCEIROS INDEPENDENTES QUE NÃO SÃO EMPREGADOS (AS)
            E NEM REPRESENTANTES DO BOOAT, NEM DE QUALQUER DE SUAS AFILIADAS.
          </Text>

          <SubTitle>LICENÇA.</SubTitle>
          <Text>
            Sujeito ao cumprimento destes Termos, o Booat outorga a você uma
            licença limitada, não exclusiva, não passível de sublicença,
            revogável e não transferível para: (I) acesso e uso do Aplicativo em
            seu dispositivo pessoal, exclusivamente para o seu uso dos Serviços;
            e acesso e uso de qualquer conteúdo, informação e material correlato
            que possa ser disponibilizado por meio dos Serviços, em cada caso,
            para seu uso pessoal, nunca comercial. Quaisquer direitos não
            expressamente outorgados por estes Termos são reservados ao Booat e
            suas afiliadas licenciadoras.
          </Text>

          <SubTitle>RESTRIÇÕES.</SubTitle>
          <Text>
            Você não poderá: (I) remover qualquer aviso de direito autoral,
            direito de marca ou outro aviso de direito de propriedade de
            qualquer parte dos Serviços; (II) reproduzir, modificar, preparar
            obras derivadas, distribuir, licenciar, alugar, vender, revender,
            transferir, exibir, copiar, veicular, transmitir ou, de qualquer
            outro modo, explorar os Serviços, exceto da forma expressamente
            permitida pelo BOOAT; (III) compilar, realizar engenharia reversa ou
            desmontar os Serviços, exceto conforme permitido pela legislação
            aplicável; (IV) conectar, espelhar ou recortar qualquer parte dos
            Serviços; (V) fazer ou lançar quaisquer programas ou scripts com a
            finalidade de fazer scraping, indexação, pesquisa ou qualquer outra
            forma de obtenção de dados de qualquer parte dos Serviços, ou de
            sobrecarregar ou prejudicar indevidamente a operação e/ou
            funcionalidade de qualquer aspecto dos Serviços; ou (VI) tentar
            obter acesso não autorizado aos Serviços ou prejudicar qualquer
            aspecto dos Serviços ou seus sistemas ou redes correlatas.
          </Text>

          <SubTitle>PRESTAÇÃO DOS SERVIÇOS.</SubTitle>
          <Text>
            Você reconhece que os Serviços podem ser disponibilizados sob
            diferentes marcas do Booat ou diferentes opções de solicitação
            associadas aos serviços prestados por terceiros independentes.
          </Text>

          <SubTitle>SERVIÇOS E CONTEÚDO DE TERCEIROS (AS).</SubTitle>
          <Text>
            Os Serviços poderão ser disponibilizados e/ou acessados em conexão
            com Serviços e conteúdo de terceiros (as) (inclusive publicidade)
            que o Booat não controlará. VOCÊ RECONHECE QUE TERMOS DE USO E
            POLÍTICAS DE PRIVACIDADE DIFERENTES PODERÃO SER APLICÁVEIS AO USO
            DESSES SERVIÇOS E CONTEÚDO DE TERCEIROS (AS). O BOOAT NÃO ENDOSSA
            ESSES SERVIÇOS E CONTEÚDO DE TERCEIROS (AS) E O BOOAT NÃO SERÁ, EM
            HIPÓTESE ALGUMA, RESPONSÁVEL POR NENHUM PRODUTO OU SERVIÇO DESSES
            (AS) TERCEIROS (AS) FORNECEDORES (AS). Além disto, Apple Inc.,
            Google, Inc., Microsoft Corporation e/ou suas subsidiárias e
            afiliadas internacionais serão terceiros (as) beneficiários (as)
            deste Contrato, caso você acesse os Serviços usando aplicativos
            desenvolvidos para dispositivos móveis baseados em Apple iOS e
            Android, respectivamente. Esses (as) terceiros (as) beneficiários
            (as) não são partes deste Contrato e não são responsáveis pela
            prestação dos Serviços ou por qualquer forma de suporte aos
            Serviços. Seu acesso aos Serviços usando esses dispositivos está
            sujeito às condições estabelecidas nos termos de serviços dos
            respectivos terceiros (as) beneficiários (as).
          </Text>

          <SubTitle>TITULARIDADE.</SubTitle>
          <Text>
            Os Serviços e todos os direitos sobre eles são e permanecerão de
            propriedade do Booat ou de propriedade de suas afiliadas, ou de suas
            respectivas licenciadoras, conforme o caso. Estes Termos e o uso dos
            Serviços não lhe outorgam nem lhe conferem qualquer direito: (I)
            sobre os Serviços, exceto pela licença limitada concedida acima; ou
            (II) de usar ou, de qualquer modo, fazer referência a nomes
            societários, logotipos, nomes de produtos ou de Serviços, marcas
            comerciais ou marcas de serviço do Booat ou de qualquer licenciadora
            do Booat.
          </Text>

          <SubTitle>3. O USO DOS SERVIÇOS</SubTitle>
          <SubTitle>CONTAS DE USUÁRIOS.</SubTitle>
          <Text>
            Para utilizar grande parte dos Serviços, você deve registrar-se e
            manter uma conta pessoal de usuário de Serviços (“Conta”). Você deve
            ter pelo menos 18 anos ou a maioridade exigida por lei em seu foro
            (se for diferente de 18 anos) para abrir uma conta. Registro de
            Conta exige que você apresente ao Booat certas informações pessoais,
            tais como seu nome, endereço, número de telefone celular e idade,
            assim como pelo menos um método de pagamento válido (cartão de
            crédito ou outro método de pagamento aceito). Você concorda em
            manter informações corretas, completas e atualizadas em sua Conta.
            Se você não mantiver informações corretas, completas e atualizadas
            em sua Conta, inclusive se o método de pagamento informado for
            inválido ou expirado, você poderá ficar impossibilitado de acessar e
            usar os Serviços ou o Booat poderá resolver estes Termos. Você é
            responsável por todas as atividades realizadas na sua Conta e
            concorda em manter sempre a segurança e o sigilo do nome de usuário
            e senha da sua Conta. A menos que diversamente permitido pelo Booat
            por escrito, você poderá manter apenas uma Conta.
          </Text>

          <SubTitle>CONDUTA E OBRIGAÇÕES DO USUÁRIO.</SubTitle>
          <Text>
            O serviço não está disponível para uso para indivíduos menores de 18
            anos. Você não poderá autorizar terceiros (as) a usar sua Conta,
            você não poderá permitir que pessoas menores de 18 anos recebam
            Serviços, salvo se estiverem em sua companhia. Você não poderá
            ceder, nem de qualquer outro modo transferir sua Conta a nenhuma
            outra pessoa ou entidade. Você concorda em cumprir todas as leis
            aplicáveis quando usar os Serviços e que somente poderá usar os
            Serviços para finalidades legítimas. Você não poderá, quando usar os
            Serviços, causar transtorno, aborrecimento, inconveniente ou danos à
            propriedade dos Prestadores Terceiros ou de qualquer outro terceiro.
            Em determinadas situações, você poderá ser solicitado (a) a fornecer
            comprovante de identidade para acessar ou usar os Serviços, e
            concorda que poderá ter seu acesso ou uso dos Serviços negado caso
            você se recuse a fornecer comprovante de identidade.
          </Text>

          <SubTitle>MENSAGEM DE TEXTO.</SubTitle>
          <Text>
            Ao criar uma Conta, você concorda que os Serviços poderão lhe enviar
            mensagens de textos informativas (SMS) como parte das operações
            comerciais regulares para o uso dos Serviços. Você poderá optar por
            não receber mensagens de texto (SMS) do BOOAT a qualquer momento
            enviando e-mail para contato@booat.com.br e indicando que não mais
            deseja receber essas mensagens, juntamente com o número do telefone
            celular que as recebe. Você reconhece que ao optar por não receber
            as mensagens de texto poderá impactar o uso dos Serviços.
          </Text>

          <SubTitle>CÓDIGOS PROMOCIONAIS.</SubTitle>
          <Text>
            O Booat poderá, a seu exclusivo critério, criar códigos promocionais
            que poderão ser resgatados para crédito na Conta ou outras
            características ou benefícios relacionados aos Serviços e/ou a
            Serviços de Prestadores Terceiros, sujeitos a quaisquer condições
            adicionais que o Booat estabelecer para cada um dos códigos
            promocionais (“Códigos Promocionais”). Você concorda que Códigos
            Promocionais: (I) devem ser usados de forma legal para a finalidade
            e o público a que se destinam; (II) não devem ser duplicados, de
            qualquer forma vendidos, transferidos ou disponibilizados ao público
            em geral (seja por meio de postagem ao público ou qualquer outro
            método), a menos que expressamente permitido pelo Booat; (III)
            poderão ser desabilitados pelo Booat a qualquer momento por motivos
            legalmente legítimos, sem que disto resulte qualquer
            responsabilidade para o Booat; (IV) somente poderão ser usados de
            acordo com as condições específicas que o Booat estabelecer para
            esses Código Promocional; (V) não são válidos como dinheiro; e (VI)
            poderão expirar antes de serem usados. O Booat se reserva o direito
            de reter ou deduzir créditos ou outras funcionalidades ou vantagens
            obtidas por meio do uso dos Códigos Promocionais por você ou por
            outro usuário, caso o Booat apure ou acredite que o uso ou resgate
            do Código Promocional foi feito com erro, fraude, ilegalidade ou
            violação às condições do respectivo Código Promocional.
          </Text>

          <SubTitle>CONTEÚDO FORNECIDO PELO (A) USUÁRIO(A).</SubTitle>
          <Text>
            O Booat poderá, a seu exclusivo critério, permitir que você ou
            qualquer pessoa apresente, carregue, publique ou, de qualquer modo,
            disponibilize para o Booat por meio dos Serviços, conteúdo e
            informações de texto, áudio ou vídeo, inclusive comentários e
            feedbacks relacionados aos Serviços, iniciação de solicitação de
            suporte e registro em concursos e promoções (“Conteúdo de Usuário
            (a)). Qualquer Conteúdo de Usuário (a) fornecido por você permanece
            de sua propriedade. Contudo, ao fornecer Conteúdo de Usuário(a) para
            o Booat, você outorga o Booat e suas afiliadas uma licença em nível
            mundial, perpétua, irrevogável, transferível, isenta de royalties, e
            com direito a sublicenciar, usar, copiar, modificar, criar obras
            derivadas, distribuir, publicar, exibir, executar em público e, de
            qualquer outro modo, explorar esse Conteúdo de Usuário(a) em todos
            os formatos e canais de distribuição hoje conhecidos ou
            desenvolvidos no futuro (inclusive em conexão com os Serviços e com
            os negócios do Booat e em sites e Serviços de terceiros), sem
            ulterior aviso a você ou seu consentimento, e sem necessidade de
            pagamento a você ou a qualquer outra pessoa ou entidade.
          </Text>
          <Text>
            Você declara e garante que: (I) é o(a) único(a) e exclusivo(a)
            proprietário(a) de todo Conteúdo de Usuário(a) ou tem todos os
            direitos, licenças, consentimentos e liberações necessários para
            outorgar ao Booat a licença sobre o Conteúdo de Usuário(a) acima
            referido; e (II) nem o Conteúdo de Usuário(a) nem sua apresentação,
            carregamento, publicação ou outra forma de disponibilização desse
            Conteúdo de Usuário(a) tampouco o uso do Conteúdo de Usuário(a) pelo
            Booat da forma aqui permitida infringirá, constituirá apropriação
            indevida nem violará propriedade intelectual ou direito de
            propriedade de terceiros(a), nem direitos de publicidade ou
            privacidade e também não resultarão na violação de qualquer lei ou
            regulamento aplicável.
          </Text>
          <Text>
            Você concorda em não fornecer Conteúdo de Usuário (a) que seja
            difamatório, calunioso, injurioso, violento, obsceno, pornográfico,
            ilegal ou de qualquer modo ofensivo, conforme apuração do Booat a
            seu critério exclusivo, seja ou não esse material protegido por lei.
            O Booat poderá, mas não está obrigado a analisar, monitorar ou
            remover Conteúdo de Usuário (a), a critério exclusivo do Booat, a
            qualquer momento e por qualquer motivo, sem nenhum aviso a você.
          </Text>

          <SubTitle>ACESSO A REDE E EQUIPAMENTOS.</SubTitle>
          <Text>
            Você é responsável por obter o acesso a rede de dados necessário
            para usar os Serviços. As taxas e encargos de sua rede de dados e
            mensagens poderão se aplicar se você acessar ou usar os Serviços de
            um dispositivo sem fio e você será responsável por essas taxas e
            encargos. Você é responsável por adquirir e atualizar os
            equipamentos e dispositivos necessários para acessar e usar os
            Serviços e Aplicativos e quaisquer de suas atualizações. O BOOAT NÃO
            GARANTE QUE OS SERVIÇOS, OU QUALQUER PARTE DELES, FUNCIONARÃO EM
            QUALQUER EQUIPAMENTO OU DISPOSITIVO EM PARTICULAR. Além disso, os
            Serviços poderão estar sujeitos a mau funcionamento e atrasos
            inerentes ao uso da Internet e de comunicações eletrônicas.
          </Text>

          <SubTitle>4. PAGAMENTO</SubTitle>
          <Text>
            Você entende que os serviços ou bens que você receber de um
            Prestador Terceiro, contratados por meio dos Serviços, poderão ser
            cobrados (“Preço”). Após ou antecipadamente você ter recebido
            serviços ou bens obtidos por meio do uso do Serviço, o Booat
            facilitará o pagamento do respectivo Preço em nome do Prestador
            Terceiro na qualidade de agente limitado de cobrança desse Prestador
            Terceiro. O pagamento do Preço feito dessa maneira será considerado
            pagamento feito diretamente por você ao Prestador Terceiro. O preço
            incluirá todos os tributos exigidos por lei.
          </Text>
          <Text>
            O preço pago por você pode sofrer alterações e qualquer valor
            referente a taxas de visita/deslocamento não é reembolsável, a menos
            que diversamente determinado pelo Booat. Você tem o direito de
            solicitar uma redução no Preço ao Prestador Terceiro por serviços ou
            bens recebidos desse Prestador Terceiro no momento em que receber
            esses serviços ou bens. O Booat responderá de acordo com qualquer
            solicitação de Prestador Terceiro para modificar o Preço de um
            serviço ou bem em particular. Na máxima extensão permitida pela lei
            aplicável, o Booat reserva o direito de limitar os Preços cobrados
            em espécie. Uma vez limitado o valor a ser pago em espécie, você
            deverá disponibilizar um método alternativo de pagamento.
          </Text>
          <Text>
            O preço total é devido e deve ser pago antes da prestação do
            serviço; ou entrega do bem; o pagamento será facilitado pelo Booat
            mediante o método de pagamento indicado na sua Conta, após pagamento
            o Booat enviará um recibo por e-mail. Se for verificado que o método
            de pagamento indicado na Conta expirou, é inválido ou não pode ser
            cobrado, você concorda que o Booat poderá, na qualidade de agente
            limitado de cobrança do Prestador Terceiro, usar um método
            secundário de cobrança na Conta, se houver.
          </Text>
          <Text>
            Na relação entre você e o Booat, o Booat reserva-se o direito de
            estabelecer, remover e/ou revisar o Preço relativo a todos os
            serviços, taxas ou bens obtidos por meio do uso dos Serviços a
            qualquer momento, a critério exclusivo do Booat. Ademais, você
            reconhece e concorda que o Preço aplicável em certas áreas
            geográficas poderá (I) incluir cobranças, tarifas, taxas, impostos
            e/ou contribuições governamentais devidas em seu nome, inclusive,
            tarifas de pedágios ou cobranças de aeroportos, conforme a rota
            tomada pelo Prestador Terceiro. (II) O pagamento de taxas, impostos
            e/ou contribuições governamentais, serão de sua responsabilidade e
            você reembolsará o Prestador Terceiro e/ou o Booat por todas
            tarifas, taxas, impostos e/ou contribuições governamentais pagas em
            seu nome. O Booat enviará esforços razoáveis para informá-lo dos
            Preços que poderão se aplicar, sendo certo que você será responsável
            pelo pagamento dos Preços lançados em sua Conta independentemente de
            estar ciente desses Preços ou de seus valores. O Booat poderá, a
            qualquer momento, fornecer a certos (as) usuários (as) ofertas e
            descontos promocionais que poderão resultar em valores diferentes
            cobrados por Serviços iguais ou similares a outros obtidos por meio
            do uso dos Serviços, e você concorda que essas ofertas e descontos
            promocionais, a menos que também oferecidos a você, não terão
            influência sobre os Preços aplicados. Você poderá optar por cancelar
            sua solicitação de serviços ou bens de um Prestador Terceiro a
            qualquer momento antes da chegada desse Prestador Terceiro, caso em
            que poderá incidir uma taxa de cancelamento.
          </Text>

          <SubTitle>TAXAS DE VISITA OU DESLOCAMENTO.</SubTitle>
          <Text>
            Você será responsável pelos custos “taxas” do Booat bem como
            visitas/deslocamento de Prestadores Terceiros resultantes do uso dos
            Serviços através da sua Conta. Caso um Prestador Terceiro relate a
            necessidade tarifas extras e essa solicitação seja confirmada pelo
            Booat, a critério razoável do Booat, o Booat reserva-se o direito de
            facilitar o pagamento desses custos em nome do Prestador Terceiro
            usando o método de pagamento indicado em sua Conta. Referidos
            valores serão transferidos pelo Booat ao respectivo Prestador
            Terceiro e não serão reembolsáveis.
          </Text>

          <SubTitle>
            5. RECUSA DE GARANTIA; LIMITAÇÃO DE RESPONSABILIDADE; INDENIZAÇÃO.
          </SubTitle>
          <SubTitle>RECUSA DE GARANTIA.</SubTitle>
          <Text>
            O BOOAT RECUSA TODAS AS DECLARAÇÕES E GARANTIAS, EXPRESSAS,
            IMPLÍCITAS OU LEGAIS, NÃO EXPRESSAMENTE CONTIDAS NESTES TERMOS,
            INCLUSIVE AS GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A
            UMA FINALIDADE ESPECÍFICA E NÃO INFRINGÊNCIA. ADEMAIS, O BOOAT NÃO
            FAZ NENHUMA DECLARAÇÃO NEM DÁ GARANTIA SOBRE A CONFIABILIDADE,
            PONTUALIDADE, QUALIDADE, ADEQUAÇÃO OU DISPONIBILIDADE DOS SERVIÇOS
            OU DE QUAISQUER SERVIÇOS OU BENS SOLICITADOS POR MEIO DO USO DOS
            SERVIÇOS, NEM QUE OS SERVIÇOS SERÃO ININTERRUPTOS OU LIVRES DE
            ERROS. O BOOAT NÃO GARANTE A QUALIDADE, ADEQUAÇÃO, SEGURANÇA OU
            HABILIDADE DE PRESTADORES TERCEIROS. VOCÊ CONCORDA QUE TODO O RISCO
            DECORRENTE DO USO DOS SERVIÇOS E DE QUALQUER SERVIÇO OU BEM
            SOLICITADO POR MEIO DA TECNOLOGIA SERÁ SEMPRE SEU NA MÁXIMA MEDIDA
            PERMITIDA PELA LEI APLICÁVEL.
          </Text>

          <SubTitle>LIMITAÇÃO DE RESPONSABILIDADE.</SubTitle>
          <Text>
            O BOOAT NÃO SERÁ RESPONSÁVEL POR DANOS INDIRETOS, INCIDENTAIS,
            ESPECIAIS, PUNITIVOS OU EMERGENTES, INCLUSIVE LUCROS CESSANTES,
            PERDA DE DADOS, DANOS MORAIS OU PATRIMONIAIS RELACIONADOS,
            ASSOCIADOS OU DECORRENTES DE QUALQUER USO DOS SERVIÇOS AINDA QUE O
            BOOAT TENHA SIDO ALERTADA PARA A POSSIBILIDADE DESSES DANOS. O BOOAT
            NÃO SERÁ RESPONSÁVEL POR NENHUM DANO, OBRIGAÇÃO OU PREJUÍZO
            DECORRENTE DO: (I) SEU USO DOS SERVIÇOS OU SUA INCAPACIDADE DE
            ACESSAR OU USAR OS SERVIÇOS; OU (II) QUALQUER OPERAÇÃO OU
            RELACIONAMENTO ENTRE VOCÊ E QUALQUER PRESTADOR TERCEIRO, AINDA QUE O
            BOOAT TENHA SIDO ALERTADO PARA A POSSIBILIDADE DESSES DANOS. O BOOAT
            NÃO SERÁ RESPONSÁVEL POR ATRASOS OU FALHAS DECORRENTES DE CAUSAS
            FORA DO CONTROLE RAZOÁVEL DO BOOAT E, TAMPOUCO, PELA QUALIDADE E
            INTEGRIDADE DOS BENS E/OU SERVIÇOS DISPONIBILIZADOS POR PRESTADORES
            TERCEIROS.
          </Text>
          <Text>
            OS SERVIÇOS DO BOOAT PODERÃO SER USADOS POR VOCÊ PARA SOLICITAR E
            PROGRAMAR SERVIÇOS DE EMBARCAÇÕES POR PRESTADORES TERCEIROS, MAS
            VOCÊ CONCORDA QUE O BOOAT NÃO TEM RESPONSABILIDADE EM RELAÇÃO A
            VOCÊ, POR CONTA DE QUALQUER SERVIÇO, BENS OU LOGÍSTICA REALIZADOS
            POR PRESTADORES TERCEIROS, SALVO SE EXPRESSAMENTE ESTABELECIDA
            NESTES TERMOS. COMO CONSEQUÊNCIA, O BOOAT NÃO TEM QUALQUER
            RESPONSABILIDADE POR ROTAS ADOTADAS POR PRESTADORES TERCEIROS OU POR
            QUAISQUER ITENS PERDIDOS.
          </Text>
          <Text>
            AS LIMITAÇÕES E RECUSA DE GARANTIAS CONTIDAS NESTA CLÁUSULA 5 NÃO
            POSSUEM O OBJETIVO DE LIMITAR RESPONSABILIDADES OU ALTERAR DIREITOS
            DE CONSUMIDOR QUE DE ACORDO COM A LEI APLICÁVEL NÃO PODEM SER
            LIMITADOS OU ALTERADOS.
          </Text>

          <SubTitle>INDENIZAÇÃO.</SubTitle>
          <Text>
            Você concorda em indenizar e manter o Booat, seus diretores(as),
            conselheiros(as), empregados(as) e agentes isentos(as) de
            responsabilidade por todas e quaisquer reclamações, cobranças,
            prejuízos, responsabilidades e despesas (inclusive honorários
            advocatícios) decorrentes ou relacionados: (I) ao uso dos Serviços,
            de serviços ou bens obtidos por meio do uso dos Serviços; (II)
            descumprimento ou violação de qualquer disposição destes Termos;
            (III) o uso, pelo Booat, do Conteúdo de Usuário(a); ou (IV) violação
            dos direitos de terceiros, inclusive Prestadores Terceiros.
          </Text>

          <SubTitle>6. LEGISLAÇÃO APLICÁVEL; JURISDIÇÃO.</SubTitle>
          <Text>
            Estes Termos serão regidos e interpretados exclusivamente de acordo
            com as leis do Brasil. Qualquer reclamação, conflito ou controvérsia
            que surgir deste contrato ou a ele relacionada, inclusive que diga
            respeito a sua validade, interpretação ou exequibilidade, será
            solucionada exclusivamente pelos tribunais do foro de escolha do
            Booat.
          </Text>

          <SubTitle>7. OUTRAS DISPOSIÇÕES</SubTitle>

          <SubTitle>ALEGAÇÕES DE INFRINGÊNCIA DE DIREITO AUTORAL.</SubTitle>

          <Text>
            Alegações de infringência de direito autoral devem ser encaminhadas
            ao representante indicado pelo Booat. Consulte a página de Internet
            do Booat para obter o endereço indicado e outras informações.
          </Text>

          <SubTitle>AVISOS.</SubTitle>

          <Text>
            O Booat poderá enviar avisos por meio de notificações, SMS ou
            correio eletrônico para seu endereço de e-mail em sua Conta. Você
            poderá notificar o Booat por meio do Aplicativo ou comunicação pelo
            endereço eletrônico privacidade@booat.com.br.
          </Text>

          <SubTitle>DISPOSIÇÕES GERAIS.</SubTitle>

          <Text>
            Você não poderá ceder tampouco transferir estes Termos, total ou
            parcialmente, sem prévia aprovação por escrito do Booat. Você
            concede sua aprovação para que o Booat ceda e transfira estes Termos
            total ou parcialmente, inclusive: (I) para uma subsidiária ou
            afiliada; (II) um adquirente das participações acionárias, negócios
            ou bens do Booat; ou (III) para um sucessor em razão de qualquer
            operação societária. Não existe joint-venture, sociedade, emprego ou
            relação de representação entre você, O Booat ou quaisquer
            Prestadores Terceiros como resultado do contrato entre você e o
            Booat ou pelo uso dos Serviços.
          </Text>

          <Text>
            Caso qualquer disposição destes Termos seja tida como ilegal,
            inválida ou inexequível total ou parcialmente, por qualquer
            legislação, essa disposição ou parte dela será, naquela medida,
            considerada como não existente para os efeitos destes Termos, mas a
            legalidade, validade e exequibilidade das demais disposições
            contidas nestes Termos não serão afetadas. Nesse caso, as partes
            substituirão a disposição ilegal, inválida ou inexequível, ou parte
            dela, por outra que seja legal, válida e exequível e que, na máxima
            medida possível, tenha efeito similar à disposição tida como ilegal,
            inválida ou inexequível para fins de conteúdo e finalidade dos
            presentes Termos. Estes Termos constituem a totalidade do acordo e
            entendimento das partes sobre este assunto e substituem e prevalecem
            sobre todos os entendimentos e compromissos anteriores sobre este
            assunto. Nestes Termos, as palavras “inclusive” e “inclui”
            significam “incluindo, sem limitação”.
          </Text>

          <Title>POLÍTICA DE PRIVACIDADE DO BOOAT</Title>

          <Text>
            Quando você utiliza nossa aplicação, está confiando a nós suas
            informações. Sabemos que isso é uma grande responsabilidade e
            trabalhamos duro para proteger essas informações.
          </Text>

          <Text>
            Esta Política de Privacidade destina-se a ajudar você a entender
            quais informações coletamos, por que as coletamos e como você pode
            atualizar e gerenciar essas informações
          </Text>

          <Text>
            Criamos uma plataforma de serviços que ajudam diariamente milhares
            de pessoas a descobrir e interagir com a náutica de novas maneiras.
            Nossos serviços incluem: alertas monitorados em tempo real, chat,
            SOS e outros serviços.
          </Text>

          <SubTitle>
            Queremos que você entenda os tipos de informações que coletamos
            quando usa nossos serviços
          </SubTitle>

          <Text>
            Coletamos informações para fornecer serviços melhores a todos os
            nossos usuários, o que inclui descobrir informações básicas, como
            seu nome, telefone, e-mail, ou outras mais específicas como nome da
            embarcação, modelo e local de guarda. As informações coletadas pelo
            Booat e como essas informações são usadas dependem de como você usa
            nossos serviços e da necessidade em casos específicos ou de
            emergência. Quando você não está conectado a plataforma do Booat,
            armazenamos as informações que coletamos com o aplicativo ou
            dispositivo que você está usando. Isso nos ajuda a manter as
            preferências em todas as sessões de navegação, por exemplo. Quando
            você está conectado, também coletamos informações que armazenamos
            com sua localização.
          </Text>

          <Text>
            Itens que você cria ou nos fornece Ao criar uma Conta do Booat, você
            nos fornece informações pessoais que incluem seu nome e uma senha.
            Você também terá que adicionar um número de telefone ou informações
            de pagamento à sua conta. Mesmo se não estiver conectado ao Booat,
            você poderá fornecer informações, como e-mail para receber
            atualizações sobre nossos serviços. Também coletamos os dados da
            embarcação, isso nos ajuda a entender suas necessidades e oferecer
            produtos e serviços personalizados com maior eficiência. Informações
            que coletamos quando você usa nossos serviços Coletamos informações
            sobre localização, alertas emitidos, serviços solicitados. As
            informações que coletamos incluem identificadores exclusivos, tipo e
            configurações de dispositivo, sistema operacional, informações de
            rede móvel, incluindo nome e número de telefone da operadora e
            número da versão do aplicativo. Também coletamos informações sobre a
            interação de apps, navegadores e dispositivos com nossos serviços,
            incluindo endereço IP, relatórios de erros, atividade do sistema,
            além de data e hora. Coletamos essas informações quando um serviço
            do Booat no seu dispositivo entra em contato com nossos servidores,
            por exemplo, quando você emite um alerta. Sua atividade Coletamos
            informações sobre sua atividade em nossa plataforma e usamos tal
            informação para recomendar, enviar notificações e alertas em sua
            região Se você usa ou contrata nossos serviços para receber
            benefícios ou enviar e receber mensagens, podemos coletar
            informações de registro de telefonia, como o número do seu telefone,
            números encaminhados, horário e data de chamadas e mensagens,
            duração das chamadas, informações de roteamento e tipos de chamadas.
            Essas informações podem ser utilizadas ou compartilhadas com
            terceiros em casos de eminência de perigo, ou outra necessidade
            específica. Suas informações de localização Quando você usa nossos
            serviços, coletamos informações sobre sua localização, o que nos
            ajuda a oferecer apoio a navegação. Sua localização é determinada
            por GPS. POR QUE O BOOAT COLETA DADOS Usamos os dados para criar
            serviços melhores e mais seguros Usamos as informações que coletamos
            em todos nossos serviços para os fins descritos abaixo. Fornecer
            nossos serviços Usamos as informações para uma relação mais eficaz,
            como suporte a produtos, serviços, entregas, informações e resgates
            em casos de alertas e SOS Manter e melhorar nossos serviços Também
            usamos as informações para garantir que nossos serviços funcionem
            como o esperado, por exemplo, para rastrear interrupções ou
            solucionar problemas informados. E usamos as informações para
            aprimorar nossos serviços; por exemplo, entender quais termos de
            pesquisa são escritos com erros de ortografia com mais frequência
            nos ajuda a melhorar os recursos de verificação ortográfica usados
            em nossos serviços. Desenvolver novos serviços Usamos as informações
            que coletamos nos serviços existentes no desenvolvimento de outros
            serviços. Por exemplo, entender as necessidades dos usuários e
            desenvolver novas soluções adaptadas. Fornecer serviços
            personalizados, incluindo conteúdo e anúncios Usamos as informações
            que coletamos para personalizar nossos serviços, inclusive para
            fornecer recomendações, conteúdo e resultados de pesquisa
            personalizados. Por exemplo, sua localização poderá lhe indicar
            eventos ou rotas personalizadas. Dependendo das configurações,
            também podemos mostrar anúncios personalizados com base nos seus
            interesses. Avaliar o desempenho Usamos dados para análise e
            avaliação a fim de entender como nossos serviços são usados. Por
            exemplo, analisamos dados sobre suas visitas ao aplicativo para
            otimizar o design do produto. Também usamos dados sobre os anúncios
            com os quais você interage para ajudar os anunciantes a entender o
            desempenho das campanhas publicitárias. Usamos diversas ferramentas
            para fazer isso, incluindo o Google Analytics. Entrar em contato com
            você Usamos as informações que coletamos, como endereço de e-mail,
            para interagir diretamente com você. Por exemplo, podemos enviar uma
            notificação se detectarmos atividades suspeitas, como uma tentativa
            de fazer login na sua Conta do Booat. Além disso, podemos informar
            você sobre futuras alterações ou melhorias em nossos serviços. Se
            você entrar em contato com o Booat, salvaremos um registro da
            solicitação para ajudar a resolver qualquer problema que estiver
            enfrentando. Proteger e auxiliar o Booat, nossos usuários e o
            público Usamos informações para ajudar a melhorar a precisão,
            segurança e confiabilidade dos nossos serviços. Isso inclui
            detectar, prevenir e responder a abusos, riscos de segurança e
            problemas técnicos que possam prejudicar o Booat, nossos usuários ou
            o público. Podemos combinar as informações que coletamos com nossos
            serviços e nos seus dispositivos para as finalidades descritas
            acima. Por exemplo, se você pesquisar “motor” no buscador, poderá
            ver uma listagem de determinadas empresas que utilizam nosso produto
            de anúncios Solicitaremos sua autorização antes de usar informações
            para fins que não estiverem abordados nesta Política de Privacidade.
            Gerenciar, analisar e atualizar suas informações Quando você está
            conectado, pode editar e atualizar informações visitando o menu. Por
            exemplo, o menu “Embarcação” foi projetado para ajudar você a
            gerenciar os dados de sua embarcação, como o nome, local de guarda e
            outras informações Também criamos um local para você analisar e
            controlar as informações de contato pessoal em “perfil”. Quando o
            Booat compartilha as informações Não compartilhamos informações
            pessoais com empresas, organizações ou indivíduos externos ao Booat,
            exceto nos casos descritos abaixo: Com sua autorização
            Compartilharemos informações pessoais fora do Booat quando tivermos
            seu consentimento. Por exemplo, se você solicitar produto ou serviço
            de empresas parceiras do Booat, pediremos sua autorização antes de
            compartilhar seu nome ou número de telefone. Solicitaremos seu
            consentimento explícito para compartilhar quaisquer informações
            pessoais e/ou de atendimento, como por exemplo a aceitação dos
            termos de uso. Para processamento externo Fornecemos informações
            pessoais e/ou de atendimento às nossas afiliadas ou outras empresas
            ou pessoas confiáveis para processar tais informações por nós, de
            acordo com nossas instruções e em conformidade com nossa Política de
            Privacidade e quaisquer outras medidas de segurança e de
            confidencialidade adequadas. Por motivos legais Compartilharemos
            informações pessoais fora do Booat se acreditarmos, de boa-fé, que o
            acesso, o uso, a conservação ou a divulgação das informações sejam
            razoavelmente necessários para: • Cumprir qualquer legislação,
            regulação, processo legal ou solicitação governamental aplicável.
            Compartilhamos informações sobre o número e o tipo de solicitações
            que recebemos dos governos; • Cumprir Termos de Serviço aplicáveis,
            inclusive investigação de possíveis violações; • Detectar, impedir
            ou lidar de alguma forma com fraudes, problemas técnicos ou de
            segurança; • Proteger de prejuízos aos direitos, à propriedade ou à
            segurança do Booat, dos nossos usuários ou do público, conforme
            solicitado ou permitido por lei. Podemos compartilhar informações de
            identificação não pessoal publicamente e com nossos parceiros – como
            editores, anunciantes, desenvolvedores ou detentores de direitos.
            Por exemplo, compartilhamos informações publicamente para mostrar
            tendências sobre o uso geral dos nossos serviços. Também permitimos
            que parceiros específicos coletem informações do seu dispositivo
            para fins de publicidade e medição usando os próprios cookies ou
            tecnologias semelhantes. Se o Booat for envolvido em uma fusão,
            aquisição ou venda de ativos, continuaremos a garantir a
            confidencialidade das suas informações pessoais e avisaremos os
            usuários afetados antes que as informações pessoais sejam
            transferidas ou submetidas a uma política de privacidade diferente.
            Incorporamos segurança nos nossos serviços para proteger suas
            informações Todos os produtos do Booat são desenvolvidos com
            recursos de segurança sólidos que protegem continuamente suas
            informações. Os insights que recebemos na manutenção dos nossos
            serviços nos ajudam a detectar e bloquear automaticamente ameaças de
            segurança que chegam até você. E se detectarmos algo perigoso e
            digno de notificação, notificaremos e orientaremos você sobre as
            etapas necessárias para se manter em segurança. Trabalhamos com
            afinco para proteger você e o Booat de acesso, alteração, divulgação
            ou destruição não autorizada das informações que detemos, o que
            inclui: • A utilização de criptografia para manter os seus dados
            privados enquanto estão em trânsito; Transferências de dados Temos
            servidores em todo o mundo e suas informações podem ser processadas
            em servidores localizados fora do país em que você vive. As leis de
            proteção de dados variam de acordo com o país, sendo que algumas
            oferecem mais proteção que outras. Independentemente do local onde
            suas informações são processadas, aplicamos as mesmas proteções
            descritas nesta política. Também atuamos em conformidade com
            determinadas estruturas legais relacionadas à transferência de
            dados, como as estruturas Privacy Shields entre União Europeia e
            Estados Unidos e Suíça e Estados Unidos. Quando recebemos
            reclamações formais por escrito, respondemos entrando em contato com
            o requerente. Trabalhamos com as autoridades reguladoras
            apropriadas, inclusive autoridades locais de proteção de dados, para
            resolver quaisquer reclamações referentes à transferência de dados
            que não possamos resolver diretamente com você. Quando esta política
            se aplica Esta Política de Privacidade se aplica a todos os serviços
            oferecidos pelo Booat. Esta Política de Privacidade não se aplica a
            produtos ou serviços que tenham políticas de privacidade separadas
            Esta Política de Privacidade não se aplica a: • Práticas de coleta
            de informações de outras empresas e organizações que anunciam em
            nossa plataforma; • Serviços oferecidos por outras empresas ou
            indivíduos, inclusive produtos ou sites que podem incluir serviços
            do Booat, que são exibidos para você ou tenham links em nossos
            serviços. Alterações nesta política Alteramos esta Política de
            Privacidade periodicamente. Nós não reduziremos seus direitos nesta
            Política de Privacidade sem seu consentimento explícito. Indicamos
            sempre a data em que as últimas alterações foram publicadas e
            oferecemos acesso às versões arquivadas para sua análise. Se as
            alterações forem significativas, forneceremos um aviso com mais
            destaque, o que inclui, no caso de alguns serviços, notificação por
            e-mail das alterações da Política de Privacidade.
          </Text>
        </FormLogin>
      </SectionForm>

      <Footer />
    </Main>
  );
};
