/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction } from 'react';
import {
  ButtonMarina,
  CloseMarina,
  ImageClosed,
  ModalMarina,
  Options,
  OptionsImage,
  SectionOption,
} from './_serviceOptions';

import Close from '../../../../assets/icons/closeMarina.png';
import Lapis from '../../../../assets/icons/lapis.png';
import Check from '../../../../assets/icons/check.svg';

import Tomada from '../../../../assets/img/tomada.png';
import Seguranca from '../../../../assets/img/seguranca.png';
import Chuveiro from '../../../../assets/img/chuveiro.png';
import Calendario from '../../../../assets/img/calendario.png';
import Loja from '../../../../assets/img/loja.png';
import Talher from '../../../../assets/img/talher.png';
import Helicoptero from '../../../../assets/img/helicoptero.png';
import Combustivel from '../../../../assets/img/combustivel.png';
import Tempo from '../../../../assets/img/tempo.png';
import Gota from '../../../../assets/img/gota.png';

interface Props {
  showModal: boolean;
  listServices: Services[];
  service: Array<any>;
  category: string;
  setServices: Dispatch<SetStateAction<Array<any>>>;
  setIsVisibleMarina: Dispatch<SetStateAction<boolean>>;
}

export interface Services {
  id: string;
  name: string;
  describe: string;
  created_at: string;
  icon_url: string;
}

export const ServiceOptions = ({
  showModal,
  listServices,
  service,
  category,
  setServices,
  setIsVisibleMarina,
}: Props) => {
  const renderImageService = (value: string) => {
    switch (value) {
      case 'energy':
        return Tomada;
        break;
      case 'water':
        return Gota;
        break;
      case 'station':
        return Combustivel;
        break;
      case 'helicopter':
        return Helicoptero;
        break;
      case 'restaurant':
        return Talher;
        break;
      case 'security':
        return Seguranca;
        break;
      case 'hours':
        return Tempo;
        break;
      case 'hotel':
        return Calendario;
        break;
      case 'stores':
        return Loja;
        break;
      case 'bathroom':
        return Chuveiro;
        break;

      default:
        break;
    }
  };

  const handleAddServices = (val: string) => {
    const value = service.indexOf(val);

    if (value !== -1) {
      if (value > -1) {
        const lista = [...service];

        lista.splice(value, 1);
        setServices(lista);
      }
    } else {
      setServices([...service, val]);
    }
  };

  const renderOptionsServices = () => {
    if (listServices) {
      return listServices.map((item, index) => (
        <OptionsImage
          key={+index}
          src={renderImageService(item.name)}
          alt={item.describe}
          onClick={() => handleAddServices(item.id)}
          option={service.indexOf(item.id) !== -1}
        />
      ));
    }
  };

  const renderShowServices = () => {
    if (listServices) {
      return listServices.map(
        (item, key) =>
          service.find(index => index === item.id) && (
            <div style={{ position: 'relative' }}>
              <OptionsImage
                key={+key}
                src={renderImageService(item.name)}
                alt={item.describe}
                option={service.indexOf(item.id) !== -1}
              />
              <img
                src={Check}
                alt="Icon check"
                style={{
                  position: 'absolute',
                  top: '3px',
                  right: '5px',
                }}
              />
            </div>
          ),
      );
    }
  };

  const renderMarina = () => {
    if (category === 'Marina') {
      if (showModal) {
        return (
          <>
            <ModalMarina>
              <CloseMarina>
                <ImageClosed
                  src={Close}
                  alt="Icon Close Marina"
                  onClick={() => setIsVisibleMarina(false)}
                />
              </CloseMarina>
              <Options>{renderOptionsServices()}</Options>
            </ModalMarina>
            <ButtonMarina onClick={() => setIsVisibleMarina(false)}>
              <img src={Lapis} alt="Icon Lapis" width={14} />
              Salvar
            </ButtonMarina>
          </>
        );
      }
      return (
        <>
          <p>O que a Marina oferece</p>
          <ButtonMarina onClick={() => setIsVisibleMarina(true)}>
            Adicionar
          </ButtonMarina>
          <Options
            style={{ justifyContent: 'flex-start', padding: '0px 25px' }}
          >
            {renderShowServices()}
          </Options>
        </>
      );
    }
  };

  return <SectionOption>{renderMarina()}</SectionOption>;
};
