/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Email,
  ImageProfile,
  ModalUser,
  Profile,
  Title,
  ForgotPassword,
  Input,
  ContainerInput,
  ContainerButton,
  ButtonPass,
  ContainerModal,
} from './styles';
import api from '../../../services/api';
import Logo from '../../../assets/img/booatLogo.png';
import { Modal } from '../../../shared/Modal/Modal';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export interface Users {
  id: string;
  name: string;
  email: string;
  cpf: string;
  password: string;
  avatar: null;
  role: string;
  created_at: string;
  updated_at: string;
}

export const ModalUserOptions = ({ setOpenModal }: Props) => {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
  const [user, setUser] = useState<Users>();
  const history = useHistory();
  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const notifySuccess = (message: string) =>
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const handleLogin = () => {
    history.push('/');
    document.location.reload();
  };

  const handleLogout = () => {
    localStorage.clear();

    handleLogin();
  };

  const setLocalStorageUser = () => {
    const dataUser = localStorage.getItem('@Booat: user');

    if (dataUser) {
      setUser(JSON.parse(dataUser));
    }
  };

  useEffect(() => {
    setLocalStorageUser();
  }, []);

  const handleClear = () => {
    setOldPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
  };

  const postApiUpdate = async () => {
    try {
      await api.post('/password/change', {
        oldPassword,
        newPassword,
      });

      notifySuccess('Senha alterada com sucesso!');
      handleClear();
      setModalIsVisible(false);
      setOpenModal(false);
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  const handleUpdate = () => {
    if (oldPassword !== '' || newPassword !== '' || newPasswordConfirm !== '') {
      if (oldPassword !== newPassword) {
        if (newPassword === newPasswordConfirm) {
          postApiUpdate();
        } else {
          notifyError('confirmação de senha não confere!');
        }
      } else {
        notifyError('Nova senha não pode ser a mesma da atual!');
      }
    } else {
      notifyError('Todos os campos devem ser preenchidos!');
    }
  };

  const handleClosed = () => {
    setModalIsVisible(false);
    handleClear();
  };

  return (
    <>
      <ModalUser>
        <Profile>
          <ImageProfile
            src={user && user.avatar ? user?.avatar : Logo}
            alt="Imagem User Profile"
          />
          <Title>{user ? user.name : 'Master'}</Title>
          <Email>{user ? user.email : 'master@booat.com'}</Email>
        </Profile>
        <Button onClick={() => setModalIsVisible(!modalIsVisible)}>
          Alterar senha
        </Button>
        <Button onClick={() => handleLogout()}>Logout</Button>
      </ModalUser>
      {modalIsVisible && (
        <Modal>
          <ContainerModal>
            <div>
              <ForgotPassword>
                <Title>Alterar Senha</Title>
              </ForgotPassword>
              <ContainerInput>
                <Input
                  value={oldPassword}
                  placeholder="Senha atual"
                  type="password"
                  onChange={e => setOldPassword(e.target.value)}
                />
                <Input
                  value={newPassword}
                  placeholder="Digite uma nova senha"
                  type="password"
                  onChange={e => setNewPassword(e.target.value)}
                />
                <Input
                  value={newPasswordConfirm}
                  placeholder="Digite novamente a nova senha"
                  type="password"
                  onChange={e => setNewPasswordConfirm(e.target.value)}
                />
              </ContainerInput>
            </div>
            <ContainerButton>
              <ButtonPass option onClick={() => handleClosed()}>
                Cancelar
              </ButtonPass>
              <ButtonPass onClick={() => handleUpdate()}>
                Alterar Senha
              </ButtonPass>
            </ContainerButton>
          </ContainerModal>
        </Modal>
      )}
    </>
  );
};
