import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Home } from '../pages/Home/Home';
import { Login } from '../pages/Login/Login';
import { MapsPage } from '../pages/MapsPage/MapsPage';
import { Terms } from '../pages/Terms/Terms';
import PrivateRoute from './routesPrivate';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/landing" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/" component={Login} />
      <Route exact path="/terms" component={Terms} />
      <PrivateRoute path="/maps/:id?" component={MapsPage} />
    </Switch>
    <ToastContainer />
  </BrowserRouter>
);

export default Routes;
