import styled from 'styled-components';

import ImagemBackground from '../../assets/img/BG-blue.png';
import ImagemCelular from '../../assets/img/BG-Cell.png';

export const Main = styled.div`
  width: 100%;
  background-image: url(${ImagemBackground});
  background-repeat: no-repeat;
  background-size: 100vw 100vh;

  @media only screen and (max-width: 600px) {
    width: 100vw;
    background-image: url(${ImagemCelular});
    background-size: 100vw 70vh;
  }
`;
