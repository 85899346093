import { Dispatch, SetStateAction } from 'react';
import { MdDelete, MdDraw, MdUndo } from 'react-icons/md';
import { Container, IconButton, StyledIcon } from './styles';

import CancelIcon from '../../../../assets/img/cancel-icon.svg';
import ConfirmIcon from '../../../../assets/img/confirm-icon.svg';
import handleError from '../../../../utils/handleError';
import api from '../../../../services/api';

interface Props {
  setIsDrawing: Dispatch<SetStateAction<boolean>>;
  setPath: Dispatch<SetStateAction<google.maps.LatLng[]>>;
  isDrawing: boolean;
  paths: google.maps.LatLng[];
  time: string;
  velocity: string;
  setTime: Dispatch<SetStateAction<string>>;
  setVelocity: Dispatch<SetStateAction<string>>;
  setNameOrigin: Dispatch<SetStateAction<string>>;
  setNameDestiny: Dispatch<SetStateAction<string>>;
  nameOrigin: string;
  nameDestiny: string;
}

export const ModalDraw: React.FC<Props> = ({
  isDrawing,
  setIsDrawing,
  setPath,
  paths,
  time,
  velocity,
  nameOrigin,
  nameDestiny,
  setVelocity,
  setTime,
  setNameOrigin,
  setNameDestiny,
}) => {
  const handleConfirm = async () => {
    try {
      const points = paths.map(point => ({
        latitude: point.lat(),
        longitude: point.lng(),
      }));

      api.post('routes', {
        name_origin: nameOrigin === '' ? undefined : nameOrigin,
        name_destiny: nameDestiny === '' ? undefined : nameDestiny,
        velocity: velocity === '' ? undefined : velocity,
        time: time === '' ? undefined : time,
        points,
      });

      handleError('Rota criada com sucesso!', 'success');

      setNameOrigin('');
      setNameDestiny('');
      setVelocity('');
      setTime('');
      setPath([]);
      setIsDrawing(false);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  return (
    <Container isOpen={isDrawing}>
      {isDrawing && (
        <StyledIcon
          src={CancelIcon}
          style={{ left: -50 }}
          onClick={() => {
            setPath([]);
            setIsDrawing(prev => !prev);
          }}
        />
      )}

      {isDrawing && (
        <>
          <IconButton
            type="button"
            onClick={() => setPath(prev => prev.slice(0, prev.length - 1))}
          >
            <MdUndo />
          </IconButton>
          <IconButton type="button" onClick={() => setPath([])}>
            <MdDelete />
          </IconButton>
        </>
      )}

      {!isDrawing && (
        <IconButton type="button" onClick={() => setIsDrawing(prev => !prev)}>
          <MdDraw />
        </IconButton>
      )}

      {isDrawing && (
        <StyledIcon
          src={ConfirmIcon}
          style={{ left: 80 }}
          onClick={handleConfirm}
        />
      )}
    </Container>
  );
};
