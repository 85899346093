/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, PaginationItem } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import DeleteIcon from '../../../assets/icons/adminDelete.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import { IUser } from '../../../interfaces/User';
import api from '../../../services/api';
import handleError from '../../../utils/handleError';
import { ModalUserDetails } from '../ModalUserDetails/ModalUserDetails';
import { ModalConfirm } from '../../../shared/ModalConfirm/ModalConfirm';
import { MultiRoutes } from '../../../pages/MapsPage/interface';
import SearchIcon from '../../../assets/icons/userSearch.svg';
import { Paginated } from '../../../interfaces/Paginated';
import { IRoutes } from '../../../interfaces/RoutesList';
import {
  Button,
  ButtonsContainer,
  Close,
  CloseButton,
  Container,
  Content,
  Icon,
  IconButton,
  Input,
  ModalHeader,
  PaginationWrapper,
  RowContainer,
  Table,
  TableWrapper,
  Title,
} from './styles';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setPathObj: Dispatch<SetStateAction<MultiRoutes[]>>;
  pathObj: MultiRoutes[];
}

export const ModalRoutes: React.FC<Props> = ({
  setOpenModal,
  setPathObj,
  pathObj,
}) => {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [routesToDelete, setRoutesToDelete] = useState<IRoutes>({} as IRoutes);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [routesList, setRoutesList] = useState<IRoutes[]>([]);
  const [page, setPage] = useState(1);
  const [maximumPage, setMaximumPage] = useState(3);
  const [search, setSearch] = useState('');

  const getRoutes = async () => {
    try {
      const { data } = await api.get<Paginated<IRoutes>>(
        `/routes/paged/${page}`,
      );

      setRoutesList(data.results);
      setMaximumPage(Math.ceil(data.total / 6));
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    getRoutes();
  }, [page]);

  const handleSearch = async () => {
    if (!search) {
      getRoutes();
      return;
    }

    try {
      const { data } = await api.get<IRoutes[]>(`/routes/origin/${search}`);

      setRoutesList(data);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const handleDeleteUser = (item: IRoutes) => {
    setModalConfirm(true);
    setRoutesToDelete(item);
  };

  const confirmDeleteUser = async () => {
    setModalConfirm(false);
    setRoutesToDelete({} as IRoutes);

    try {
      await api.delete(`/routes/${routesToDelete.id}`);

      handleError('Rota deletada com sucesso!', 'success');
      handleRemoveRoutes(routesToDelete);
      getRoutes();
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const handleAddRoutes = (item: IRoutes) => {
    const position = item.latitude.map((lat: number, index: number) => {
      return new google.maps.LatLng(lat, item.longitude[index]);
    });

    setPathObj(
      pathObj.concat({
        position,
      }),
    );
  };

  const handleRemoveRoutes = (item: IRoutes) => {
    const position = item.latitude.map((lat: number, index: number) => {
      return new google.maps.LatLng(lat, item.longitude[index]);
    });

    const newRoutes = pathObj.filter(route => {
      return route.position[0].lat() !== position[0].lat();
    });

    setPathObj(newRoutes);
  };

  const verifyIfRoutesExists = (item: IRoutes) => {
    const routes = pathObj.map(route => {
      return route.position[0];
    });

    const newRoutes = item.latitude.map((lat: number, index: number) => {
      return new google.maps.LatLng(lat, item.longitude[index]);
    });

    const result = routes.some(route => {
      return newRoutes.some(newRoute => {
        return route.lat() === newRoute.lat() && route.lng() === newRoute.lng();
      });
    });

    return result;
  };

  const renderRoutes = (route: IRoutes) => {
    if (verifyIfRoutesExists(route)) {
      handleRemoveRoutes(route);
      return;
    }
    handleAddRoutes(route);
  };

  const renderEyeIcon = (bool: boolean) => {
    if (bool) {
      return <FaEye color="#77a0c0" size={28} />;
    }
    return <FaEyeSlash color="#77a0c0" size={28} />;
  };

  return (
    <Container>
      <Content>
        <ModalHeader>
          <Title>Listagem de rotas</Title>

          <CloseButton onClick={() => setOpenModal(false)}>
            <Close src={CloseIcon} />
          </CloseButton>
        </ModalHeader>

        <RowContainer>
          <Input
            placeholder="Buscar por origem"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <Button type="button" onClick={handleSearch}>
            Buscar
            <Icon src={SearchIcon} />
          </Button>
        </RowContainer>

        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Nº</th>
                <th>Origem</th>
                <th>Destino</th>
                <th>Velocidade</th>
                <th>Tempo</th>
              </tr>
            </thead>

            <tbody>
              {routesList.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{`${item.name_origin}, ${item.origin}`}</td>
                  <td>{`${item.name_destiny}, ${item.destiny}`}</td>
                  <td>{item.velocity}</td>
                  <td>{item.time}</td>

                  <td>
                    <ButtonsContainer>
                      <IconButton
                        type="button"
                        onClick={() => renderRoutes(item)}
                      >
                        {renderEyeIcon(verifyIfRoutesExists(item))}
                      </IconButton>

                      <IconButton
                        type="button"
                        onClick={() => handleDeleteUser(item)}
                      >
                        <Icon src={DeleteIcon} />
                      </IconButton>
                    </ButtonsContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <PaginationWrapper>
          <Pagination
            page={page}
            count={maximumPage}
            siblingCount={1}
            onChange={(e, _page) => setPage(_page)}
            hidePrevButton={page === 1}
            hideNextButton={page === maximumPage}
            renderItem={item => {
              return (
                <PaginationItem
                  {...item}
                  style={{
                    color: item.page === page ? '#4793CE' : '#e5e5e5',
                    background: 'transparent',
                    fontSize: '1rem',
                    fontWeight: 500,
                    margin: 0,
                    padding: '0.125rem',
                    minWidth: '1rem',
                  }}
                  classes={{
                    icon: 'icon',
                  }}
                />
              );
            }}
          />
        </PaginationWrapper>
      </Content>

      {modalConfirm && (
        <ModalConfirm
          onCancel={() => setModalConfirm(false)}
          onConfirm={confirmDeleteUser}
          title="Tem certeza que deseja excluir essa rota?"
        />
      )}

      {selectedUser && (
        <ModalUserDetails
          user={selectedUser}
          onClose={() => setSelectedUser(undefined)}
        />
      )}
    </Container>
  );
};
