import styled from 'styled-components';
import pixel from '../../utils/size';

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: ${pixel(28)};
  left: ${pixel(26)};
  border-radius: ${pixel(6)};
  background-color: #ffffffc2;
  width: ${pixel(265)};
  height: auto;

  @media only screen and (max-width: 600px) {
    position: absolute;
    z-index: 1;
    top: 35px;
    left: 35px;
    border-radius: 10px;
    background-color: #ffffffc2;
    width: 290px;
    height: auto;
  }
`;

export const Response = styled.div`
  width: 100%;
  margin-top: ${pixel(45)};

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 60px;
  }
`;

export const Address = styled.div`
  padding: ${pixel(6)} ${pixel(15)};
  display: flex;
  flex-direction: row;
  gap: ${pixel(6)};
  cursor: pointer;
  font-size: ${pixel(12)};

  img {
    width: ${pixel(8)};
  }

  &:hover {
    background-color: #ffffffb7;
  }

  @media only screen and (max-width: 600px) {
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
  }
`;
