/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-await */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ContainerModal,
  ContainerTitle,
  Title,
  Input,
  Button,
  ContainerButton,
  ContainerRow,
  Select,
  Option,
  AreaText,
  Section,
  Label,
  CheckItem,
  CheckName,
  Toggle,
} from './_modalEstablichment';
import { ServiceOptions } from './Components/ServiceOptions/ServiceOptions';
import { BusinessHours } from './Components/BusinessHours/BusinessHours';
import handleError from '../../utils/handleError';
import api from '../../services/api';
import Address from './Components/Address/Address';
import {
  IEstablishmentImages,
  NewUploadImages,
} from './Components/NewUploadImages/NewUploadImages';
import { ContactOptions } from './Components/ContactOptions/ContactOptions';
import {
  IContactForm,
  PropsTime,
  ResponseServices,
  Services,
} from './interface';
import { Establishment } from '../../pages/MapsPage/interface';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIdEst: Dispatch<SetStateAction<string>>;
  id: string | any;
}

export const ModalEstablishment = ({ setOpenModal, id, setIdEst }: Props) => {
  const [name, setName] = useState<string>('');
  const [logotype, setLogotype] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [lowCost, setLowCost] = useState<boolean>(false);

  const [adress, setAdress] = useState<string>('');
  const [endere, setEndere] = useState<string>('');

  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');

  const [describe, setDescribe] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [youtube, setYoutube] = useState<string>('');
  const [facebook, setFacebook] = useState<string>('');
  const [instagram, setInstagram] = useState<string>('');
  const [site, setSite] = useState<string>('');
  const [, setImages] = useState<string>('');
  const [service, setServices] = useState([] as Array<any>);
  const [listImages, setListImages] = useState([] as Array<any>);
  const [testImages, setTestImages] = useState([] as Array<any>);
  const [, setIdImages] = useState([] as Array<any>);

  const [weekDay, setWeekDay] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [isVisibleHours, setIsVisibleHours] = useState<boolean>(false);
  const [business, setBusiness] = useState<PropsTime[]>([]);

  const [listServices, setListServices] = useState<Services[]>([]);
  const [isVisibleMarina, setIsVisibleMarina] = useState<boolean>(false);

  const [hasContacts, setHasContacts] = useState(false);
  const [contacts, setContacts] = useState<IContactForm[]>([]);

  const [establishmentImages, setEstablishmentImages] = useState(
    {} as IEstablishmentImages,
  );

  const notify = (message: string) =>
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const notifyError = (message: string) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
    });

  const fetchApiServices = async () => {
    try {
      const { data } = await api.get<ResponseServices>(
        '/establishment/service',
      );

      setListServices(data.results);
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchApiServices();
  }, []);

  const handleSetStates = (data: Establishment) => {
    if (data) {
      setName(data.name ?? '');
      setLowCost(data.is_low_cost || false);
      setCategory(data.category ?? '');
      setAdress(data.address ?? '');
      setLatitude(data.latitude ?? '');
      setLongitude(data.longitude ?? '');
      setDescribe(data.describe ?? '');
      setTelephone(data.telephone ?? '');
      setYoutube(data.youtube ?? '');
      setFacebook(data.facebook ?? '');
      setInstagram(data.instagram ?? '');
      setSite(data.site ?? '');
      setListImages(data.images ?? []);
      setTestImages(data.images.map(item => item.img_url) ?? []);
      setIdImages(data.images.map(item => item.id) ?? []);
      setHasContacts(data.has_contacts ?? false);
      setContacts(
        data?.contacts.map(contact => ({
          type: contact.type,
          email: contact.email,
          telephone: contact.telephone,
          secondary_telephone: contact.secondary_telephone || undefined,
          secondary_email: contact.secondary_email || undefined,
          tertiary_telephone: contact.tertiary_telephone || undefined,
        })) || [],
      );

      const firstImage = data.images.find(image => image.position === 1);
      const secondImage = data.images.find(image => image.position === 2);
      const thirdImage = data.images.find(image => image.position === 3);
      const fourthImage = data.images.find(image => image.position === 4);

      setEstablishmentImages({
        banner: {
          url: data?.banner_url || undefined,
        },
        image1: {
          url: firstImage?.img_url,
          id: firstImage?.id,
        },
        image2: {
          url: secondImage?.img_url,
          id: secondImage?.id,
        },
        image3: {
          url: thirdImage?.img_url,
          id: thirdImage?.id,
        },
        image4: {
          url: fourthImage?.img_url,
          id: fourthImage?.id,
        },
      });

      if (data.services) {
        const lista = [...service];
        data.services.map((item: any) => lista.push(item.id));
        setServices(lista);
      }

      if (data.business_days) {
        const lista = [...business];
        data.business_days.map(item =>
          lista.push({
            idHours: item.id,
            weekDay: item.week_day,
            startTime: item.start_time,
            endTime: item.end_time,
          }),
        );
        setBusiness(lista);
      }
    }
  };

  const fetchApiData = async (val: string) => {
    try {
      const { data } = await api.get<Establishment>(`/establishment/${val}`);

      handleSetStates(data);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    if (id !== '') fetchApiData(id);
  }, [id]);

  const handleClearStates = () => {
    setName('');
    setLogotype('');
    setCategory('');
    setLowCost(false);
    setAdress('');
    setLatitude('');
    setLongitude('');
    setDescribe('');
    setTelephone('');
    setYoutube('');
    setFacebook('');
    setInstagram('');
    setSite('');
    setImages('');
    setServices([]);
    setListImages([]);
    setTestImages([]);
    setIdImages([]);
  };

  const UpdateApiEstablishment = async () => {
    try {
      business.map(async item => {
        await api.post('/establishment/days', {
          days: [
            {
              weekDay: item.weekDay,
              startTime: item.startTime,
              endTime: item.endTime,
            },
          ],
          establishmentId: id,
        });
      });

      const { data } = await api.put(`/establishment/${id}`, {
        name,
        category,
        latitude,
        longitude,
        address: adress,
        describe,
        telephone,
        youtube,
        facebook,
        instagram,
        site,
        has_contacts: hasContacts,
        contacts,
        is_low_cost: lowCost,
        services: service,
      });

      notify('Alteração realizada com sucesso!');
      handleClearStates();
      setOpenModal(false);
      setIdEst(data.id);
      fetchApiData(data.id);
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  const createEstablishment = async () => {
    try {
      const param = new FormData();

      param.append('name', name);
      param.append('logotype', logotype);
      param.append('category', category);
      param.append('latitude', latitude);
      param.append('longitude', longitude);
      param.append('address', endere);
      param.append('describe', describe);
      param.append('telephone', telephone);
      param.append('youtube', youtube);
      param.append('facebook', facebook);
      param.append('instagram', instagram);
      param.append('site', site);
      param.append('is_low_cost', lowCost.toString());
      param.append('services', service.toString());
      param.append('has_contacts', hasContacts.toString());

      if (listImages) listImages.map(item => param.append('images', item));

      if (establishmentImages) {
        Object.entries(establishmentImages).map(([key, value]) => {
          if (value?.file) {
            param.append(key, value.file);
          }
        });
      }

      if (hasContacts && contacts.length > 0) {
        contacts.forEach((contact, index) => {
          Object.entries(contact).map(([key, value]) => {
            if (value) {
              param.append(`contacts[${index}][${key}]`, value.toString());
            }
          });
        });
      }

      const { data } = await api.post<Establishment>('/establishment', param);

      if (business) {
        business.map(
          async item =>
            await api.post('/establishment/days', {
              days: [
                {
                  weekDay: item.weekDay,
                  startTime: item.startTime,
                  endTime: item.endTime,
                },
              ],
              establishmentId: data.id,
            }),
        );
      }

      notify('Cadastro realizado com sucesso!');

      setOpenModal(false);
      setIdEst(data.id);

      handleClearStates();
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  const handleErro = (message: string) => {
    return notifyError(`${message} é obrigatório!`);
  };

  const maskPhone = (value: string) => {
    if (value.length > 10) {
      return value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2})(\d{5})(\d{4})$.*/, '($1) $2-$3');
    }

    return value
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{2})(\d{4})(\d{4})$.*/, '($1) $2-$3');
  };

  const handleCadaster = () => {
    if (name === '') return handleErro('Nome');
    if (category === '') return handleErro('Categoria');

    if (category === 'Marina') {
      if (service.length === 0) return handleErro('Serviço na Marina');
    }

    if (adress === '') return handleErro('Endereço');
    if (business.length === 0) return handleErro('Horário de Funcionamento');
    if (testImages.length > 4)
      return notifyError('Número máximo de imagens do estabelecimento é 4');

    if (hasContacts) {
      if (
        contacts.length === 0 ||
        !contacts.find(contact => contact.type === 'Exit')
      ) {
        return notifyError('É necessário preencher o aviso de saída');
      }
    }

    if (id) UpdateApiEstablishment();
    else createEstablishment();
  };

  const handleFileLogotype = (e: any) => {
    const file = e.target.files[0];
    return file;
  };

  return (
    <ContainerModal key="02">
      <Section>
        <ContainerTitle>
          <Title>Cadastrar Estabelecimento</Title>
        </ContainerTitle>

        <ContainerRow>
          <Input
            value={name}
            placeholder="Nome do Estabelecimento"
            type="text"
            onChange={e => setName(e.target.value)}
          />
          <Label htmlFor="arquivo">
            {logotype ? `Logomarca adicionada` : 'Adicione a logomarca'}
          </Label>
          <input
            type="file"
            name="arquivo"
            id="arquivo"
            accept="image/x-png,image/jpeg"
            onChange={e => setLogotype(handleFileLogotype(e))}
          />
        </ContainerRow>
        <ContainerRow>
          <Select onChange={e => setCategory(e.target.value)} value={category}>
            <Option value="" disabled selected>
              Categoria
            </Option>
            <Option value="Posto de Combustível">Posto de Combustível</Option>
            <Option value="Loja">Loja</Option>
            <Option value="Artigos em Inox">Artigos em Inox</Option>
            <Option value="Elétrica Naval">Elétrica Naval</Option>
            <Option value="Marina">Marina</Option>
            <Option value="Mercado">Mercado</Option>
            <Option value="Salvatagem">Salvatagem</Option>
            <Option value="Manutenção">Manutenção</Option>
            <Option value="Restaurante">Restaurante</Option>
            <Option value="Entretenimento">Entretenimento</Option>
          </Select>
        </ContainerRow>

        {category === 'Marina' && (
          <ContainerRow>
            <ContactOptions
              contacts={contacts}
              setContacts={setContacts}
              hasContacts={hasContacts}
              setHasContacts={setHasContacts}
              establishmentId={id}
            />
          </ContainerRow>
        )}

        <ContainerRow>
          <AreaText
            placeholder="Descreva, brevemente, o estabelecimento."
            onChange={e => setDescribe(e.target.value)}
            value={describe}
          />
        </ContainerRow>

        {category === 'Marina' ? (
          <ServiceOptions
            service={service}
            listServices={listServices}
            setIsVisibleMarina={setIsVisibleMarina}
            showModal={isVisibleMarina}
            setServices={setServices}
            category={category}
          />
        ) : null}

        <Address
          adress={adress}
          setAdress={setAdress}
          latitude={latitude}
          longitude={longitude}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setEndere={setEndere}
        />

        <ContainerRow>
          <Input
            placeholder="Telefone"
            value={telephone}
            onChange={e => setTelephone(maskPhone(e.target.value))}
            maxLength={15}
          />
        </ContainerRow>

        <ContainerRow>
          <CheckItem>
            <CheckName>Baixo custo</CheckName>
            <Toggle
              type="checkbox"
              checked={lowCost}
              onChange={() => setLowCost(!lowCost)}
            />
          </CheckItem>
        </ContainerRow>

        <BusinessHours
          business={business}
          endTime={endTime}
          isVisibleHours={isVisibleHours}
          setBusiness={setBusiness}
          setEndTime={setEndTime}
          setIsVisibleHours={setIsVisibleHours}
          setStartTime={setStartTime}
          setWeekDay={setWeekDay}
          startTime={startTime}
          weekDay={weekDay}
        />

        <ContainerRow>
          <Input
            placeholder="Instagram"
            onChange={e => setInstagram(e.target.value)}
            value={instagram}
          />
          <Input
            placeholder="Facebook"
            onChange={e => setFacebook(e.target.value)}
            value={facebook}
          />
          <Input
            placeholder="YouTube"
            onChange={e => setYoutube(e.target.value)}
            value={youtube}
          />
          <Input
            placeholder="Site"
            onChange={e => setSite(e.target.value)}
            value={site}
          />
        </ContainerRow>

        <NewUploadImages
          hasBanner={category === 'Entretenimento'}
          setEstablishmentImages={setEstablishmentImages}
          establishmentImages={establishmentImages}
        />
      </Section>

      <ContainerButton>
        <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
        <Button option onClick={() => handleCadaster()}>
          {id ? 'Alterar' : 'Cadastrar'}
        </Button>
      </ContainerButton>
    </ContainerModal>
  );
};
