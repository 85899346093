import IconTempestade from '../assets/img/alerts/tempestade.png';
import IconNoSeguro from '../assets/img/alerts/noSeguro.png';
import Alerta from '../assets/img/alerts/alerta.png';
import IconAreia from '../assets/img/alerts/areia.png';
import IconChuva from '../assets/img/alerts/chuva.png';
import IconFiscal from '../assets/img/alerts/fiscal.png';
import IconRocha from '../assets/img/alerts/rocha.png';
import IconSeguro from '../assets/img/alerts/seguro.png';
import Nublado from '../assets/img/alerts/nublado.png';
import Sos from '../assets/img/alerts/Sos.png';
import IconSupermarket from '../assets/img/establishment/iconSupermarket.svg';
import IconManutencao from '../assets/img/establishment/iconManutecao.svg';
import Restaurante from '../assets/img/establishment/Restaurante.png';
import IconLoja from '../assets/img/establishment/iconLoja.svg';
import Combustivel from '../assets/img/establishment/posto.svg';
import IconInox from '../assets/img/establishment/inox.svg';
import IconNaval from '../assets/img/establishment/naval.svg';
import IconSalvatagem from '../assets/img/establishment/salvatagem.svg';
import IconMarina from '../assets/img/establishment/iconMarina.svg';
import IconEntertainment from '../assets/img/establishment/entertainment.svg';
import IconPoita from '../assets/img/alerts/poita.png';

export const renderIconsMaps = (name: string) => {
  if (name === 'Alerta') return Alerta;
  if (name === 'Perigo') return Alerta;
  if (name === 'Pernoite Seguro') return IconSeguro;
  if (name === 'Pernoite Não Seguro') return IconNoSeguro;
  if (name === 'Banco de Areia') return IconAreia;
  if (name === 'Fiscalização') return IconFiscal;
  if (name === 'Rocha') return IconRocha;
  if (name === 'Tempestade') return IconTempestade;
  if (name === 'Clima Tempestade') return IconTempestade;
  if (name === 'Clima Chuva Passageira') return IconChuva;
  if (name === 'Chuva Leve') return IconChuva;
  if (name === 'Nublado') return Nublado;
  if (name === 'SOS') return Sos;
  if (name === 'Poita') return IconPoita;

  if (name === 'Restaurante') return Restaurante;
  if (name === 'Entretenimento') return IconEntertainment;
  if (name === 'Posto de Combustível') return Combustivel;
  if (name === 'Salvatagem') return IconSalvatagem;
  if (name === 'Mercado') return IconSupermarket;
  if (name === 'Marina') return IconMarina;
  if (name === 'Elétrica Naval') return IconNaval;
  if (name === 'Manutenção') return IconManutencao;
  if (name === 'Artigos em Inox') return IconInox;
  if (name === 'Loja') return IconLoja;

  return Alerta;
};
