import styled from 'styled-components';
import pixel from '../../utils/size';

export const ImageMessage = styled.img`
  position: absolute;
  z-index: 1;
  width: ${pixel(75)};

  right: ${pixel(20)};
  bottom: ${pixel(15)};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 600px) {
    top: 12px;
    right: 65px;
    width: 60px;
  }
`;

export const Pointer = styled.div`
  position: absolute;
  z-index: 2;
  width: ${pixel(16)};
  height: ${pixel(16)};

  right: ${pixel(38)};
  bottom: ${pixel(58)};
  font-size: ${pixel(12)};
  padding-top: ${pixel(1.5)};
  color: #ffffff;
  border-radius: 50%;
  background-color: #0099ff;
  box-shadow: 0 0 1px 1px white;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
`;
