import {
  Boat,
  Circulo,
  GooglePlay,
  Info,
  Midias,
  Section1,
  Social,
  Stores,
  SubTitle,
  TextMidias,
  Title,
  IconGoogle,
  IconAplle,
} from './_firstSection';

import BoatWhite from '../../../../assets/img/barcoSeta.svg';
import Google from '../../../../assets/img/google.svg';
import Aplle from '../../../../assets/img/apple.svg';

const FirstSection = () => {
  return (
    <Section1>
      <Info>
        <Title>Navegue com Booat</Title>

        <SubTitle>
          Com o aplicativo do Booat você navega com segurança e tranquilidade.
          Informações importantes para sua navegação na palma da mão, conheça
          nossas funcionalidades.
        </SubTitle>

        <Stores>
          <GooglePlay>
            <IconGoogle src={Google} />
            <div className="stores">
              Disponivel em
              <div>Google Play</div>
            </div>
          </GooglePlay>
          <GooglePlay>
            <IconAplle src={Aplle} />
            <div className="stores">
              Disponivel em
              <div>App Store</div>
            </div>
          </GooglePlay>
        </Stores>
      </Info>
      <Social>
        <Midias>
          <Circulo />
          <Circulo />
          <Circulo />
          <TextMidias>REDES SOCIAIS</TextMidias>
        </Midias>
        <Boat src={BoatWhite} alt="image Barco" />
      </Social>
    </Section1>
  );
};

export default FirstSection;
