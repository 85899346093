import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 25rem;
  margin: 2rem auto;
`;

export const ImageRow = styled.div`
  width: 100%;
  height: 10rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ImageColumn = styled.div`
  width: 14rem;
  height: 10rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ImageLabel = styled.label`
  position: relative;

  width: 100%;
  height: 100%;
  background: #acbfd0;
  border-radius: 0.5rem;
`;

export const ImageInput = styled.input`
  appearance: none;
  display: none;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;

  object-fit: cover;
  object-position: center;
  border-radius: 0.5rem;
`;

export const RemoveButton = styled.button`
  border: none;
  background: white;

  position: absolute;
  top: -0.5rem;
  right: -0.5rem;

  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.25rem;
  color: #1c1c1c;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &:hover {
    filter: brightness(0.9);
  }
`;
