import styled from 'styled-components';
import pixel from '../../utils/size';

export const Container = styled.div`
  width: 100%;
  height: 85%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  font-size: 20pt;
  font-weight: bold;
  width: 100%;
`;

export const ButtonClose = styled.img`
  position: static;
  padding: 0 20px 0 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Section = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: ${pixel(8)};
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue003);
    border-radius: ${pixel(8)};
  }

  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  hr {
    border: 1px solid #d4d4d4;
    margin-bottom: 15px;
  }
`;

export const Ticket = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 380px;
`;

export const Map = styled.div`
  margin-top: 12px;
  width: 92%;
  height: 200px;
  border-radius: 8px;
`;

export const Status = styled.div`
  width: 92%;
  padding: 15px 0;

  display: flex;
  justify-content: space-between;
`;

export const Serie = styled.div`
  font-weight: bold;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 92%;
  align-items: flex-end;
`;
