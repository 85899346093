/* eslint-disable react/jsx-no-useless-fragment */
import { Dispatch, SetStateAction } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { ImageIcon, SectionStatus, TextStatus } from './_mapStatus';
import IconGas from '../../../../assets/icons/gas.svg';
import IconBarco from '../../../../assets/icons/boat.svg';
import IconSos from '../../../../assets/icons/sos.svg';
import IconAlerta from '../../../../assets/icons/alert.svg';
import IconRestaurant from '../../../../assets/icons/restaurant.svg';

interface Props {
  setCategory: Dispatch<SetStateAction<string>>;
  category: string;
}

const MapStatus = ({ setCategory, category }: Props) => {
  const renderIconsStatus = (name: string) => {
    if (name === 'Restaurantes') return IconRestaurant;
    if (name === 'Ponto de Abastecimento') return IconGas;
    if (name === 'Embarcações') return IconBarco;
    if (name === 'SOS') return IconSos;
    if (name === 'Alertas & Perigos') return IconAlerta;
    return IconRestaurant;
  };

  return (
    <>
      {category && (
        <SectionStatus>
          <ImageIcon
            src={renderIconsStatus(category)}
            alt="Icone de status restautante"
          />
          <TextStatus>{category}</TextStatus>
          <IoCloseCircleOutline
            onClick={() => setCategory('')}
            size={30}
            className="button-close-status"
          />
        </SectionStatus>
      )}
    </>
  );
};

export default MapStatus;
