import styled, { keyframes } from 'styled-components';
import pixel from '../../../../utils/size';

const start = keyframes`
  0% {
    opacity: 0.5;
    margin-top: -20px;
  }
  50% {
    opacity: 0.7;
    margin-top: ${pixel(70)};
  }
  100% {
    opacity: 1;
    margin-top: ${pixel(70)};
  }
`;

const startPhone = keyframes`
  0% {
    opacity: 0;
    margin-top: 60px;
  }

  100% {
    opacity: 1;
    margin-top: 20px;
  }
`;

export const Section1 = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 85vh;

  padding: 0 ${pixel(68)};
  margin-top: ${pixel(70)};
  color: white;

  animation-name: ${start};
  animation-duration: 2s;

  @media only screen and (max-width: 600px) {
    height: 60vh;

    margin: 0;
    margin-top: 20px;
    flex-direction: column;
    padding: 0 15px 0 15px;

    animation-name: ${startPhone};
    animation-duration: 2s;
  }
`;

export const Info = styled.div`
  width: ${pixel(385)};
  display: flex;
  flex-direction: column;
  gap: ${pixel(20)};

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Title = styled.text`
  font-size: ${pixel(32)};
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 20pt;
    padding: 0 15px;
  }
`;

export const SubTitle = styled.text`
  font-size: ${pixel(16)};

  @media only screen and (max-width: 600px) {
    font-size: 10pt;
    padding: 0 15px;
  }
`;

export const Stores = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pixel(15)};

  @media only screen and (max-width: 600px) {
    padding: 0 15px;
    align-items: center;
    justify-content: center;
  }
`;

export const GooglePlay = styled.div`
  width: ${pixel(145)};
  height: ${pixel(45)};
  border-radius: ${pixel(8)};
  background: var(--black);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${pixel(5)};

  .stores {
    display: flex;
    flex-direction: column;

    font-size: ${pixel(11)};

    div {
      font-size: ${pixel(16)};
      font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
      font-size: 8pt;

      div {
        font-size: 12pt;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 155px;
    height: 55px;
    border-radius: 8px;
  }
`;

export const IconGoogle = styled.img`
  width: ${pixel(25)};

  @media only screen and (max-width: 600px) {
    width: 30px;
  }
`;

export const IconAplle = styled.img`
  width: ${pixel(25)};
  margin-right: ${pixel(10)};

  @media only screen and (max-width: 600px) {
    width: 30px;
  }
`;

export const Social = styled.div`
  height: ${pixel(360)};
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  gap: ${pixel(15)};
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Midias = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixel(15)};
  align-items: center;
`;

export const TextMidias = styled.div`
  writing-mode: vertical-lr;
  font-size: ${pixel(11)};
  font-weight: bold;
`;

export const Boat = styled.img`
  width: ${pixel(20)};
`;

export const Circulo = styled.div`
  border-radius: 50%;
  width: ${pixel(25)};
  height: ${pixel(25)};
  background: white;
`;
