import { useEffect, useState } from 'react';
import { IUser } from '../../interfaces/User';
import api from '../../services/api';
import handleError from '../../utils/handleError';
import { permissionLabels } from '../../utils/permissions';
import { Loading } from '../Loading/Loading';
import {
  Button,
  ButtonsContainer,
  ButtonText,
  Container,
  Content,
  LoadingWrapper,
  PermissionItem,
  PermissionList,
  PermissionName,
  Title,
  Toggle,
} from './_modalPermissions';

interface IPermission {
  id: number;
  name: string;
}

interface Props {
  onClose: () => void;
  user: IUser;
  getList: () => void;
}

export const ModalPermissions: React.FC<Props> = ({
  user,
  onClose,
  getList,
}) => {
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>(
    user.permissions || [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    setIsSubmitting(true);

    try {
      await api.put(`/users/${user.id}`, {
        permissions: selectedPermissions,
      });

      getList();
      onClose();
    } catch (error: any) {
      handleError(error, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getPermissions = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<IPermission[]>('/permission');

      setPermissions(response.data);
    } catch (error: any) {
      handleError(error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const handleTogglePermission = (id: number) => {
    const alreadySelected = selectedPermissions.findIndex(
      permission => permission === id,
    );

    if (alreadySelected >= 0) {
      const filteredPermissions = selectedPermissions.filter(
        permission => permission !== id,
      );
      setSelectedPermissions(filteredPermissions);
    } else {
      setSelectedPermissions([...selectedPermissions, id]);
    }
  };

  return (
    <Container>
      <Content>
        <Title>Permissões</Title>
        {isLoading ? (
          <LoadingWrapper>
            <Loading style={{ fontSize: '3rem' }} />
          </LoadingWrapper>
        ) : (
          <PermissionList>
            {permissions.map(permission => (
              <PermissionItem key={permission.id}>
                <PermissionName>
                  {permissionLabels[permission.name]}
                </PermissionName>
                <Toggle
                  type="checkbox"
                  checked={selectedPermissions.includes(permission.id)}
                  onChange={() => handleTogglePermission(permission.id)}
                />
              </PermissionItem>
            ))}
          </PermissionList>
        )}
        <ButtonsContainer>
          <Button type="button" onClick={onClose}>
            <ButtonText>Cancelar</ButtonText>
          </Button>
          <Button option onClick={handleSave} disabled={isSubmitting}>
            <ButtonText>
              {isSubmitting ? (
                <Loading
                  style={{
                    marginTop: '0.25rem',
                  }}
                />
              ) : (
                'Salvar'
              )}
            </ButtonText>
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
