import { List, ListItem, Progress } from './_progressBar';

interface Props {
  status: string;
}

export const ProgressBar = ({ status }: Props) => {
  return (
    <Progress>
      <List>
        <ListItem
          active
          style={
            status === 'coming' || status === 'finished'
              ? { zIndex: 3 }
              : { zIndex: 4 }
          }
        >
          Ajuda Encaminhada
        </ListItem>
        <ListItem
          active={status === 'coming' || status === 'finished'}
          style={status !== null ? { zIndex: 3 } : { zIndex: 1 }}
        >
          Ajuda a Caminho
        </ListItem>
        <ListItem
          active={status === 'finished'}
          style={status === null ? { zIndex: 3 } : { zIndex: 1 }}
        >
          Ajuda Chegou ao Destino
        </ListItem>
      </List>
    </Progress>
  );
};
