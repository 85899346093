import { useEffect, useState } from 'react';
import { Loading } from '../Loading/Loading';
import {
  Button,
  ButtonsContainer,
  ButtonText,
  Container,
  Content,
  LoadingWrapper,
  PermissionItem,
  PermissionList,
  PermissionName,
  Title,
  Toggle,
} from './_modalRadius';
import handleError from '../../utils/handleError';
import api from '../../services/api';

interface Props {
  onClose: () => void;
}

export const ModalRadius: React.FC<Props> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(false);

  const getRadius = async () => {
    try {
      const { data } = await api.get<{
        id: string;
        filters: {
          id: string;
          radius: boolean;
          created_at: string;
          updated_at: string;
        };
        created_at: string;
        updated_at: string;
      }>('/system-info');

      setActive(data.filters.radius);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getRadius().finally(() => setIsLoading(false));
  }, []);

  const updateRadius = async (status: boolean) => {
    setIsLoading(true);

    try {
      await api.put('/system-info', {
        filters: {
          radius: status,
        },
      });

      setActive(status);
      handleError('Alteração realizada com sucesso!', 'success');
    } catch (error: any) {
      handleError(error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <Title>Ativar/Desativar Raio de Icones</Title>

        {isLoading ? (
          <LoadingWrapper>
            <Loading style={{ fontSize: '2rem' }} />
          </LoadingWrapper>
        ) : (
          <PermissionList>
            <PermissionItem>
              <Toggle
                type="checkbox"
                checked={active}
                onChange={() => updateRadius(!active)}
              />
              <PermissionName>
                {active ? 'Ativado' : 'Desativado'}
              </PermissionName>
            </PermissionItem>
          </PermissionList>
        )}

        <ButtonsContainer>
          <Button type="button" onClick={onClose}>
            <ButtonText>Cancelar</ButtonText>
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
