/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction } from 'react';
import { IoClose, IoLocationSharp } from 'react-icons/io5';
import {
  Adress,
  BusinessHours,
  Container,
  Edit,
  ModalMarker,
  SectionClose,
  Title,
  Trash,
} from './_windowAnchor';

import Editar from '../../assets/icons/edit.png';
import lixo from '../../assets/icons/trash.png';
import { IAnchor } from '../../interfaces/Anchor';
import api from '../../services/api';
import handleError from '../../utils/handleError';
import { maskPhone } from '../../utils/mask';

interface Props {
  info: IAnchor;
  setInfo: Dispatch<SetStateAction<IAnchor | null | undefined>>;
  setIdAnchor: Dispatch<SetStateAction<string>>;
  fetchAnchors: () => void;
}

export const WindowAnchor = ({
  info,
  setInfo,
  setIdAnchor,
  fetchAnchors,
}: Props) => {
  const DeleteApiAnchor = async () => {
    try {
      await api.delete(`/anchor/${info.id}`);

      handleError('Poita deletada com sucesso!', 'success');
      fetchAnchors();
      setInfo(undefined);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  return (
    <ModalMarker>
      <Container>
        <Title>{info.title}</Title>

        <SectionClose type="button" onClick={() => setInfo(null)}>
          <IoClose color="#0006b8" size={20} />
        </SectionClose>
      </Container>
      <Container>
        <Adress>
          <IoLocationSharp color="#0006b8" width={25} />
          <p>
            {info.latitude.slice(0, 10)}/{info.longitude.slice(0, 10)}
          </p>
        </Adress>
      </Container>
      <Container>
        <BusinessHours>
          <p>{info.describe && info.describe.slice(0, 30)}</p>
        </BusinessHours>
      </Container>
      <BusinessHours>
        <p>{maskPhone(info.cellphone || '')}</p>
      </BusinessHours>
      <Edit
        src={lixo}
        alt="Icon Deletar"
        onClick={DeleteApiAnchor}
        width={35}
      />
      <Trash
        src={Editar}
        alt="Icon Editar"
        width={35}
        onClick={() => {
          setIdAnchor(info.id);
          setInfo(undefined);
        }}
      />
    </ModalMarker>
  );
};
