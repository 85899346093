import { Dispatch, SetStateAction, useState } from 'react';

import ExitIcon from '../../../../assets/icons/contactExit.svg';
import OtherIcon from '../../../../assets/icons/contactOther.svg';
import SupportIcon from '../../../../assets/icons/contactSupport.svg';
import api from '../../../../services/api';
import handleError from '../../../../utils/handleError';
import { IContactForm } from '../../interface';

import { IModalExitFormData, ModalExit } from '../ModalExit/ModalExit';
import { IModalOtherFormData, ModalOther } from '../ModalOther/ModalOther';
import {
  IModalSupportFormData,
  ModalSupport,
} from '../ModalSupport/ModalSupport';
import {
  Card,
  Container,
  CardsList,
  Toggle,
  CardIcon,
} from './_contactOptions';

interface Props {
  contacts: IContactForm[];
  setContacts: Dispatch<SetStateAction<IContactForm[]>>;
  hasContacts: boolean;
  setHasContacts: Dispatch<SetStateAction<boolean>>;
  establishmentId: string;
}

export const ContactOptions: React.FC<Props> = ({
  contacts,
  setContacts,
  hasContacts,
  setHasContacts,
  establishmentId,
}) => {
  const [modalExit, setModalExit] = useState(false);
  const [modalSupport, setModalSupport] = useState(false);
  const [modalOther, setModalOther] = useState(false);

  const handleUpsertContact = async (data: IContactForm) => {
    try {
      await api.put(`/establishment/${establishmentId}/contact`, data);
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  const handleModalExit = async (data: IModalExitFormData) => {
    const exitContact = contacts.find(contact => contact.type === 'Exit');

    if (establishmentId) {
      handleUpsertContact({ ...data, type: 'Exit' });
    }

    if (exitContact) {
      const listContacts = contacts.map(contact =>
        contact.type === 'Exit' ? { ...contact, ...data } : contact,
      );

      setContacts(listContacts);
    } else {
      setContacts([...contacts, { ...data, type: 'Exit' }]);
    }

    setModalExit(false);
  };

  const handleModalSupport = (data: IModalSupportFormData) => {
    const supportContact = contacts.find(contact => contact.type === 'Support');

    if (establishmentId) {
      handleUpsertContact({ ...data, type: 'Support' });
    }

    if (supportContact) {
      const listContacts = contacts.map(contact =>
        contact.type === 'Support' ? { ...contact, ...data } : contact,
      );

      setContacts(listContacts);
    } else {
      setContacts([...contacts, { ...data, type: 'Support' }]);
    }

    setModalSupport(false);
  };

  const handleModalOther = (data: IModalOtherFormData) => {
    const otherContact = contacts.find(contact => contact.type === 'Other');

    if (establishmentId) {
      handleUpsertContact({ ...data, type: 'Other' });
    }

    if (otherContact) {
      const listContacts = contacts.map(contact =>
        contact.type === 'Other' ? { ...contact, ...data } : contact,
      );

      setContacts(listContacts);
    } else {
      setContacts([...contacts, { ...data, type: 'Other' }]);
    }

    setModalOther(false);
  };

  const initialExitContact = contacts.find(contact => contact.type === 'Exit');

  const initialSupportContact = contacts.find(
    contact => contact.type === 'Support',
  );

  const initialOtherContact = contacts.find(
    contact => contact.type === 'Other',
  );

  return (
    <Container>
      <Toggle
        type="checkbox"
        checked={hasContacts}
        onChange={e => setHasContacts(e.target.checked)}
      />

      <CardsList enabled={hasContacts}>
        <Card
          cardBackgroundColor="#d7eed4"
          disabled={!hasContacts}
          onClick={() => setModalExit(true)}
        >
          <CardIcon src={ExitIcon} />
          Avisar saída
        </Card>

        <Card
          cardBackgroundColor="#f1d4d6"
          onClick={() => setModalSupport(true)}
          disabled={!hasContacts}
        >
          <CardIcon src={SupportIcon} />
          Apoio
        </Card>

        <Card
          cardBackgroundColor="#d1e1ee"
          disabled={!hasContacts}
          onClick={() => setModalOther(true)}
        >
          <CardIcon src={OtherIcon} />
          Outros assuntos
        </Card>
      </CardsList>

      {modalExit && (
        <ModalExit
          setOpenModal={setModalExit}
          onSubmit={handleModalExit}
          initialData={initialExitContact}
        />
      )}

      {modalSupport && (
        <ModalSupport
          setOpenModal={setModalSupport}
          onSubmit={handleModalSupport}
          initialData={initialSupportContact}
        />
      )}

      {modalOther && (
        <ModalOther
          setOpenModal={setModalOther}
          onSubmit={handleModalOther}
          initialData={initialOtherContact}
        />
      )}
    </Container>
  );
};
