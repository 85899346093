import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  gap: 8px;
  font-size: 10pt;
  margin-top: 5px;
`;

export const Image = styled.img`
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;
