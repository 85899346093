import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;

  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(3, 3, 3, 0.08);
`;

export const Content = styled.div`
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 1px 2px 20px 0px var(--gray003);

  width: 100%;
  min-height: min(50vh, 20rem);
  max-height: 80vh;
  max-width: 38rem;

  padding: 2rem 2rem 3rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Title = styled.h2`
  font-weight: bold;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-family: 'Nunito';
`;

export const Input = styled.input`
  background-color: #fff;
  height: 2.5rem;
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 0 1rem;
  border-radius: 6px;
  font-size: 1rem;
  font-family: 'Nunito';

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-end;
`;

interface ButtonProps {
  option?: boolean;
}

export const Button = styled.button<ButtonProps>`
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: ${props => (props.option ? 'var(--white)' : 'var(--blue003)')};

  border: 2px solid var(--blue003);
  background-color: ${props =>
    props.option ? 'var(--blue003)' : 'transparent'};
  border-radius: 8px;
  padding: 0 1.25rem;

  height: 2.625rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;
