import {
  Section2,
  ContainerPhone,
  Phone,
  SectionText1,
  TitleText1,
  SubTitleText1,
} from './_secondSection';

import Smartphone from '../../../../assets/img/phone.svg';

const SecondSection = () => {
  return (
    <Section2>
      <ContainerPhone>
        <Phone src={Smartphone} alt="Imagem do smartphone" rotate={20} />
      </ContainerPhone>
      <SectionText1>
        <TitleText1>Saiba a sua localização</TitleText1>
        <SubTitleText1>
          Com o aplicativo do Booat você pode saber sua localização em tempo
          real, além de ter informações de alertas e perigos nas proximidades é
          possível observar os estabelecimentos mais próximos de você.
        </SubTitleText1>
      </SectionText1>
    </Section2>
  );
};

export default SecondSection;
