import styled from 'styled-components';
import pixel from '../../../../utils/size';

export const BarWrapper = styled.div`
  position: absolute;
  left: ${pixel(26)};
  top: ${pixel(28)};
  z-index: 2;
  display: flex;
  background-color: #fafafa80;
  border-radius: ${pixel(4)};
  align-items: center;

  @media only screen and (max-width: 600px) {
    background-color: #ffffff;
    width: 50%;
    height: 40px;
    bottom: 10px;
    top: auto;
    left: auto;
    right: 15px;
  }
`;

export const MenuButton = styled.img`
  cursor: pointer;
  border-radius: 8px 0 0 8px;
  background-color: white;
  opacity: 0.9;
  height: ${pixel(45)};

  &:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

export const Input = styled.input`
  border: 0;
  padding: 0 ${pixel(10)};
  background-color: var(--white002);
  font-size: ${pixel(16)};
  height: ${pixel(45)};
  color: black;

  width: ${pixel(175)};
  color: black;

  outline: none !important;

  &:focus-visible {
    border: 0;
  }

  ::-webkit-input-placeholder {
    color: black;
  }

  @media only screen and (max-width: 600px) {
    width: 75%;
    height: 20px;
    background-color: #ffffff;
    font-size: 6pt;
  }

  @media only screen and (max-width: 600px) {
    width: 70%;
  }
`;

export const ContainerIcon = styled.div`
  background-color: var(--white002);
  height: ${pixel(45)};
  width: ${pixel(45)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 ${pixel(8)} ${pixel(8)} 0;
`;

export const SearchButton = styled.img`
  height: ${pixel(15)};
  width: ${pixel(15)};

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;
