import { Dispatch, SetStateAction } from 'react';
import { ImageProfile } from './_modalUser';

import Profile from '../../../../assets/icons/user.svg';
import { ModalUserOptions } from '../../../../components/Modals/ModalForgotPassword/ModalForgotPassword';

interface Props {
  setUserOptions: Dispatch<SetStateAction<boolean>>;
  userOptions: boolean;
}

export const User = ({ setUserOptions, userOptions }: Props) => {
  return (
    <>
      <ImageProfile
        src={Profile}
        alt="Icon message chat"
        onClick={() => setUserOptions(!userOptions)}
      />

      {userOptions && <ModalUserOptions setOpenModal={setUserOptions} />}
    </>
  );
};
