import styled from 'styled-components';

export const ContainerOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.div`
  height: 40px;
  width: 150px;
  border-radius: 10px;
  background: white;
  box-shadow: -2px 2px 1px #e6e6e6;
  border: 1px solid #e6e6e64a;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
    color: var(--blue003);
  }
`;

export const Image = styled.img`
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;
