import { Container, Main } from './_modal';

export interface Props {
  children: React.ReactNode;
  width?: string;
  height?: string;
}

export const Modal = ({ children, width, height }: Props) => {
  return (
    <Container>
      <Main height={height} width={width}>
        {children}
      </Main>
    </Container>
  );
};
