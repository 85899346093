/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
import axios from 'axios';
import { useEffect } from 'react';
import handleError from '../../../../utils/handleError';
import { ContainerRow, Input } from '../../_modalEstablichment';

interface Props {
  adress: string;
  latitude: string;
  longitude: string;
  setAdress: React.Dispatch<React.SetStateAction<string>>;
  setLatitude: React.Dispatch<React.SetStateAction<string>>;
  setLongitude: React.Dispatch<React.SetStateAction<string>>;
  setEndere: React.Dispatch<React.SetStateAction<string>>;
}

export interface Geocode {
  results: Result[];
  error_message: string | undefined;
}

export interface Result {
  formatted_address: string;
  geometry: Geometry;
}

export interface Geometry {
  location: Location;
}

export interface Location {
  lat: number;
  lng: number;
}

const Address = ({
  adress,
  latitude,
  longitude,
  setLatitude,
  setAdress,
  setLongitude,
  setEndere,
}: Props) => {
  const url = 'https://maps.googleapis.com/maps/api/geocode/json';
  const keys = 'AIzaSyAVZHZLREXZLj_HZnHS0hT_jTq4PpPqyo4';

  const fetchApiGeocoding = async (val: string) => {
    val.replace(' ', '+');

    const { data } = await axios.get<Geocode>(url, {
      params: {
        address: `${val}`,
        key: keys,
      },
    });

    if (data.error_message) {
      console.log(data.error_message);
    } else {
      data.results.map(item => {
        const teste = item.geometry.location.lat;
        setLatitude(teste.toString());
      });

      data.results.map(item => {
        const teste = item.geometry.location.lng;
        setLongitude(teste.toString());
      });

      setEndere(data.results ? data.results[0].formatted_address : '');
      setAdress(data.results ? data.results[0].formatted_address : '');
    }
  };

  const fetchApiLatLng = async (lat: string, lng: string) => {
    lat.replace(' ', '');
    lng.replace(' ', '');

    const { data } = await axios.get<Geocode>(url, {
      params: {
        latlng: `${lat},${lng}`,
        key: keys,
      },
    });

    if (data.error_message) {
      handleError(data.error_message, 'error');
    } else {
      setAdress(data.results ? data.results[0].formatted_address : '');
    }
  };

  useEffect(() => {
    fetchApiLatLng(latitude, longitude);
  }, [latitude, longitude]);

  const something = (event: { keyCode: number }) => {
    if (event.keyCode === 13) fetchApiGeocoding(adress);
  };

  return (
    <>
      <ContainerRow>
        <Input
          placeholder="Endereço do estabelecimento"
          onBlur={e => fetchApiGeocoding(e.target.value)}
          onChange={e => setAdress(e.target.value)}
          value={adress}
          onKeyDown={e => {
            something(e);
          }}
        />
      </ContainerRow>
      <ContainerRow>
        <Input
          placeholder="Latitude"
          onChange={e => setLatitude(e.target.value)}
          value={latitude}
          type="number"
        />
        <Input
          placeholder="Longitude"
          onChange={e => setLongitude(e.target.value)}
          value={longitude}
          type="number"
        />
      </ContainerRow>
    </>
  );
};

export default Address;
