import styled, { keyframes } from 'styled-components';
import pixel from '../../../utils/size';

interface Props {
  option?: boolean;
}

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalUser = styled.div`
  position: absolute;
  z-index: 1;

  top: ${pixel(75)};
  right: ${pixel(80)};
  width: ${pixel(235)};
  height: ${pixel(260)};

  background-color: var(--white);
  border-radius: ${pixel(8)};

  animation-name: ${fade};
  animation-duration: 0.5s;

  box-shadow: 1px 1px 10px 0px #999999;

  @media only screen and (max-width: 600px) {
    top: 80px;
    right: 0;

    width: 100%;
    height: 330px;
    padding: 0 15px;
  }
`;

export const Button = styled.button`
  width: 100%;
  border: ${pixel(1)} solid;
  border-color: var(--gray001);
  background-color: var(--white);
  text-align: left;
  padding: 0 ${pixel(16)};
  font-size: ${pixel(14)};
  color: var(--gray002);

  height: ${pixel(45)};

  &:hover {
    background-color: var(--gray001);
  }

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
    font-size: 14pt;
    height: 50px;
    border-radius: 12px;
    text-align: center;
  }
`;

export const Profile = styled.div`
  height: ${pixel(160)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: ${pixel(15)};

  @media only screen and (max-width: 600px) {
    height: 175px;
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pixel(15)};
`;

export const ImageProfile = styled.img`
  height: ${pixel(60)};
  width: ${pixel(60)};
  background-color: var(--blue001);
  border-radius: 50%;

  @media only screen and (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
`;

export const Title = styled.h2`
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 16pt;
  }
`;

export const Email = styled.h6`
  color: var(--gray003);

  @media only screen and (max-width: 600px) {
    font-size: 12pt;
  }
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: ${pixel(50)};
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 0 ${pixel(22)};
  border-radius: ${pixel(10)};
  font-size: ${pixel(14)};
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: ${pixel(15)};
  gap: ${pixel(16)};
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: ${pixel(15)};
  gap: ${pixel(16)};
  height: 100%;
  align-items: flex-end;
`;

export const ButtonPass = styled.button<Props>`
  width: ${pixel(150)};
  border: ${pixel(2)} solid var(--blue003);
  background-color: ${props =>
    props.option ? 'var(--blue003)' : 'var(--white)'};
  font-size: ${pixel(14)};
  color: ${props => (props.option ? 'var(--white)' : 'var(--blue003)')};
  border-radius: ${pixel(12)};

  height: ${pixel(55)};

  &:hover {
    opacity: 0.8;
  }
`;

export const ContainerModal = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
