import { Dispatch, SetStateAction } from 'react';
import { Input } from '../ModalBanner/styles';
import { ModalMarker, Label } from './styles';

interface ModalDrawRegisterProps {
  setVelocity: Dispatch<SetStateAction<string>>;
  setTime: Dispatch<SetStateAction<string>>;
  setNameOrigin: Dispatch<SetStateAction<string>>;
  setNameDestiny: Dispatch<SetStateAction<string>>;
  name_origin: string;
  name_destiny: string;
  time: string;
  velocity: string;
}

export const ModalDrawRegister = ({
  setNameOrigin,
  setNameDestiny,
  setVelocity,
  setTime,
  time,
  velocity,
  name_origin,
  name_destiny,
}: ModalDrawRegisterProps) => {
  return (
    <ModalMarker>
      <ModalMarker>
        <Label>Nome da Origem</Label>
        <Input
          type="text"
          placeholder="Ex.: Marina X"
          value={name_origin}
          onChange={e => setNameOrigin(e.target.value)}
        />

        <Label>Nome do Destino</Label>
        <Input
          type="text"
          placeholder="Ex.: Marina Y"
          value={name_destiny}
          onChange={e => setNameDestiny(e.target.value)}
        />

        <Label>Velocidade</Label>
        <Input
          type="text"
          placeholder="Ex.: 40 km/h"
          value={velocity}
          onChange={e => setVelocity(e.target.value)}
        />

        <Label>Tempo para o destino</Label>
        <Input
          type="text"
          placeholder="Ex.: 3 h"
          value={time}
          onChange={e => setTime(e.target.value)}
        />
      </ModalMarker>
    </ModalMarker>
  );
};
