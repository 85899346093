import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(3, 3, 3, 0.08);
`;

export const Content = styled.div`
  background-color: #edf0f4;
  border-radius: 16px;
  box-shadow: 1px 2px 20px 0px var(--gray003);

  width: 100%;
  min-height: min(38.375rem, 80vh);
  max-height: 90vh;
  max-width: 56.25rem;

  padding: 1.25rem 3rem 1.75rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Title = styled.h2`
  font-weight: bold;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: 50px;
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 0 22px;
  border-radius: 6px;
  font-size: 12pt;
  font-family: 'Nunito';

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PermissionList = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;
`;

export const PermissionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PermissionName = styled.p`
  font-weight: 300;
`;

export const Toggle = styled.input`
  appearance: none;
  width: 2.375rem;
  height: 1.375rem;
  background: #e9ecef;
  border-radius: 2rem;
  border: 1px solid rgba(0, 6, 184, 0.22);

  cursor: pointer;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);

  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    margin: 0.125rem;

    display: inline-block;

    margin-left: 0.125rem;

    border-radius: 50%;

    background-color: #cfcfcf;
    border: none;

    transition: transform 0.2s ease;
  }

  &:checked {
    background-color: rgba(19, 133, 142, 0.2);

    &:after {
      width: 0.875rem;
      height: 0.875rem;
      background-color: #64cfff;
      transform: translateX(90%);
      border: 1px solid rgba(0, 6, 184, 0.22);
    }
  }
`;

export const ButtonsContainer = styled.div`
  margin-left: auto;
  margin-top: auto;

  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.625rem;
`;

interface Props {
  option?: boolean;
}

export const Button = styled.button<Props>`
  font-family: 'Nunito';

  border: 2px solid var(--blue003);
  background-color: ${props =>
    props.option ? 'var(--blue003)' : 'transparent'};
  color: ${props => (props.option ? 'var(--white)' : 'var(--blue003)')};
  border-radius: 8px;
  padding: 0 1.25rem;

  height: 42px;
  width: 6.875rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonText = styled.p`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.01em;
`;
