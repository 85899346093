import styled from 'styled-components';
import pixel from '../../utils/size';

interface Props {
  active?: boolean;
}

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${pixel(10)} ${pixel(20)};

  @media only screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixel(10)};
`;

export const ImgHeader = styled.img`
  width: ${pixel(32)};
  height: ${pixel(32)};
  border-radius: 50%;

  @media only screen and (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;

export const Paragraph = styled.p`
  font-size: ${pixel(18)};
  font-weight: bold;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;

  @media only screen and (max-width: 600px) {
    font-size: 18pt;
    margin-left: 10px;
  }
`;

export const Icon = styled.img`
  width: ${pixel(18)};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;

export const ListChat = styled.div`
  height: 85%;
  width: 100%;
`;

export const Chat = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${pixel(5)};
  align-items: center;

  @media only screen and (max-width: 600px) {
    gap: 10px;
  }
`;

export const MessagesClient = styled.div`
  width: 90%;
  height: 90%;

  border: 1px solid var(--gray001);
  border-radius: ${pixel(16)};

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: ${pixel(6)};
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue003);
    border-radius: ${pixel(16)};
  }

  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  display: flex;
  flex-direction: column;
`;

export const Start = styled.div`
  width: 60%;
  background-color: #5abce957;
  font-size: ${pixel(12)};
  text-align: center;
  margin: ${pixel(10)};
  border-radius: ${pixel(16)};
  align-self: center;
  padding: 2px;

  @media only screen and (max-width: 600px) {
    padding: 1px;
    font-size: 10pt;
  }
`;

export const Window = styled.div<Props>`
  width: fit-content;
  max-width: 80%;
  word-wrap: break-word;
  padding: ${pixel(10)};
  border: 0px solid;
  border-radius: ${pixel(10)};
  float: ${props => (props.active ? 'right' : 'left')};
  margin: ${pixel(4)} ${pixel(10)};
  background-color: ${props =>
    props.active ? 'var(--blue001)' : 'var(--gray001)'};
  color: ${props => (props.active ? 'white' : 'black')};

  @media only screen and (max-width: 600px) {
    padding: 10px;
    font-size: 14pt;
  }
`;

export const Action = styled.div`
  width: 90%;
  display: flex;
  gap: ${pixel(5)};
`;

export const Input = styled.input`
  height: 40px;
  width: 90%;
  background-color: white;
  border: ${pixel(1)} solid #4793ce;
  border-radius: ${pixel(10)};
  font-size: ${pixel(16)};
  padding: 0 ${pixel(10)};

  @media only screen and (max-width: 600px) {
    height: 40px;
    font-size: 14pt;
  }
`;

export const Send = styled.div`
  height: 40px;
  width: ${pixel(60)};
  border: 1px solid #4793ce;
  border-radius: ${pixel(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: ${pixel(25)};

    @media only screen and (max-width: 600px) {
      width: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    font-size: 14pt;
    height: 40px;
    width: 40px;
  }
`;

export const MessagesHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const MessageRow = styled.div``;
