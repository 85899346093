import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FaSearch } from 'react-icons/fa';
import api from '../../../services/api';
import handleError from '../../../utils/handleError';

import IconClose from '../../../assets/icons/closeChat.svg';

import {
  Conteiner,
  Header,
  Icon,
  InfoMessage,
  ListChat,
  Message,
  Photo,
  SectionPhote,
  TextMessage,
  UserName,
  Pointer,
  StatusFirst,
  StatusSecond,
  Input,
  InputContainer,
  Row,
  SectionFilter,
  RowChat,
  StatusTConcierge,
} from './styles';

import { ResponseSOS, Result } from '../../../shared/ModalSos/ModalSos';
import { ResponseConcierge } from '../../../pages/MapsPage/interface';
import { Conversation } from '../../Conversation/Conversation';
import { UserMessage } from '../../../interfaces/Chat';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIdChat: Dispatch<SetStateAction<string>>;
  idChat: string;
  name: string;
  type: string;
}

const noPhoto =
  'https://www.promoview.com.br/uploads/images/unnamed%2819%29.png';

export const ModalChat = ({
  idChat,
  name,
  type,
  setOpenModal,
  setIdChat,
}: Props) => {
  const [message, setMessage] = useState<Result[]>();
  const [messageDefault, setMessageDefault] = useState<Result[]>();
  const [concierge, setConcierge] = useState<ResponseConcierge[]>();
  const [conciergeDefault, setConciergeDefault] =
    useState<ResponseConcierge[]>();

  const [showChat, setShowChat] = useState<boolean>(false);
  const [user, setUser] = useState<UserMessage>({
    id: idChat || '',
    name: name || '',
    avatar: noPhoto,
    type: type || '',
  });

  const [statusChat, setStatusChat] = useState<string>('progress');

  const fecthApiListSOS = async () => {
    try {
      const { data } = await api.get<ResponseSOS>('/sos/');

      setMessage(
        data.results?.filter(item => !item.sos.status.includes('finished')),
      );
      setMessageDefault(data.results);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchApiConcierge = async () => {
    try {
      const { data } = await api.get<ResponseConcierge[]>('/concierge/admin');

      setConcierge(data?.filter(item => item.is_active));
      setConciergeDefault(data);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    fecthApiListSOS();
    fetchApiConcierge();
  }, []);

  useEffect(() => {
    if (idChat !== '') setShowChat(true);
  }, [idChat]);

  const handleSetUser = (user_info: UserMessage) => {
    setUser(user_info);
    setShowChat(true);
  };

  const onClose = () => {
    setOpenModal(false);
    setIdChat('');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setMessage(messageDefault);
      setConcierge(conciergeDefault);
    } else {
      setMessage(
        messageDefault?.filter(item =>
          item.sos.user.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()),
        ),
      );
      setConcierge(
        conciergeDefault?.filter(item =>
          item.user.name.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
      );
    }
  };

  const handleFilter = (status: string) => {
    if (status === 'concierge') {
      setStatusChat('concierge');

      setConcierge(conciergeDefault?.filter(item => item.is_active));
      setMessage([]);
    } else if (status === 'progress') {
      setStatusChat('progress');
      setMessage(
        messageDefault?.filter(item => !item.sos.status.includes('finished')),
      );
      setConcierge(conciergeDefault?.filter(item => item.is_active));
    } else if (status === 'finished') {
      setStatusChat('finished');
      setMessage(
        messageDefault?.filter(item => item.sos.status.includes('finished')),
      );
      setConcierge(conciergeDefault?.filter(item => !item.is_active));
    } else {
      setMessage(messageDefault);
    }
  };

  const renderConcierge = () => {
    if (concierge) {
      return concierge.map(item => (
        <>
          <Message
            onClick={() =>
              handleSetUser({
                id: item.id,
                name: `${item.user.name} - Concierge`,
                avatar: item.user.avatar_url,
                type: 'concierge',
              })
            }
          >
            <RowChat>
              <SectionPhote>
                <Photo src={item.user.avatar_url || noPhoto} />
              </SectionPhote>
              <InfoMessage>
                <UserName>
                  {item.user.name} - Concierge - {item.code}
                </UserName>
                <TextMessage>
                  {item.is_active ? 'Chat em andamento.' : 'Finalizado'}
                </TextMessage>
              </InfoMessage>
            </RowChat>
            {item.messages_not_viewed > 0 ? (
              <Pointer>
                {item.messages_not_viewed > 99 ? 99 : item.messages_not_viewed}
              </Pointer>
            ) : null}
          </Message>
          <hr />
        </>
      ));
    }
  };

  const renderMessages = () => {
    if (message) {
      return message.map(item => (
        <>
          <Message
            onClick={() =>
              handleSetUser({
                id: item.sos.id,
                name: `${item.sos.user.name} - ${item.sos.code}`,
                avatar: item.sos.user.avatar_url,
                type: 'message',
              })
            }
          >
            <RowChat>
              <SectionPhote>
                <Photo src={item.sos.user.avatar_url || noPhoto} />
              </SectionPhote>
              <InfoMessage>
                <UserName>
                  {item.sos.user.name} - {item.sos.code}
                </UserName>
                <TextMessage>
                  {item.sos.status.includes('finished')
                    ? 'Finalizado'
                    : 'Pedido de ajuda!'}
                </TextMessage>
              </InfoMessage>
            </RowChat>
            {item.messages_not_viewed > 0 ? (
              <Pointer>
                {item.messages_not_viewed > 99 ? 99 : item.messages_not_viewed}
              </Pointer>
            ) : null}
          </Message>
          <hr />
        </>
      ));
    }
  };

  return (
    <Conteiner key={1}>
      {showChat ? (
        <Conversation
          setShowChat={setShowChat}
          setIdChat={setIdChat}
          setUser={setUser}
          user={user}
        />
      ) : (
        <>
          <Header>
            <p style={{ color: 'black' }}>Chat</p>
            <SectionFilter>
              <StatusFirst
                option={statusChat}
                onClick={() => handleFilter('progress')}
              >
                Em andamento/
              </StatusFirst>
              <StatusSecond
                option={statusChat}
                onClick={() => handleFilter('finished')}
              >
                Finalizados/
              </StatusSecond>
              <StatusTConcierge
                option={statusChat}
                onClick={() => handleFilter('concierge')}
              >
                Concierge
              </StatusTConcierge>
            </SectionFilter>
            <Icon src={IconClose} onClick={onClose} />
          </Header>
          <Row>
            <InputContainer>
              <Input onChange={e => handleSearch(e)} />
              <FaSearch />
            </InputContainer>
          </Row>
          <ListChat>
            {renderConcierge()}
            {renderMessages()}
          </ListChat>
        </>
      )}
    </Conteiner>
  );
};
