/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import api from '../../../services/api';
import handleError from '../../../utils/handleError';
import { Modal } from '../../Modal/Modal';
import {
  ContainerModal,
  Section,
  Title,
  ContainerTitle,
  ContainerRow,
  Input,
  ContainerButton,
  Button,
  AreaText,
} from './_modalEditAlert';

interface Props {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  id: string;
}

export interface WarningObj {
  id: string;
  latitude: string;
  longitude: string;
  title: string;
  describe: string;
  visible: boolean;
  user_id: string;
  user: User;
  created_at: string;
  updated_at: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  avatar: null;
  role: string;
  phone: string;
  user_valid: boolean;
  boat_id: string;
  created_at: string;
  updated_at: string;
  avatar_url: string;
}

const ModalEditAlert = ({ setShowModal, showModal, id }: Props) => {
  const [title, setTitle] = useState<string>('');
  const [describe, setDescribe] = useState<string>('');

  const handleCadaster = async () => {
    try {
      await api.put(`/warning/${id}`, {
        title,
        describe,
      });

      handleError('Alterado com sucesso!', 'success');
      setShowModal(false);
      setDescribe('');
      setTitle('');
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const fetchAlert = async () => {
    try {
      const { data } = await api.get<WarningObj>(`/warning/${id}/visible/`);

      setDescribe(data.describe);
      setTitle(data.title);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAlert();
  }, [showModal]);

  const renderModal = () => {
    if (showModal) {
      return (
        <Modal>
          <ContainerModal>
            <Section>
              <ContainerTitle>
                <Title>Editar Alertas e Perigos</Title>
              </ContainerTitle>
              <ContainerRow>
                <Input
                  value={title}
                  placeholder="Título do Alerta"
                  type="text"
                  onChange={e => setTitle(e.target.value)}
                />
              </ContainerRow>
              <ContainerRow>
                <AreaText
                  value={describe}
                  placeholder="Descrição do Alerta"
                  onChange={e => setDescribe(e.target.value)}
                />
              </ContainerRow>
            </Section>
            <ContainerButton>
              <Button option onClick={() => setShowModal(false)}>
                Cancelar
              </Button>
              <Button onClick={() => handleCadaster()}>Cadastrar</Button>
            </ContainerButton>
          </ContainerModal>
        </Modal>
      );
    }

    return <div />;
  };

  return renderModal();
};

export default ModalEditAlert;
