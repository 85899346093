/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction } from 'react';
import {
  IoLocationSharp,
  IoTimeSharp,
  IoCallSharp,
  IoClose,
} from 'react-icons/io5';
import lixo from '../../assets/icons/trash.png';
import Editar from '../../assets/icons/edit.png';
import handleError from '../../utils/handleError';
import api from '../../services/api';
import { IBannerList } from '../../pages/MapsPage/interface';
import {
  StyledText,
  StyledRowContainer,
  BannerDescribe,
  BannerImage,
  ModalMarker,
  BannerName,
  TrashImage,
  EditImage,
  SectionClose,
} from './styles';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  info: IBannerList;
  setInfo: Dispatch<SetStateAction<IBannerList | null | undefined>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setId: Dispatch<SetStateAction<string>>;
}

const BannerDetails = ({ info, setIsOpen, setId, setInfo }: Props) => {
  const { user } = useAuth();

  const handleSendId = (id: string) => {
    setIsOpen(true);
    setId(id);
  };

  const handleDelete = async (value: string) => {
    try {
      await api.delete(`/banners/${value}`);

      handleError('Banner deletado com sucesso!', 'success');
      setId(value);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  return (
    <ModalMarker>
      <StyledRowContainer style={{ justifyContent: 'space-between' }}>
        <BannerName>{info?.name}</BannerName>

        <SectionClose type="button" onClick={() => setInfo(null)}>
          <IoClose color="#0006b8" size={20} />
        </SectionClose>
      </StyledRowContainer>

      <StyledRowContainer>
        {info.first_banner && (
          <BannerImage
            width={300}
            src={info.first_banner}
            alt="banner principal"
            style={{ objectFit: 'cover' }}
          />
        )}
      </StyledRowContainer>

      <StyledRowContainer>
        <BannerDescribe>{info.segment}</BannerDescribe>
      </StyledRowContainer>

      <StyledRowContainer>
        <IoLocationSharp color="#0006b8" size={20} />
        <StyledText>{info.adress}</StyledText>
      </StyledRowContainer>

      <StyledRowContainer>
        <IoTimeSharp color="#0006b8" size={20} />
        <StyledText>
          {info.open_day.is_open ? 'Aberto Agora' : 'Fechado Agora'}
        </StyledText>
      </StyledRowContainer>

      <StyledRowContainer>
        <IoCallSharp color="#0006b8" size={20} />
        <StyledText>{info.phone}</StyledText>
      </StyledRowContainer>

      {(user.permissions.some((item: number) => item === 7) ||
        user.role === 'Master') && (
          <TrashImage src={lixo} onClick={() => handleDelete(info.id)} />
        )}

      {(user.permissions.some((item: number) => item === 7) ||
        user.role === 'Master') && (
          <EditImage src={Editar} onClick={() => handleSendId(info.id)} />
        )}
    </ModalMarker>
  );
};

export default BannerDetails;
