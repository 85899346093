/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory } from 'react-router-dom';
import {
  Circulo,
  Copy,
  Instagram,
  Link,
  SectionFooter,
  SocialMedia,
} from './_footer';

import Insta from '../../assets/icons/Instagram.png';

export const Footer = () => {
  const history = useHistory();
  const handleExternalLink = () => {
    window.location.href = 'https://www.instagram.com/';
  };

  return (
    <SectionFooter>
      <Copy>
        Copyright © 2021 Booat.{' '}
        <Link onClick={() => history.push('terms')}>
          Todos os direitos reservados.
        </Link>
      </Copy>
      <SocialMedia>
        <Circulo />
        <Circulo />
        <Instagram
          src={Insta}
          alt="Social media instagram"
          onClick={() => handleExternalLink}
        />
      </SocialMedia>
    </SectionFooter>
  );
};
