import styled from 'styled-components';
import pixel from '../../../../utils/size';

export const ImageAlert = styled.img`
  position: absolute;
  z-index: 1;
  width: ${pixel(65)};
  right: ${pixel(20)};
  top: ${pixel(16)};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  @media only screen and (max-width: 600px) {
    top: 12px;
    right: 5px;
    width: 65px;
  }
`;

export const Pointer = styled.div`
  position: absolute;
  z-index: 2;
  right: ${pixel(42)};
  top: ${pixel(35)};
  width: ${pixel(11)};
  height: ${pixel(11)};
  border-radius: 50%;
  background-color: #0099ff;
  box-shadow: 0 0 1px 1px white;
`;
