/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction } from 'react';
import {
  IoLocationSharp,
  IoTimeSharp,
  IoCallSharp,
  IoClose,
} from 'react-icons/io5';
import {
  StyledText,
  Image,
  ModalMarker,
  SecondImages,
  SectionIcons,
  SsectionImage,
  Status,
  Title,
  Trash,
  Edit,
  Container,
  SectionClose,
} from './styles';
import Insta from '../../assets/icons/insta.svg';
import Site from '../../assets/icons/site.svg';
import Facebook from '../../assets/icons/iconFacebook.png';
import Youtube from '../../assets/icons/iconYoutube.png';
import BooatLogo from '../../assets/img/booatLogo.png';
import lixo from '../../assets/icons/trash.png';
import Editar from '../../assets/icons/edit.png';
import handleError from '../../utils/handleError';
import api from '../../services/api';
import { Establishment } from '../../pages/MapsPage/interface';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  info: Establishment;
  setInfo: Dispatch<SetStateAction<Establishment | null | undefined>>;
  setEstablishment: Dispatch<SetStateAction<boolean>>;
  setId: Dispatch<SetStateAction<string>>;
}

const EstablishmentDetails = ({
  info,
  setEstablishment,
  setId,
  setInfo,
}: Props) => {
  const { user } = useAuth();

  const handleSendId = (id: string) => {
    setEstablishment(true);
    setId(id);
  };

  const DeleteApiEstablishment = async (value: string) => {
    try {
      await api.delete(`/establishment/${value}`);

      handleError('Estabelecimento deletado com sucesso!', 'success');
      setId(value);
    } catch (error) {
      handleError(error, 'error');
    }
  };

  return (
    <ModalMarker>
      <Container style={{ justifyContent: 'space-between' }}>
        <Title>{info?.name}</Title>

        <SectionIcons>
          {info.instagram && (
            <a rel="noopener noreferrer" href={info.instagram} target="_blank">
              <Image src={Insta} alt="Icon Insta" />
            </a>
          )}
          {info.site && (
            <a rel="noopener noreferrer" href={info.site} target="_blank">
              <Image src={Site} alt="Icon Site" />
            </a>
          )}
          {info.facebook && (
            <a rel="noopener noreferrer" href={info.facebook} target="_blank">
              <Image src={Facebook} alt="Icon Facebook" width={16} />
            </a>
          )}
          {info.youtube && (
            <a rel="noopener noreferrer" href={info.youtube} target="_blank">
              <Image src={Youtube} alt="Icon Youtube" width={17} height={15} />
            </a>
          )}

          <SectionClose type="button" onClick={() => setInfo(null)}>
            <IoClose color="#0006b8" size={20} />
          </SectionClose>
        </SectionIcons>
      </Container>
      <Container>
        <Status cor={info.open_day.is_open}>
          {info.category}
          <div />
          {info.open_day.is_open ? 'Aberto Agora' : 'Fechado Agora'}
        </Status>
      </Container>

      <Container>
        <SsectionImage>
          {info.images.length > 0 ? (
            <>
              {info.images[0] && (
                <Image
                  width={300}
                  src={`${info.images[0].img_url}`}
                  alt="Imagem 1"
                  style={{ objectFit: 'cover' }}
                />
              )}
              <SecondImages>
                {info.images[1] && (
                  <Image
                    height={45}
                    width={50}
                    src={`${info.images[1].img_url}`}
                    alt=""
                  />
                )}
                {info.images[2] && (
                  <Image
                    height={45}
                    width={50}
                    src={`${info.images[2].img_url}`}
                    alt=""
                  />
                )}
              </SecondImages>
              {info.images[3] && (
                <Image
                  width={300}
                  src={`${info.images[3].img_url}`}
                  alt="Imagem 1"
                />
              )}
            </>
          ) : (
            <Image
              width={300}
              src={BooatLogo}
              alt="Imagem 1"
              style={{ objectFit: 'contain', background: '#0006b8' }}
            />
          )}
        </SsectionImage>
      </Container>

      <Container>
        <StyledText style={{ maxWidth: '100%' }}>{info.describe}</StyledText>
      </Container>
      <Container>
        <IoLocationSharp color="#0006b8" size={20} />
        <StyledText>{info.address}</StyledText>
      </Container>
      <Container>
        <IoTimeSharp color="#0006b8" size={20} />
        <StyledText>
          {info.open_day.is_open
            ? `${info.open_day.day.week_day} | ${info.open_day.day.start_time} - ${info.open_day.day.end_time}`
            : 'Fechado hoje'}
        </StyledText>
      </Container>
      <Container>
        <IoCallSharp color="#0006b8" size={20} />
        <StyledText>{info.telephone}</StyledText>
      </Container>
      {(user.permissions.some((item: number) => item === 0) ||
        user.role === 'Master') && (
          <Trash
            src={lixo}
            alt="Icon trash"
            onClick={() => DeleteApiEstablishment(info.id)}
          />
        )}

      {(user.permissions.some((item: number) => item === 0) ||
        user.role === 'Master') && (
          <Edit
            src={Editar}
            alt="Icon edit"
            onClick={() => handleSendId(info.id)}
          />
        )}
    </ModalMarker>
  );
};

export default EstablishmentDetails;
