/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useEffect } from 'react';
import handleError from '../../../../../utils/handleError';
import { ContainerRow, Input } from './styles';
import { Geocode } from '../../../../../interfaces/Banner';

interface Props {
  adress: string;
  latitude: string;
  longitude: string;
  setAdress: React.Dispatch<React.SetStateAction<string>>;
  setLatitude: React.Dispatch<React.SetStateAction<string>>;
  setLongitude: React.Dispatch<React.SetStateAction<string>>;
}

const url = 'https://maps.googleapis.com/maps/api/geocode/json';
const keys = 'AIzaSyAVZHZLREXZLj_HZnHS0hT_jTq4PpPqyo4';

const AddressForm = ({
  adress,
  latitude,
  longitude,
  setLatitude,
  setAdress,
  setLongitude,
}: Props) => {
  const fetchApiGeocoding = async (val: string) => {
    const { data } = await axios.get<Geocode>(url, {
      params: {
        address: `${val.replace(' ', '+')}`,
        key: keys,
      },
    });

    if (data.error_message) {
      handleError(data.error_message, 'error');
    } else {
      data.results.map(item => {
        const teste = item.geometry.location.lat;
        setLatitude(teste.toString());
        return teste;
      });

      data.results.map(item => {
        const teste = item.geometry.location.lng;
        setLongitude(teste.toString());
        return teste;
      });

      setAdress(data.results ? data.results[0].formatted_address : '');
    }
  };

  const fetchApiLatLng = async (lat: string, lng: string) => {
    const { data } = await axios.get<Geocode>(url, {
      params: {
        latlng: `${lat.replace(' ', '')},${lng.replace(' ', '')}`,
        key: keys,
      },
    });

    if (data.error_message) {
      handleError(data.error_message, 'error');
    } else {
      setAdress(data.results ? data.results[0].formatted_address : '');
    }
  };

  useEffect(() => {
    fetchApiLatLng(latitude, longitude);
  }, [latitude, longitude]);

  const something = (event: { keyCode: number }) => {
    if (event.keyCode === 13) fetchApiGeocoding(adress);
  };

  return (
    <>
      <ContainerRow>
        <Input
          placeholder="Endereço"
          onBlur={e => fetchApiGeocoding(e.target.value)}
          onChange={e => setAdress(e.target.value)}
          value={adress}
          onKeyDown={e => {
            something(e);
          }}
        />
      </ContainerRow>
      <ContainerRow>
        <Input
          placeholder="Latitude"
          onChange={e => setLatitude(e.target.value)}
          value={latitude}
          type="number"
        />
        <Input
          placeholder="Longitude"
          onChange={e => setLongitude(e.target.value)}
          value={longitude}
          type="number"
        />
      </ContainerRow>
    </>
  );
};

export default AddressForm;
