/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { IoLocationSharp, IoClose } from 'react-icons/io5';
import {
  Adress,
  BusinessHours,
  Container,
  Image,
  ModalMarker,
  Telephone,
  Title,
  Trash,
  Edit,
  SectionClose,
} from './_windowAlert';

import Marker from '../../assets/icons/marker.svg';
import User from '../../assets/icons/blueUser.svg';
import SimpleBoat from '../../assets/icons/blueSimpleBoat.svg';
import Boat from '../../assets/icons/blueBoat.svg';
import Chat from '../../assets/icons/blueChat.svg';
import lixo from '../../assets/icons/trash.png';
import Editar from '../../assets/icons/edit.png';
import handleError from '../../utils/handleError';
import api from '../../services/api';
import { ResponsePoint } from '../../pages/MapsPage/interface';

interface Props {
  info: ResponsePoint;
  optionInfo: string;
  setInfo: Dispatch<SetStateAction<ResponsePoint | null | undefined>>;
  setModalSos: Dispatch<SetStateAction<boolean>>;
  setModalAlertEdit: Dispatch<SetStateAction<boolean>>;
  setIdAlert: Dispatch<SetStateAction<string>>;
  setIdChat: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
}

export const WindowAlert = ({
  info,
  optionInfo,
  setInfo,
  setModalSos,
  setIdChat,
  setIdAlert,
  setName,
  setModalAlertEdit,
}: Props) => {
  const history = useHistory();

  const DeleteApiEstablishment = async (value: string) => {
    try {
      await api.delete(`/warning/${value}`);

      handleError('Alerta deletado com sucesso!', 'success');

      setName('teste');
    } catch (error) {
      handleError(error, 'error');
    }
  };

  const handleSendId = () => {
    setModalSos(true);
  };

  const renderSOS = () => {
    return (
      <ModalMarker>
        <Container>
          <Title>{info.describe && info.describe.slice(0, 20)}</Title>

          <SectionClose type="button" onClick={() => setInfo(null)}>
            <IoClose color="#0006b8" size={20} />
          </SectionClose>
        </Container>
        <Container>
          <Adress>
            <Image src={Marker} alt="" width={13} />
            <p>
              {info.latitude.slice(0, 10)}/{info.longitude.slice(0, 10)}
            </p>
          </Adress>
        </Container>
        <Container>
          <BusinessHours>
            <Image src={User} alt="" width={15} />
            <p>{info.user ? info.user.name : ''}</p>
          </BusinessHours>
        </Container>
        <Container>
          <Telephone>
            <Image src={SimpleBoat} alt="" width={15} />
            <p>{info.boats?.length > 0 ? info.boats[0].name : 'Embarcação'}</p>
          </Telephone>
        </Container>
        <Trash
          src={Chat}
          alt="Icon Chat"
          width={40}
          onClick={() => {
            setIdChat(info.id);
            setName(info.user ? info.user.name : '');
          }}
        />

        <Edit
          src={Boat}
          alt="Icon Boat"
          onClick={() => {
            handleSendId();
            history.push(`/maps/${info.id}`);
          }}
          width={40}
        />
      </ModalMarker>
    );
  };

  const renderWarning = () => {
    return (
      <ModalMarker>
        <Container>
          <Title>{info.title}</Title>

          <SectionClose type="button" onClick={() => setInfo(null)}>
            <IoClose color="#0006b8" size={20} />
          </SectionClose>
        </Container>
        <Container>
          <Adress>
            <IoLocationSharp color="#0006b8" width={25} />
            <p>
              {info.latitude.slice(0, 10)}/{info.longitude.slice(0, 10)}
            </p>
          </Adress>
        </Container>
        <Container>
          <BusinessHours>
            <p>{info.describe && info.describe.slice(0, 30)}</p>
          </BusinessHours>
        </Container>
        <Edit
          src={lixo}
          alt="Icon Deletar"
          onClick={() => {
            DeleteApiEstablishment(info.id);
          }}
          width={35}
        />
        <Trash
          src={Editar}
          alt="Icon Editar"
          width={35}
          onClick={() => {
            setIdAlert(info.id);
            setModalAlertEdit(true);
          }}
        />
      </ModalMarker>
    );
  };

  return (
    <ModalMarker>
      {optionInfo === 'sos' ? renderSOS() : renderWarning()}
    </ModalMarker>
  );
};
