import styled from 'styled-components';
import pixel from '../../../../utils/size';

interface Props {
  rotate: number;
}

export const Section2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  background-color: white;

  @media only screen and (max-width: 600px) {
    width: 100vw;
    flex-direction: column;
  }
`;

export const ContainerPhone = styled.div`
  display: flex;
`;

export const Phone = styled.img<Props>`
  width: ${pixel(370)};
  transform: rotate(${props => props.rotate}deg);

  @media only screen and (max-width: 600px) {
    transform: rotate(0);
    width: 280px;
  }
`;

export const SectionText1 = styled.div`
  width: ${pixel(490)};
  text-align: right;

  display: flex;
  flex-direction: column;
  gap: ${pixel(15)};

  @media only screen and (max-width: 600px) {
    padding: 0 15px;
    width: 100vw;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

export const TitleText1 = styled.div`
  font-size: ${pixel(26)};
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 16pt;
  }
`;

export const SubTitleText1 = styled.div`
  font-size: ${pixel(18)};

  @media only screen and (max-width: 600px) {
    font-size: 10pt;
  }
`;
