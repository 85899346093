import styled from 'styled-components';

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  gap: 10px;

  input[type='file'] {
    display: none;
  }
`;

export const Input = styled.input`
  background-color: #ffffff;
  height: 50px;
  border: 0px solid var(--gray001);
  width: 100%;
  padding: 0 22px;
  border-radius: 6px;
  font-size: 12pt;
  font-family: 'Nunito';

  ::-webkit-input-placeholder {
    color: #1a1a1a;
  }
`;
