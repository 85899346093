/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useState, useEffect } from 'react';

import handleError from '../../../utils/handleError';
import api from '../../../services/api';
import { maskPhone, unformatPhone } from '../../../utils/mask';

import { ResponseBanner } from '../../../interfaces/Banner';

import AddressForm from './Components/AddressForm/AddressForm';
import { PropsTime } from '../../../shared/ModalEstablichment/interface';
import { BusinessHours } from './Components/BusinessHours/BusinessHours';
import { NewUploadImages } from './Components/UploadImages/UploadImages';

import {
  ContainerModal,
  ContainerTitle,
  Title,
  Input,
  Button,
  ContainerButton,
  ContainerRow,
  Section,
  Label,
} from './styles';

interface Props {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setIdEst: Dispatch<SetStateAction<string>>;
  id: string | any;
}

export const ModalBanner = ({ setOpenModal, id, setIdEst }: Props) => {
  const [name, setName] = useState<string>('');
  const [segment, setSegment] = useState<string>('');
  const [adress, setAdress] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [logo, setLogo] = useState<string>('');
  const [default_logo, setDefaultLogo] = useState<string | undefined>();
  const [first_banner, setFirstBanner] = useState<string>('');
  const [default_banner, setDefaltBanner] = useState<string | undefined>();
  const [opening_hours, setOpeningHours] = useState<string>('');
  const [closing_hours, setClosingHours] = useState<string>('');
  const [week_day, setWeekDay] = useState<string>('');
  const [business, setBusiness] = useState<PropsTime[]>([]);
  const [isVisibleHours, setIsVisibleHours] = useState<boolean>(false);

  const fecthApiBanner = async () => {
    try {
      const { data } = await api.get<ResponseBanner>(`/banners/${id}`);

      setName(data.name);
      setSegment(data.segment);
      setAdress(data.adress);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
      setPhone(maskPhone(data.phone));
      setLogo(data.logo);
      setDefaultLogo(data.logo);
      setDefaltBanner(data.first_banner);
      setFirstBanner(data.first_banner);

      if (data.banner_hours) {
        const lista = [...business];
        data.banner_hours.map(item =>
          lista.push({
            idHours: item.id,
            weekDay: item.week_day,
            startTime: item.start_time,
            endTime: item.end_time,
          }),
        );
        setBusiness(lista);
      }
    } catch (error: any) {
      handleError(error, 'error');
    }
  };

  useEffect(() => {
    if (id) {
      fecthApiBanner();
    }
  }, [id]);

  const onSubmit = async () => {
    try {
      const formData = new FormData();

      if (!default_logo) {
        if (logo) formData.append('logo', logo);
      }
      if (!default_banner) {
        if (first_banner) formData.append('first_banner', first_banner);
      }
      formData.append('name', name);
      formData.append('segment', segment);
      formData.append('adress', adress);
      formData.append('start_time', opening_hours);
      formData.append('end_time', closing_hours);
      formData.append('week_day', week_day);
      formData.append('latitude', latitude);
      formData.append('longitude', longitude);
      formData.append('phone', unformatPhone(phone));

      if (id) {
        const { data } = await api.put<ResponseBanner>(
          `/banners/${id}`,
          formData,
        );

        if (business) {
          const hours = business.map(item => {
            return {
              week_day: item.weekDay,
              start_time: item.startTime,
              end_time: item.endTime,
            };
          });

          await api.post(`/banners/hours/${id}`, {
            hours,
          });
        }

        handleError('Banner alterado com sucesso', 'success');
        setIdEst(data.id);
      } else {
        const { data } = await api.post<ResponseBanner>(`/banners/`, formData);

        if (business && data.id) {
          const hours = business.map(item => {
            return {
              week_day: item.weekDay,
              start_time: item.startTime,
              end_time: item.endTime,
            };
          });

          await api.post(`/banners/hours/${data.id}`, {
            hours,
          });
        }

        handleError('Banner cadastrado com sucesso', 'success');
        setIdEst(data.id);
      }

      setOpenModal(false);
    } catch (err: any) {
      handleError(err, 'error');
    }
  };

  const handleFileLogotype = (e: any) => {
    const file = e.target.files[0];

    setDefaultLogo(undefined);

    return file;
  };

  const closeModal = () => setOpenModal(false);

  return (
    <ContainerModal key="02">
      <Section>
        <ContainerTitle>
          <Title>Cadastrar Banners</Title>
        </ContainerTitle>

        <ContainerRow>
          <Input
            placeholder="Nome"
            type="text"
            onChange={e => setName(e.target.value)}
            value={name}
          />

          <Label htmlFor="arquivo">
            {logo ? `Logomarca adicionada` : 'Adicione a logomarca'}
          </Label>

          <input
            type="file"
            name="arquivo"
            id="arquivo"
            accept="image/x-png,image/jpeg"
            onChange={e => setLogo(handleFileLogotype(e))}
          />
        </ContainerRow>

        <ContainerRow>
          <Input
            placeholder="Segmento"
            type="text"
            onChange={e => setSegment(e.target.value)}
            value={segment}
          />
        </ContainerRow>

        <AddressForm
          adress={adress}
          setAdress={setAdress}
          latitude={latitude}
          longitude={longitude}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
        />

        <ContainerRow>
          <Input
            placeholder="Telefone"
            maxLength={15}
            value={phone}
            onChange={e => setPhone(maskPhone(e.target.value))}
          />
        </ContainerRow>

        <BusinessHours
          business={business}
          setBusiness={setBusiness}
          startTime={opening_hours}
          setStartTime={setOpeningHours}
          endTime={closing_hours}
          setEndTime={setClosingHours}
          weekDay={week_day}
          setWeekDay={setWeekDay}
          isVisibleHours={isVisibleHours}
          setIsVisibleHours={setIsVisibleHours}
        />

        <NewUploadImages
          first_banner={first_banner}
          setFirstBanner={setFirstBanner}
          default_banner={default_banner}
          setDefaultBanner={setDefaltBanner}
        />
      </Section>

      <ContainerButton>
        <Button onClick={closeModal}>Cancelar</Button>
        <Button option onClick={onSubmit}>
          {id ? 'Alterar' : 'Cadastrar'}
        </Button>
      </ContainerButton>
    </ContainerModal>
  );
};
