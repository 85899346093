import styled, { keyframes } from 'styled-components';
import pixel from '../../utils/size';

interface Props {
  option?: boolean;
}

const fade = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const test = keyframes`
  from{
    border: 0;
  }
  to {
    border: 5px solid #FFFFFF83;
  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 3;

  top: ${pixel(75)};
  right: ${pixel(30)};
  width: ${pixel(250)};
  height: 70vh;
  font-size: ${pixel(18)};
  font-family: 'Nunito';

  background: white;
  border-radius: ${pixel(8)};

  animation-name: ${fade};
  animation-duration: 0.5s;

  box-shadow: 1px 1px 10px 0px #999999;

  hr {
    border: ${pixel(1)} solid var(--gray001);
    width: 100%;
  }

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: ${pixel(6)};
  }

  ::-webkit-scrollbar-thumb {
    background: var(--blue003);
    border-radius: ${pixel(12)};
  }

  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  div {
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
  }

  @media only screen and (max-width: 600px) {
    top: 80px;
    right: 0;

    width: 100%;
    height: 658px;
    padding: 0 15px;
    font-size: 16pt;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pixel(4)};

  padding: ${pixel(15)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pixel(6)};

  p {
    font-weight: bold;
    font-size: ${pixel(14)};
  }

  h6 {
    font-weight: 100;
    font-size: ${pixel(14)};
  }

  @media only screen and (max-width: 600px) {
    gap: 10px;

    img {
      width: 16px;
    }
  }
`;

export const ButtonOption = styled.button<Props>`
  height: ${pixel(32)};
  width: ${pixel(100)};
  background-color: ${props => (props.option === true ? 'red' : 'green')};
  color: white;
  border-radius: ${pixel(8)};
  border: 0;
  margin-top: ${pixel(10)};
  font-size: ${pixel(14)};
  font-family: 'Nunito';
  font-weight: bold;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 600px) {
    width: 40%;
    height: 40px;
    padding: 0 15px;
    font-size: 10pt;
  }
`;

export const SectionAlert = styled.div``;

export const Limit = styled.div`
  width: 100%;
  height: ${pixel(25)};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--blue003);

  cursor: pointer;

  color: white;
  font-size: ${pixel(12)};

  &:hover {
    opacity: 0.8 !important;

    background: var(--blue003) !important;
  }
`;

export const Pointer = styled.div`
  width: ${pixel(15)};
  height: ${pixel(15)};
  border-radius: ${pixel(20)};
  background-color: #0099ff;
  margin-right: ${pixel(5)};
  box-shadow: 0 0 1px 1px #0099ff;
  border: 5px solid #0099ff;

  transition: 0.3s ease-in-out;
  animation-name: ${test};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;
